import { toast, ToastContainer } from 'react-toastify';
import * as apiServices from '../../services/API/api.service';


export const PostPresaleAction = async (data) => {
  try {
    const response = await apiServices.PostPresale(data);
    return response;
  } catch (err) {
    console.log(err, ' PostPresaleAction');
    //   toast.error(JSON.stringify(err.data.error.message));
    // throw JSON.stringify(err);
  }
};

export const PostSpecialSaleAction = async (data) => {
  try {
    const response = await apiServices.PostSpecialSale(data);
    return response;
  } catch (err) {
    console.log(err, ' PostPresaleAction');
    //   toast.error(JSON.stringify(err.data.error.message));
    // throw JSON.stringify(err);
  }
};

export const GetPresaleAction = async (data) => {
 
  try {
    const response = await apiServices.getPresaleService(data);
    // console.log("response",response)
    return response?.data
  } catch (err) {
    console.log(err, ' PostPresaleAction');
    //   toast.error(JSON.stringify(err.data.error.message));
    // throw JSON.stringify(err);
  }
}

export const GetSpecialSaleAction = async (data) => {
 
  try {
    const response = await apiServices.getSpecialSaleService(data);
    // console.log("response",response)
    return response?.data
  } catch (err) {
    console.log(err, ' PostPresaleAction');
    //   toast.error(JSON.stringify(err.data.error.message));
    // throw JSON.stringify(err);
  }
}
// const notify = (data) => toast.success(data);


export const FavouriteAction = async (data) => {
  try {
    const response = await apiServices.favouriteService(data);
    // console.log("response", response)
    // toast.success(response?.data?.message || response?.data?.data?.message)
    return response?.data
  } catch (err) {
    console.log(err, ' FavouriteAction');
    //   toast.error(JSON.stringify(err.data.error.message));
    // throw JSON.stringify(err);
  }
}

export const reminderAction = async (data) => {

  try {
    const response = await apiServices.remnderService(data);
    console.log("response", response)
    // toast.success(response?.data?.message || response?.data?.data?.message)
    return response?.data
  } catch (err) {
    toast.error(err?.data?.message,{toastId:err?.data?.message});
    // throw JSON.stringify(err);
  }
}


export const createTokenAction = async (data) => {
  try {
    const response = await apiServices.createTokenService(data);
    // toast.success(response?.data?.message|| response?.data?.data?.message)
    return response?.data
  } catch (err) {
    console.log(err, ' createTokenService');
    //   toast.error(JSON.stringify(err.data.error.message));
    // throw JSON.stringify(err);
  }
}

export const getCurrentBnbPriceAction = async (data) => {
  try {
    const response = await apiServices.getcurrentBnbPriceService(data);
    // toast.success(response?.data?.message|| response?.data?.data?.message)
    return response?.data
  } catch (err) {
    // console.log(err, ' createTokenService');
    //   toast.error(JSON.stringify(err.data.error.message));
    // throw JSON.stringify(err);
  }
}

export const getPresaleDetailAction = async (data) => {
  try {
    const response = await apiServices.getPresaleDetailService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' getPresaleDetailAction');
    //   toast.error(JSON.stringify(err.data.error.message));
    // throw JSON.stringify(err);
  }
}

export const getSpecialDetailAction = async (data) => {
  try {
    const response = await apiServices.getSpecialDetailService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' getSpecialDetailAction');
    //   toast.error(JSON.stringify(err.data.error.message));
    // throw JSON.stringify(err);
  }
}

export const getInvestDetailsAction = async (data) => {
  try {
    const response = await apiServices.getInvestDetails(data);
    return response?.data
  } catch (err) {
    console.log(err, ' getInvestDetailsAction');
    //   toast.error(JSON.stringify(err.data.error.message));
    // throw JSON.stringify(err);
  }
}


export const postInvestAction = async (data) => {
  try {
    const response = await apiServices.postInvestService(data);
    return response?.data
  } catch (err) {
    console.log(err, 'postInvest');
    // throw JSON.stringify(err);
  }
}

export const deleteContributionAction = async (data) => {
  try {
    const response = await apiServices.deleteContributionService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' deleteContributionAction');
    // throw JSON.stringify(err);
  }
}

export const updatePresaleAction = async (data) => {
  try {
    const response = await apiServices.updatePresaleDetails(data);
    return response?.data
  } catch (err) {
    console.log(err, ' updatePresaleAction');
    // throw JSON.stringify(err);
  }
}

export const cancelPresaleAction = async (data) => {
  try {
    const response = await apiServices.cancelPresaleService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' cancelPresaleAction');
    // throw JSON.stringify(err);
  }
}

export const extendLiquidityLockAction = async (data) => {
  try {
    const response = await apiServices.extendLiquidityLockService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' extendLiquidityLockAction');
    // throw JSON.stringify(err);
  }
}


export const presaleStartAction = async (data) => {
  try {
    const response = await apiServices.presaleStartService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' presaleStartAction');
    // throw JSON.stringify(err);
  }
}

export const addWhiteListedAction = async (data) => {
  try {
    const response = await apiServices.addWhiteListedService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' addWhiteListedAction');
    // throw JSON.stringify(err);
  }
}
export const addWhiteListedSpecialAction = async (data) => {
  try {
    const response = await apiServices.addWhiteListedServiceSpecial(data);
    return response?.data
  } catch (err) {
    console.log(err, ' addWhiteListedAction');
    // throw JSON.stringify(err);
  }
}

export const removeWhitelistAction = async (data) => {
  try {
    const response = await apiServices.removeWhitelistService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' removeWhitelistAction');
    // throw JSON.stringify(err);
  }
}

export const removeWhitelistSpecialAction = async (data) => {
  try {
    const response = await apiServices.removeWhitelistSpecialService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' removeWhitelistSpecialAction');
    // throw JSON.stringify(err);
  }
}


export const finalizePoolAction = async (data) => {
  try {
    const response = await apiServices.finalizePoolService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' finalizePoolAction');
    // throw JSON.stringify(err);
  }
}

export const claimSpecialSaleAction = async (data) => {
  try {
    const response = await apiServices.claimSpecialSaleService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' claimSpecialSaleAction');
    // throw JSON.stringify(err);
  }
}

export const ClaimedAction = async (data) => {
  try {
    const response = await apiServices.ClaimedService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' ClaimedAction');
    // throw JSON.stringify(err);
  }
}

export const allowClaimAction = async (data) => {
  try {
    const response = await apiServices.allowClaimService(data);
    return response?.data
  } catch (err) {
    console.log(err, ' ClaimedAction');
    // throw JSON.stringify(err);
  }
}


export const getPresaleReminderDetails = async (data, saleType) => {
  try {
    const response = await apiServices.getPresaleReminder(data, saleType);
    return response?.data
  } catch (err) {
    console.log(err, 'Get Presale Reminder');
    // throw JSON.stringify(err);
  }
}
export const deletePresalesReminder = async (data) => {
  try {
    const response = await apiServices.deletePresaleReminder(data);
    return response
  } catch (err) {
    console.log(err, 'delete Presales Reminder');
    // throw JSON.stringify(err);
  }
}
export const deleteonePresalesReminder = async (data) => {
  try {
    const response = await apiServices.deleteSinglePresaleReminder(data);
    return response
  } catch (err) {
    console.log(err, 'delete Presales Reminder');
    // throw JSON.stringify(err);
  }
}


export const updateLiqAddsOnTimestampService = async (data) => {
  console.log("data",data)
  try {
    const response = await apiServices.updateLiqAddsOnTimestamp(data);
    return response
  } catch (err) {
    console.log(err, 'updateLiqAddsOnTimestampService');
    // throw JSON.stringify(err);
  }
}



export const updatePresaleRDexLRAction = async (data) => {
  try {
    const response = await apiServices.updatePresaleRDexListingR(data);
    return response
  } catch (err) {
    console.log(err, 'updatePresaleRDexLRAction');
    // throw JSON.stringify(err);
  }
}

export const getHeighlightedPresalesAction = async () => {
  try {
    const response = await apiServices.getHeighlightedPresalesService();
    return response
  } catch (err) {
    console.log(err, 'getHeighlightedPresalesAction');
    // throw JSON.stringify(err);
  }
}

export const lockAction = async (data) => {
  try {
    const response = await apiServices.lockService(data);
    return response
  } catch (err) {
    console.log(err, 'lockAction');
    // throw JSON.stringify(err);
  }
}

export const getLocksAction = async (data) => {
  try {
    const response = await apiServices.getLocksService(data);
    return response
  } catch (err) {
    console.log(err, 'getLocksAction');
    // throw JSON.stringify(err);
  }
}

export const getMyLocksServiceAction = async (data) => {
  try {
    const response = await apiServices.getMyLocksService(data);
    return response
  } catch (err) {
    console.log(err, 'getMyLocksServiceAction');
    // throw JSON.stringify(err);
  }
}


export const getALockAction = async (data) => {
  try {
    const response = await apiServices.getALockService(data);
    return response
  } catch (err) {
    console.log(err, 'getALockAction');
    // throw JSON.stringify(err);
  }
}

export const updateRatesAfterFinalizeAction = async (data) => {
  try {
    const response = await apiServices.updateRatesAfterFinalizeService(data);
    return response
  } catch (err) {
    console.log(err, 'updateRatesAfterFinalizeAction');
    // throw JSON.stringify(err);
  }
}

export const unlockAction = async(data)=>{
  try {
    const response = await apiServices.unlockService(data);
    return response
  } catch (err) {
    console.log(err, 'unlockAction');
    // throw JSON.stringify(err);
  }
}

export const postStakingAction = async(data)=>{
  try {
    const response = await apiServices.postStakingService(data);
    return response
  } catch (err) {
    console.log(err, 'postStakingAction');
    // throw JSON.stringify(err);
  }
}

export const homeInfoService = async(data)=>{
  try {
    const response = await apiServices.homeInfoService(data);
    return response
  } catch (err) {
    console.log(err, 'homeInfoService');
    // throw JSON.stringify(err);
  }
}

export const getStacksAction = async(data)=>{
  try {
    const response = await apiServices.getStakingService(data);
    return response
  } catch (err) {
    console.log(err, 'getStacksAction');
    // throw JSON.stringify(err);
  }
}


export const getStakeDetailsAction = async(data)=>{
  try {
    const response = await apiServices.getStakeDetails(data);
    return response
  } catch (err) {
    console.log(err, 'getStakeDetails');
    // throw JSON.stringify(err);
  }
}

export const extendLockerTimeAction = async(data)=>{
  try {
    const response = await apiServices.extendLockerTime(data);
    return response
  } catch (err) {
    console.log(err, 'update-locker');
    // throw JSON.stringify(err);
  }
}

export const updateStakingDetailsAction = async(data)=>{
  try {
    const response = await apiServices.updateStakingDetails(data);
    return response
  } catch (err) {
    console.log(err, 'updateStakingDetailsAction');
    // throw JSON.stringify(err);
  }
}

export const cancelStakeAction = async(data)=>{
  try {
    const response = await apiServices.cancelStakeService(data);
    return response
  } catch (err) {
    console.log(err, 'cancelStakeService');
    // throw JSON.stringify(err);
  }
}
export const depositeWithdrawalStakAction = async(data)=>{
  try {
    const response = await apiServices.depositeWithdrawalStak(data);
    return response
  } catch (err) {
    console.log(err, 'depositeWithdrawalStakAction');
    // throw JSON.stringify(err);
  }
}

export const stakeAction = async(data)=>{
  try {
    const response = await apiServices.stakeService(data);
    return response
  } catch (err) {
    console.log(err, 'stakeService');
    // throw JSON.stringify(err);
  }
}

export const myStakeAction = async(data)=>{
  try {
    const response = await apiServices.myStack(data);
    return response
  } catch (err) {
    console.log(err, 'myStakeAction');
    // throw JSON.stringify(err);
  }
}

export const updateLastDRewardAction = async(data)=>{
  try {
    const response = await apiServices.updateLastDReward(data);
    return response
  } catch (err) {
    console.log(err, 'updateLastDRewardAction');
    // throw JSON.stringify(err);
  }
}
export const updateLastDisTimeAction = async(data)=>{
  try {
    const response = await apiServices.updateLastDisTime(data);
    return response
  } catch (err) {
    console.log(err, 'updateLastDisTimeAction');
    // throw JSON.stringify(err);
  }
}

export const getStakedDetailsAction = async(data)=>{
  try {
    const response = await apiServices.getStakedDetailsService(data);
    return response
  } catch (err) {
    console.log(err, 'getStakedDetailsAction');
    // throw JSON.stringify(err);
  }
}

export const updateAuditAction = async(data)=>{
  try {
    const response = await apiServices.updateAuditService(data);
    return response
  } catch (err) {
    console.log(err, 'updateAuditAction');
    // throw JSON.stringify(err);
  }
}

export const updateSaleStatusAction = async(data)=>{
  try {
    const response = await apiServices.updateSaleStaus(data);
    return response
  } catch (err) {
    console.log(err, 'updateAuditAction');
    // throw JSON.stringify(err);
  }
}


export const tokenIsStakeAction= async(data)=>{
  try {
    const response = await apiServices.tokenIsStakeService(data);
    return response
  } catch (err) {
    console.log(err, 'tokenIsStakeService');
    // throw JSON.stringify(err);
  }
}

export const sendOTPSubscAction= async(data)=>{
  try {
    const response = await apiServices.sendOTPSubsc(data);
    return response
  } catch (err) {
    toast.error(err?.data?.message)
    console.log(err, 'sendOTPSubscAction');
    // throw JSON.stringify(err);
  }
}

export const subscriptionRegisterAction= async(data)=>{
  try {
    const response = await apiServices.subscipRegisterService(data);
    return response
  } catch (err) {
    toast.error(err?.data?.message)
    console.log(err, 'subscriptionRegisterAction');
    // throw JSON.stringify(err);
  }
}

export const getPackageListAction= async(data)=>{
  try {
    const response = await apiServices.getPackageListService(data);
    return response
  } catch (err) {
    console.log(err, 'getPackageListAction');
    // throw JSON.stringify(err);
  }
}

export const checkRegisterAction= async(data)=>{
  try {
    const response = await apiServices.checkRegisterService(data);
    return response
  } catch (err) {
    console.log(err, 'checkRegisterAction');
    // throw JSON.stringify(err);
  }
}

export const subscribeAction= async(data)=>{
  try {
    const response = await apiServices.subscribe(data);
    return response
  } catch (err) {
    console.log("err?.data?.error ",err?.data?.error )
    toast.error(err?.data?.error || err?.data?.message)
    console.log(err, 'subscribeAction');
    // throw JSON.stringify(err);
  }
}

export const checkSubscriptionAction= async(data)=>{
  try {
    const response = await apiServices.checkSubscription(data);
    return response
  } catch (err) {
    console.log("err?.data?.error ",err?.data?.error )
    toast.error(err?.data?.error || err?.data?.message)
    console.log(err, 'checkSubscriptionAction');
    // throw JSON.stringify(err);
  }
}

export const packageDetailActions= async(data)=>{
  try {
    const response = await apiServices.packageDetails(data);
    return response
  } catch (err) {
    console.log("err?.data?.error ",err?.data?.error )
    toast.error(err?.data?.error || err?.data?.message)
    console.log(err, 'packageDetailActions');
    // throw JSON.stringify(err);
  }
}

export const checkAvailabilityAction= async(data)=>{
  try {
    const response = await apiServices.checkAvailability(data);
    return response
  } catch (err) {
    console.log("err?.data?.error ",err?.data?.error )
    toast.error(err?.data?.error || err?.data?.message)
    console.log(err, 'checkAvailabilityAction');
    // throw JSON.stringify(err);
  }
}

export const checkPayentAction= async(data)=>{
  try {
    const response = await apiServices.checkPayent(data);
    return response
  } catch (err) {
    console.log("err?.data?.error ",err?.data?.error )
    toast.error(err?.data?.error || err?.data?.message)
    console.log(err, 'checkPayentAction');
    // throw JSON.stringify(err);
  }
}

export const subsDetailsAction= async(data)=>{
  try {
    const response = await apiServices.subsDetailsService(data);
    return response
  } catch (err) {
    console.log("err?.data?.error ",err?.data?.error )
    toast.error(err?.data?.error || err?.data?.message)
    console.log(err, 'subsDetailsAction');
    // throw JSON.stringify(err);
  }
}

export const storeHashAction= async(data)=>{
  try {
    const response = await apiServices.storeHash(data);
    return response
  } catch (err) {
    console.log("err?.data?.error ",err?.data?.error )
    toast.error(err?.data?.error || err?.data?.message)
    console.log(err, 'storeHashAction');
    // throw JSON.stringify(err);
  }
}


export const transactionErrorAction= async(data)=>{
  try {
    const response = await apiServices.transactionError(data);
    return response
  } catch (err) {
    console.log("err?.data?.error ",err?.data?.error )
    toast.error(err?.data?.error || err?.data?.message)
    console.log(err, 'transactionErrorAction');
    // throw JSON.stringify(err);
  }
}


export const getCoinPriceAction= async(data)=>{
  try {
    const response = await apiServices.getCoinPriceService(data);
    return response
  } catch (err) {
    console.log("err?.data?.error ",err?.data?.error )
    toast.error(err?.data?.error || err?.data?.message)
    console.log(err, 'getCoinPriceAction');
    // throw JSON.stringify(err);
  }
}

export const checkReferrerAction = async(data)=>{
  try {
    const response = await apiServices.checkReferrerService(data);
    return response
  } catch (err) {
    console.log("err?.data?.error ",err?.data?.error )
    toast.error(err?.data?.error || err?.data?.message)
    console.log(err, 'checkReferrerAction');
    // throw JSON.stringify(err);
  }
}

export const getSubsDetailsAction = async(data)=>{
  try {
    const response = await apiServices.getSubsDetailsService(data);
    return response
  } catch (err) {
    console.log("err?.data?.error ",err?.data?.error )
    toast.error(err?.data?.error || err?.data?.message)
    console.log(err, 'getSubsDetailsAction');
    // throw JSON.stringify(err);
  }
}