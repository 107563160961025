import React, { useEffect, useState } from "react";
import {  extendLockerTimeAction, getALockAction, getCurrentBnbPriceAction, unlockAction } from "../../redux/apiActions/api.action";
import queryString from "query-string";
import moment from "moment";
import { erc20ABI, useContractRead, useContractWrite, useNetwork, useToken, useWaitForTransaction,useAccount } from "wagmi";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LOCKERS_ADDRESSESS } from "../../_constant";
import LockerAddress from "../../_constant/Locker.json";
import { TRANSACTIONMSGS } from "../../utils/messages";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import SimpleToken from "../../_constant/SimpleToken.json";
import { decodeEventLog, parseAbi } from "viem";
import { bscTestnet } from "viem/chains";
import { SyncLoader } from "react-spinners";
const currentTime = dayjs(); // Get the current time
const startTime = currentTime.add(1, 'month');

export default function TokenLockDetails() {

    const { chain } = useNetwork()

    const { address } = useAccount()


    const LOCKER_ADDRESS = LOCKERS_ADDRESSESS[chain?.network]

    const { search } = window.location;
    const { token } = queryString.parse(search);
    const [lock, setLock] = useState([])
    const [lockId,_lockId] = useState()
    const [refreshFlag,setRefreshFlag] = useState(false)

    const [extendTime, setExtendTime] = useState(startTime)

    const [loading, _loading] = useState(false)
    async function getTokenLockDetail() {
        try{
            _loading(true)
            let { data } = await getALockAction({ token: token });
            // console.log("data",data?.dat)
            setLock(data?.data)
        }catch(error){
            
        }finally{
            _loading(false)
        }

    }

    useEffect(() => {
        getTokenLockDetail()
    }, [refreshFlag])

    const { data: tokenInfo, isSuccess: isTokenFetched, isError: isErrorTokenFetch,isLoading:isGettingTokenInfo,isFetching:isFetchingTokenInfo } = useToken({
        address: token,
        // onError: onTokenFetchError,
        // onSuccess: onSuccessPresaleToken,
    });

    function handleDateChangeStart(dateString) {
        setExtendTime(moment(new Date(dateString)).unix())
        // formikStartPresale.setFieldValue("start_date", moment(new Date(dateString)).unix())

    }


    const popperSx = {
        "& .MuiPaper-root": {
            border: "1px solid black",
            padding: 2,
            marginTop: 1,
            backgroundColor: "rgba(120, 120, 120, 0.2)",
            minWidth: "100%"
        },
        "& .MuiCalendarPicker-root": {
            backgroundColor: "rgba(45, 85, 255, 0.4)"
        },
        "& .PrivatePickersSlideTransition-root": {},
        "& .MuiPickersDay-dayWithMargin": {
            color: "rgb(229,228,226)",
            backgroundColor: "rgba(50, 136, 153)"
        },
        "& .MuiTabs-root": { backgroundColor: "rgba(120, 120, 120, 0.4)" }
    };


    const handleLockExtend = ()=>{
        const closeButton = document.getElementById('btn-close');
        closeButton.click();
        extendLock({args:[lockId, extendTime*1000]})
    }


    const onErrorExtendLock = (error)=>{
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
           toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }else if(error.toString().includes('InvalidExtendTime')){
            toast.error('Wrong timer!')
        } else {
            toast.error(TRANSACTIONMSGS.WRONGADDRESS)
          }
    }

    const onSuccessExtendLock = () => {
    }



    const {
        data: dataExtLock,
        write: extendLock,
        isLoading: isLoadingLock,
        isSuccess: isSuccessLock,
    } = useContractWrite({
        address: LOCKER_ADDRESS,
        abi: LockerAddress.abi,
        functionName: "extend",
        onError: onErrorExtendLock,
        // args: [token, lockUntil, amount],
        onSuccess: onSuccessExtendLock
    });


    const onSuccessWFTExtLock =async ({logs})=>{
        const lastIndex = chain?.id ==bscTestnet.id?logs.length - 1:logs.length - 2; //DynamicChain
      

        const  {args}  = decodeEventLog({
            abi: parseAbi([
              "event Extended(uint256 lockId, uint256 oldEndTime, uint256 newEndTime)",]),
            // `data` should be 64 bytes, but is only 32 bytes.
            data: logs[lastIndex]?.data,
            topics: [
              logs[lastIndex]?.topics[0],
            ],
          });
         const data= await   extendLockerTimeAction({id:Number(args?.lockId),newTime:Number(args?.newEndTime)})
           if (data.status==200){
            toast.success(data?.data?.message)
            setRefreshFlag(!refreshFlag)
           }
    }   

    const { isLoading: isLoadingWFTLock } = useWaitForTransaction({
        hash: dataExtLock?.hash,
        // onSettled: onApproveReceipt,
        onSuccess: onSuccessWFTExtLock
    });

//     address:TOKEN_ADDRESS,
// functionName: "balanceOf",
// abi: erc20ABI,
// args: [LOCKER_CONTRACT_ADDRESS]

const { data: balanceOf } = useContractRead({
    /// PreSale token address
    // @ts-ignore
    address: token,
    abi: SimpleToken.abi,
    functionName: "balanceOf",
    args: [LOCKER_ADDRESS],
    // onError: onTokenFetchError,
  });

  const { data: preSaleToken } = useToken({
    address:token,
    // onError: onTokenFetchError,
    // onSuccess: onSuccessPresaleToken,
  });

  const [currentMarketPrice,_currentMarketPrice] = useState(0)
  const currentMarketValue = async()=>{
    const data = await getCurrentBnbPriceAction({symbol:preSaleToken?.symbol})
    _currentMarketPrice(data?.data?.price || 0)

  }

  useEffect(()=>{
    currentMarketValue()
  },[preSaleToken?.symbol])

//   function unlock(uint256 _lockId)
const [currUnLocking,_currunLocking]=useState("")
const onUnlockError = (error)=>{
    _currunLocking("")
    if(error.toString().includes(" User rejected the request")){
        toast.error('User rejected transaction!')
    }else{
        toast.error("Something went wrong! Please try again")
    }
}



const { data: dataUnlock, isLoading: isLoadingUnlock, isSuccess: isSuccessUnlock, isError: isErrorUnlock, write: unlock } = useContractWrite({
    address: LOCKER_ADDRESS,
    abi: LockerAddress.abi,
    functionName: 'unlock',
    // args: [],
    onError: onUnlockError,
    // value: perkFees?.[formData?.tier]?.result ?? 0
    // onSuccess:onSuccessCreatePreSale
  })

//   dataUnlock

const onSuccessWFTUnLock = async(data)=>{
    try{
        const reponse = await unlockAction({address:address,locker_id:currUnLocking})
        if(reponse){
         toast.success("Unlocked!")
         getTokenLockDetail()
        }
    }catch(err){

    }finally{
        _currunLocking("")
    }
}

const { isLoading: isLoadingWFTUnLock } = useWaitForTransaction({
    hash: dataUnlock?.hash,
    // onSettled: onApproveReceipt,
    onSuccess: onSuccessWFTUnLock
});
  const unlockFunction = (id)=>{
    _currunLocking(id)
    unlock({args:[Number(id)]})
  }

  document.title =  tokenInfo?.name ? `${tokenInfo?.name} Token Lock` : 'Token Locks'
 
  function sisMillisecondTime(timestamp) {
    // Check if the timestamp is greater than or equal to 10^12 (1 trillion).
    // If it is, it's likely in milliseconds; otherwise, it's likely in seconds.
    return timestamp >= 1e12;
  }

  return (
        <div>


            <div className="container-fluid mt-5">
                {
                    loading || isGettingTokenInfo || isFetchingTokenInfo?
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <SyncLoader color="#3498DB" />
                    </div>
                    :
                    <>
                <div className="approve_token_card card">
                    <h4 className="card-header">Lock info</h4>
                    <div>
                        <hr className="m-0" />
                        <div className="card-body">
                            <div className="mb-3 d-flex justify-content-between">
                                <span className="card-text">Total Amount Locked</span>
                                <p className="card-text">{(preSaleToken&&lock) && ethers.utils.formatUnits(String(lock[0]?.total_amount.split('.')[0]||0),preSaleToken?.decimals||0)}</p>                            </div>
                            <hr />
                            <div className="mb-1 d-flex justify-content-between">
                                <span className="card-text">Total Values Locked</span>
                                <p className="card-text">{(balanceOf && preSaleToken)&& Number(ethers.utils.formatUnits(balanceOf, preSaleToken?.decimals)) * currentMarketPrice || 0} </p>
                            </div>
                            <hr />
                            <div className="mb-1 d-flex justify-content-between">
                                <span className="card-text">Token Address</span>
                                <p className="card-text">{tokenInfo?.address}</p>
                            </div>
                            <hr />
                            <div className="mb-1 d-flex justify-content-between">
                                <span className="card-text">Token Name</span>
                                <p className="card-text">{tokenInfo?.name}</p>
                            </div>
                            <hr />
                            <div className="mb-1 d-flex justify-content-between">
                                <span className="card-text">Token Symbol</span>
                                <p className="card-text">{tokenInfo?.symbol}</p>
                            </div>
                            <hr />
                            <div className="mb-1 d-flex justify-content-between">
                                <span className="card-text">Token Decimals</span>
                                <p className="card-text">{tokenInfo?.decimals}</p>
                            </div>
                            <hr />
                            <div className="mb-1 d-flex justify-content-between">
                                <span className="card-text">Owner</span>
                                <p className="card-text">{lock[0]?.address}</p>
                            </div>
                            <hr />
                        </div></div>

                </div>


                <div className="container-fluid mt-5">
                    <div className="approve_token_card card">
                        <h4 className="card-header">Lock Records</h4>
                        <div>
                            <hr className="m-0" />
                            <div className="card-body">
                                <div class="container mt-3">
                                    <div className="table-responsive">
                                        <table class="table quick_view_table">
                                            <thead>
                                                <tr>
                                                    <th>Token</th>
                                                    <th>Lock time</th>
                                                    <th>Unlock time</th>
                                                    {/* { lock?.map((data) => (  data?.address===address&&(<> <th>Unlock</th>
                                                    <th>Extend</th></>)))} */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                lock?.map((data) => (
                                                    <tr>
                                                        <td>{ethers.utils.formatUnits(String(data?.amount.split('.')[0]),JSON.parse(data.token_info)?.decimal)}</td>
                                                        {/* <td>{moment.unix(data?.startedAt).format('YYYY-MM-DD HH:mm')}</td> */}
                                                        <td>{sisMillisecondTime(data?.endAt)?moment.unix(data?.endAt/1000).format('YYYY-MM-DD HH:mm'):moment.unix(Number(data?.endAt)).format('YYYY-MM-DD HH:mm')}</td>
                                                         <td className="btn-hide-fix-width">
                                                         {data?.address===address&& <button type="button" class="m-1 btn btn-secondary" disabled={data?.endAt > moment().unix()?true:false || isLoadingUnlock || isLoadingWFTUnLock} onClick={()=>{unlockFunction(data?.locker_id)}}>{currUnLocking==data?.locker_id?'Unlocking...':'Unlock'}</button>}
                                                        </td>
                                                        <td className="btn-hide-fix-width">
                                                        {data?.address===address&& <button
                                                                type="button"
                                                                className="m-1 nextBtn"
                                                                href="#modal"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#extendLockModel"
                                                                onClick={()=>{_lockId(data?.locker_id)}}
                                                                disabled={isLoadingLock || isLoadingWFTLock}
                                                            >{data?.locker_id == lockId && isLoadingLock || isLoadingWFTLock?'Extending...':'Extend'}</button>}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                }

            </div>

            <div className="modal" id="extendLockModel">
                    <button type="button" className="btn-close" id="btn-close" data-bs-dismiss="modal" hidden={false} />
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div id="myDropdown"
                                className="dropdown-content px-2 py-4"
                            >
                                <h5 className="text-center text-blue mb-4">Extend Lock</h5>
                                <div className="row row-cols-1 row-cols-sm-2">
                                    <div className="col">
                                        {/* <label className="fieldlabels">This pool will be cancelled.</label> */}
                                        <div id="approve_token_form">
                                            {/* <label htmlFor="liquidity-lock form-label">Sale Start Time:</label> */}
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <DemoContainer
                                                    components={["MobileDateTimePicker", "MobileDateTimePicker"]}
                                                    PopperProps={{
                                                        sx: popperSx
                                                    }}
                                                >
                                                    <MobileDateTimePicker
                                                        className="form-control"
                                                        // label={'Start Date (UTC)'}
                                                        openTo="hours"
                                                        name="startTime"
                                                        onChange={handleDateChangeStart}
                                                        defaultValue={startTime}
                                                        PopperProps={{
                                                            sx: popperSx
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    <div style={{ overflow: "auto" }}>
                                        <div className="d-flex gap-3 pt-4 py-2 justify-content-center">
                                            <button
                                                type="button"
                                                id="prevBtnClose"
                                                
                                                className="btn btn-danger m-0"
                                                data-bs-dismiss="modal"
                                                disabled={isLoadingLock || isLoadingWFTLock}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                id="nextBtn"
                                                className="btn btn-primary m-0"
                                                onClick={()=>{handleLockExtend()}}
                                                disabled={isLoadingLock || isLoadingWFTLock}
                                            >
                                              {isLoadingLock || isLoadingWFTLock ? 'Extending...':'Ok'}  
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}