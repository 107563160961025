import React, { useEffect, useState } from "react";
import { useContractRead, useContractWrite, useNetwork, useToken, useWaitForTransaction } from "wagmi";
import SimpleToken from "../../_constant/SimpleToken.json";
import { getAccount, getNetwork } from "@wagmi/core"
import { constants, ethers } from "ethers";
import { BASE_URL, HLPR_CONTRACT_ADDRESSES, HLPR_MANAGENR_ADDRESS } from "../../_constant";
import { checkPayentAction, checkRegisterAction, getCoinPriceAction, packageDetailActions, storeHashAction, subscribeAction, transactionErrorAction } from "../../redux/apiActions/api.action";
import queryString from "query-string";
import { FadeLoader, SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { decodeEventLog, parseAbi } from "viem";
import Success from "./Success";
import { ERRORMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { useNavigate } from "react-router-dom";


const Payment = () => {

    const account = getAccount()


    const {chain} = useNetwork()

    const  HLPR_CONTRACT_ADDRESS = HLPR_CONTRACT_ADDRESSES[chain?.network]

    const [paymentMethod, _paymentMethod] = useState("1")
    const handlePackageSelect = (method) => {
        _paymentMethod(method);
    };

    const onTokenFetchError = () => {
    }

    const { data: balanceOf } = useContractRead({
        address: HLPR_CONTRACT_ADDRESS,
        abi: SimpleToken.abi,
        functionName: "balanceOf",
        args: [account.address],
        watch: true,
        onError: onTokenFetchError,
    });



    const { data: dataHelper, isSuccess: isTokenFetched, isError: isErrorHlpr, isLoading: isLoadingHlpr, isFetching: isFetchingHlpr } = useToken({
        address: HLPR_CONTRACT_ADDRESS,
        onError: onTokenFetchError
    });

    const checkRegister = async () => {
        try {
          let bodyData = { address: account?.address };
          const { data } = await checkRegisterAction(bodyData);
          if(data?.data?.status != true){
            navigate(BASE_URL+'subscription')
          }
        } catch (err) {
        } finally {
        }
      };

      useEffect(()=>{
        checkRegister()
      },[account?.address])


    const { search } = window.location;
    const { package_id } = queryString.parse(search);

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [packageDetails, setPackageDetails] = useState({})
    const fetchPackageDetails = async () => {
        try {
            setLoading(true)
            let bodyData = { package_id: package_id }
            let { data } = await packageDetailActions(bodyData)
            if (!data?.data) {
                navigate(BASE_URL + 'packages')
            } else {
                setPackageDetails(data?.data)
            }
        } catch (err) {
        } finally {
            setLoading(false)
        }

    }

    useEffect(() => {
        fetchPackageDetails()
    }, [])

    const onErrorTransHlpr = async (error) => {
        _transhelper(false)
        if (error.toString().includes("User rejected the request")) {
            toast.error("User Rejected Transaction", { toastId: 'urtTid' })
        } else if (error.toString().includes("transfer amount exceeds balance")) {
            toast.error(ERRORMSGS.TRANSFER_AMOUNT_EXCEED_BALANCE, { toastId: 'tbebTid' })
        } else {
            let bodyData = { address: account?.address, error_response: error, route: '/transfer helper' }
            await transactionErrorAction(bodyData)
            toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG, { toastId: 'smwTid' })
        }


    }

    const [isPayed, _isPayed] = useState(false);

    const [hlprCoinPrice, sethlprCoinPrice] = useState()
    const [fetchingCoinPrice,setFetchCoinPrice] = useState(false)
    const getHelprPriceFunc = async () => {
        const params = new URLSearchParams();
        params.append('package_id', package_id);
        params.append('payment_method', paymentMethod);
        params.append("address", account?.address)
        try{
            setFetchCoinPrice(true)
            const { data } = await getCoinPriceAction(params)
            sethlprCoinPrice(data?.data)
            return data;
        }catch(err){

        }finally{
            setFetchCoinPrice(false)
        }
        
    }


    const onSuccessTrnsHlpr = async (success) => {
        let bodyData = { address: account?.address, package_id: package_id, payment_method: paymentMethod, hash: success.hash, total_coin: hlprCoinPrice?.amount, coin_price: hlprCoinPrice?.coin_price, received_amount: hlprCoinPrice?.originalAmount }
        const { data } = await storeHashAction(bodyData)
        if (data) {
            toast.success(data?.message, { toastId: 'hgsTid' })
        }

    }

    const {
        data: dataPayment,
        write: TransferHelper,
        isLoading: isLoadingHlprTrans,
        isSuccess: isSuccessHlprTrans,
    } = useContractWrite({
        address: HLPR_CONTRACT_ADDRESS,
        abi: SimpleToken.abi,
        functionName: "transfer",
        // args: [HLPR_MANAGENR_ADDRESS, ethers.utils.parseUnits(String(0.005), dataHelper?.decimals || 18).toString()],
        onError: onErrorTransHlpr,
        onSuccess: onSuccessTrnsHlpr,
    });

    const [paying, setPaying] = useState(false)
    const onSuccessWFTTransHlpr = async (data) => {
        console.log("receipt", data)
        try {
            setPaying(true)
            let bodyData = { package_id: package_id, address: account?.address, package_id: package_id, payment_method: paymentMethod, hash: data?.transactionHash }
            //   
            const response = await subscribeAction(bodyData)
            if (response) {
                _isPayed(true)
            }
        } catch (err) {

        } finally {
            setPaying(false)
        }

    }



    const onErrorWFTTransHlpr = (error) => {
        console.log('Error', error)
    }
    const { data: dataTrasHlpt, isError: isErrorTransHlpr, isLoading: isLoadingWTFTransHlpr } = useWaitForTransaction({
        hash: dataPayment?.hash,
        onSuccess: onSuccessWFTTransHlpr,
        onError: onErrorWFTTransHlpr
    })


    const [transhelper, _transhelper] = useState(false)
    const MakePayment = async () => {
        var button = document.getElementById("prevBtnCloseUpdate");
        button.click();
        if (!account?.isConnected) {
            return toast.error(ERRORMSGS.LOGINWITHYOURWALLET, { toastId: 'cwywFTid' })
        }
        if (!paymentMethod) {
            return toast.error("Select payment method!", { toastId: 'spmTid' })
        } else {
            if (paymentMethod == "2" || paymentMethod == '3' || paymentMethod == '4') {
                const bodyData = { address: account?.address }
                const checkCashback = await checkPayentAction(bodyData)

                if (checkCashback?.data?.data?.remaining_cashback == null) {
                    toast.error("Insufficient cashback!")
                } else {
                    _transhelper(true)
                    const { data } = getHelprPriceFunc()
                    const amount = data?.amount;
                    TransferHelper({ args: [HLPR_MANAGENR_ADDRESS, ethers.utils.parseUnits(String(amount), dataHelper?.decimals || 18).toString()], })
                }
            } else {
                _transhelper(true)
                const { data } = await getHelprPriceFunc()
                const amount = data?.amount;
                TransferHelper({ args: [HLPR_MANAGENR_ADDRESS, ethers.utils.parseUnits(String(amount), dataHelper?.decimals || 18).toString()], })
            }

        }
    }

    const openModel = async()=>{
        try{
            const userHplprBal = Number(ethers.utils.formatUnits(String(balanceOf || 0), dataHelper?.decimals || 0));
            if(userHplprBal ==0){
                return toast.error("Insufficient balance to purchase this package!",{toastId:'isbtpTid'})
            }
            
            const { data } =await getHelprPriceFunc()
            const amount = data?.amount;
            const userHlprBalance = Number(ethers.utils.formatUnits(String(balanceOf || 0), dataHelper?.decimals || 0));
            if(userHlprBalance !== amount && userHlprBalance < amount){
                return toast.error("Insufficient balance to purchase this package!",{toastId:'isbtpTid'})
            }
            if(data){
               var button = document.getElementById("openModelConfirm");
               button.click();
            }
        }catch(err){

        }finally{
           
        }
        

    }


    return (
        <>
            {
                isPayed ?
                    <Success />
                    :
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xxl-7 col-lg-10 col-md-12 mx-auto">
                                <div className="row mt-5">
                                    <div className="row">
                                        <div className="col-md-12 mx-auto">
                                            <div className="approve_token_section">
                                                <h3 className="text-center">Subscription</h3>
                                                <div className="package_token_card mt-2">

                                                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 gap-y-3">
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="dark_bg p-4 d-flex flex-wrap justify-content-between align-items-center">
                                                                <p className="mb-0">Package</p>
                                                                <h4 className="text-white">{loading || Object.keys(packageDetails).length === 0 ? <SyncLoader color="#3498DB" size={10} /> : packageDetails?.title}</h4>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 col-md-12">
                                                            <h5 className="text-white mb-3">Choose Payment Method</h5>
                                                            <div className="dark_bg p-3">
                                                                <ul className="d-flex flex-wrap align-items-center justify-content-start gap-4">
                                                                    <li>
                                                                        <p className="mb-0 text-blue">HLPR Tokens Available</p>
                                                                        <h4 className="text-lightblue">{
                                                                            (isLoadingHlpr || isFetchingHlpr) ?
                                                                                <SyncLoader color="#3498DB" size={10} />
                                                                                :
                                                                                Number(ethers.utils.formatUnits(String(balanceOf || 0), dataHelper?.decimals || 0)).toFixed(2)

                                                                        }</h4>
                                                                    </li>

                                                                    <li className="package_choose_payment_method_border d-none d-sm-block"></li>

                                                                    <li>
                                                                        <p className="mb-0 text-blue">Earning Wallet Available</p>
                                                                        <h4 className="text-lightblue">0.00$</h4>
                                                                    </li>

                                                                </ul>


                                                                <div className="package_choose_payment_method mt-3">
                                                                    <label class="radio-button-container" disabled>100% HLPR tokens
                                                                        <input type="radio" name="radio" id="1" checked={paymentMethod == '1'}
                                                                            onChange={() => { handlePackageSelect('1') }}
                                                                        />
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    <label class="radio-button-container">
                                                                        <ul className="d-flex flex-wrap align-items-center justify-content-start gap-sm-3 gap-1">
                                                                            <li>50% Cashback</li>
                                                                            <li className="plus_circle"><i class="fa fa-plus-circle" aria-hidden="true"></i></li>
                                                                            <li>50% HLPR tokens</li>
                                                                        </ul>
                                                                        <input type="radio" name="radio" id="2" checked={paymentMethod == "2"} 
                                                                        onChange={() => { handlePackageSelect('2') }}
                                                                        />
                                                                        <span class="checkmark"></span>
                                                                    </label>

                                                                    <label class="radio-button-container">
                                                                        <ul className="d-flex flex-wrap align-items-center justify-content-start gap-sm-3 gap-1">
                                                                            <li>25% Cashback</li>
                                                                            <li className="plus_circle"><i class="fa fa-plus-circle" aria-hidden="true"></i></li>
                                                                            <li>50% HLPR token</li>
                                                                            <li className="plus_circle"><i class="fa fa-plus-circle" aria-hidden="true"></i></li>
                                                                            <li>25% scanner’s earning wallet</li>
                                                                        </ul>

                                                                        <input type="radio" name="radio" id="3" checked={paymentMethod == '3'} onChange={() => { toast.info("comming soon!") }} />
                                                                        <span class="checkmark"></span>
                                                                    </label>

                                                                    <label class="radio-button-container mb-0">
                                                                        <ul className="d-flex flex-wrap align-items-center justify-content-start gap-sm-3 gap-1">
                                                                            <li>50% HLPR tokens</li>
                                                                            <li className="plus_circle"><i class="fa fa-plus-circle" aria-hidden="true"></i></li>
                                                                            <li>50% Scanner’s earning wallet</li>
                                                                        </ul>
                                                                        <input type="radio" name="radio" id="4" checked={paymentMethod == "4"} onChange={() => { toast.info("comming soon!") }} />
                                                                        <span class="checkmark"></span>
                                                                    </label>

                                                                </div>
                                                            </div>
                                                                        
                                                                        <button hidden 
                                                                                     id="openModelConfirm"
                                                                                     type="button"
                                                                                     href="#modal"
                                                                                     data-bs-toggle="modal"
                                                                                     data-bs-target="#cancelPresaleModal"
                                                                        />

                                                            <div className="col-sm-12 col-md-12 mt-3">
                                                                <div className="packages_btns d-flex flex-wrap align-items-center justify-content-center">
                                                                    {
                                                                        isLoadingHlprTrans || isLoadingWTFTransHlpr || paying || transhelper || fetchingCoinPrice ?
                                                                            <FadeLoader color="#3498DB" />
                                                                            :
                                                                            <button class="btn subscribe_btn"
                                                                                disabled={fetchingCoinPrice}
                                                                                // onClick={()=>{MakePayment()}} disabled={isLoadingHlprTrans || isLoadingWTFTransHlpr || paying || transhelper}
                                                                                onClick={()=>{openModel()}}
                                                                            >
                                                                                {
                                                                                    'Make Payment'
                                                                                }
                                                                            </button>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

{/* Update Pool Model */}
<div className="modal" id="cancelPresaleModal">
    <div className="modal-dialog">
        <div className="modal-content">

            <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" hidden={false}></button>
            </div>

            <div className="modal-body">
                <div id="myDropdown" className="dropdown-content px-2 py-4">
                <h3 className="text-center text-white mb-4">Payment Details</h3>
                    <table className="table" style={{ borderBottom: 'none' }}>
                        <tbody>
                            <tr style={{color:'#09D2D9',border:'#ffffff'}}>
                                <td>Subscription amount</td>
                                <td>{fetchingCoinPrice? 'Loaing...':`$${hlprCoinPrice?.originalAmount_currency}`}</td>
                            </tr>
                            <tr style={{color:'#09D2D9',border:'#ffffff'}}>
                                <td>Helper Coin Amount</td>
                                <td>{fetchingCoinPrice ?'Loaing...': Number(hlprCoinPrice?.originalAmount)?.toFixed(6)}</td>
                            </tr>
                            {/* <tr style={{color:'#09D2D9',border:'#ffffff'}}>
                                <td>Helper Amount in USD</td>
                                <td>{fetchingCoinPrice ? 'Loading...' : `$${Number(hlprCoinPrice?.amount)?.toFixed(2)}`}</td>
                            </tr> */}
                            <tr style={{color:'#09D2D9',border:'#ffffff'}}>
                                <td>Total Paid Amount</td>
                                <td>{fetchingCoinPrice ? 'Loading...' : `$${Number(hlprCoinPrice?.amount_currency)?.toFixed(2)} (${Number(hlprCoinPrice?.amount)?.toFixed(6)} HLPR)`}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div style={{ overflow: "auto" }} className="d-flex justify-content-center"> {/* Center the buttons */}
                          <div className="d-flex gap-3 py-2">
                            <button
                              type="button"
                              id="prevBtnCloseUpdate"
                              className="btn btn-danger m-0"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              id="nextBtn"
                              className="btn btn-primary m-0"
                              disabled={fetchingCoinPrice}
                              onClick={()=>{MakePayment()}}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                </div>
            </div>

        </div>
    </div>
</div>



        </>
    )
}

export default Payment;