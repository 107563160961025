import { useState, useEffect } from "react";
import Select, { components } from "react-select";
import StackingHeader from "./StackingHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { APP_NAME_TITLE, BASE_URL, NO_REWARD, REWARD_TYPE, networkImages, networkImagesByChain } from "../../_constant";
import { getStacksAction } from "../../redux/apiActions/api.action";
import { getAccount, watchNetwork, getNetwork } from '@wagmi/core'
import moment from "moment";
import { ethers } from "ethers";
import Pagination from "../../Components/Pagination/Pagination";
import PaginationOver from "../../Components/Pagination/PaginationOverload";
import { useAccount } from "wagmi";
import { SyncLoader } from "react-spinners";
import Banner from "../PresaleDetails/Banner";
const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "transparent";
    if (isActive) bg = "transparent";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex ",
        width: "100%",
        zIndex: 999
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" className="select_checkbox" checked={isSelected} />
            {children}
        </components.Option>
    );
};

const allOptions = [
    { value: "97", label: "tBNB" },
    { value: "80001", label: "Matic" }
];

const allOptionsStatus = [
    { value: "1", label: "Live" },
    { value: "0", label: "Cancelled" }
];

export default function StackingPool() {
    const [loading, _loading] = useState(false)
    const [activeTab, _activeTab] = useState("all_products");

    const [selectedValues, setSelectedValues] = useState([allOptions[0].value, allOptions[1].value]);
    const [selectedValuesStatus, setSelectedValuesStatus] = useState([allOptionsStatus[0].value, allOptionsStatus[1].value]);


    function setShowTab(tab) {
        // _search("")
        _activeTab(tab);
        // handleChange(undefined, undefined, undefined, undefined, tab)
        // setCurrentPage(1)
    }

    const { address } = useAccount()

    const [reward, setReward] = useState("no");
    const [status, setStatus] = useState("all")
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);

    const location = useLocation()


    const [currentNetwork, _currentNetwork] = useState(selectedValues)
    const [currentStatus, _currentStatus] = useState(selectedValuesStatus)

    const account = getAccount()
    const { chain } = getNetwork()

    const [stacks, setStacks] = useState([])

    const [totalData, setTotalData] = useState(0)

    const getStack = async () => {
        
        const urlSearchParams = new URLSearchParams(location.search);
        const page = urlSearchParams.get('page') || '1';
        const status = urlSearchParams.get('status') || '0,1';
        const reward = urlSearchParams.get('reward') || 'no';
        const networks = urlSearchParams.get('networks') || selectedValues;
        const activatedTab = urlSearchParams.get("tab") || "all_products"

        setCurrentPage(Number(page));
        setStatus(status);
        _activeTab(activatedTab)
        setReward(reward);
        _currentNetwork(networks);
        _currentStatus(currentStatus)
        let activeInfo = { activeTab: activatedTab };

        _loading(true)

        let params = { wallet: account?.address, chain_id: selectedValues || chain?.id, reward: reward, status: status, pageSize: itemsPerPage, currentPage: page };
        let mergedParams = Object.assign(activeInfo, params);
        const data = await getStacksAction(mergedParams)
        setStacks(data?.data?.data)
        setTotalData(data?.data?.totalResult?.total)
        _loading(false)
    }
    // useEffect(() => {
    //     _loading(true)
    //     setTimeout(() => {
    //         _loading(false)
    //     }, 3000)
    // }, [])
    useEffect(() => {
        getStack()
    }, [location.search, activeTab, address, chain?.id, reward, selectedValues, selectedValuesStatus])

    const [currentUnixTime, setCurrentUnixTime] = useState(moment().unix());




    const navigate = useNavigate()


    const handleChange = (page, status, rewardChange, network, tab) => {
        const searchParams = new URLSearchParams();
        searchParams.set('page', page || currentPage);
        searchParams.set('status', status || currentStatus);
        searchParams.set('reward', rewardChange || reward);
        searchParams.set('networks', network || currentNetwork);
        searchParams.set("tab", tab || activeTab)
        navigate(`${BASE_URL}stakes?${searchParams.toString()}`, { replace: true });

    }

    const handleChangeStatus = (page, status, reward, network, tab) => {
        const searchParams = new URLSearchParams();
        searchParams.set('page', page || currentPage);
        searchParams.set('status', status || currentStatus);
        searchParams.set('reward', reward || reward);
        searchParams.set('networks', network || currentNetwork);
        searchParams.set("tab", tab || activeTab)
        navigate(`${BASE_URL}stakes?${searchParams.toString()}`, { replace: true });

    }


    function setShowTab(tab) {
        _activeTab(tab);
        handleChange(undefined, undefined, undefined, undefined, tab)
    }


    const handleFilterChange = ({ target }) => {
        setStatus(target.value)
        handleChange(undefined, target.value, undefined, undefined);
    };

    const handleFilterRewardChange = ({ target }) => {
        setReward(target?.value)
        handleChange(undefined, undefined, target.value, undefined, undefined);
    };

    const handlePageChange = (page) => {
        // setCurrentPage(page);
        handleChange(page, undefined, undefined, undefined, undefined)
        setCurrentPage(page)
    }


    document.title = `${APP_NAME_TITLE} -  Stake Pools`

    return (
        <div className="container-fluid">

            <div className="row">
                <div className="presales_section d-flex justify-content-center align-items-center flex-wrap mt-3">
                    {/* <h2 className="text-white fw-lighter">Presales</h2> */}
                    <div className="presales_tabs">
                        {/* Nav tabs */}
                        <StackingHeader onButtonClick={setShowTab} actived={activeTab} />
                    </div>
                </div>
                {/* Tab panes */}
                <div className="container tab-content mt-5 px-0 px-lg-3">
                <div className="tab-content mt-3 px-0 px-lg-3">
                    {/* <div className="tab-pane container" id="quickview">
            {" "}
          </div> */}
                    <div className="tab-pane container active" id="allproducts">
                        {
                            activeTab != "quick_view" &&
                            <div className="row row-cols-1 mb-5">
                                <div className="allproducts_tab_fillter d-flex gap-3 align-items-center justify-content-end">

                                    <div className="d-flex gap-2 filter_section">
                                        <div class="input-group mb-2 coustom">
                                            <span class="input-group-text Inputlable" id="basic-addon1">Network</span>
                                            <Select
                                                isSearchable={false}
                                                defaultValue={allOptions}
                                                classNamePrefix="custom_select"
                                                className="custom_select_input"
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                onChange={(options) => {
                                                    if (Array.isArray(options)) {
                                                        setSelectedValues(options.map((opt) => opt.value))
                                                        handleChange(undefined, undefined, undefined, options.map((opt) => opt.value))
                                                    }
                                                }}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                        color: "white",
                                                    
                                                        textOverflow: "ellipsis",
                                                        flexWrap: "nowrap",
                                                        // minWidth: "150px",
                                                        border: state.isFocused ? 0 : 0,
                                                        // This line disable the blue border
                                                        boxShadow: state.isFocused ? 0 : 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? 0 : 0,
                                                            cursor: 'pointer'
                                                        },
                                                        borderColor: state.isFocused ? 'transparent' : 'transparent',
                                                    }),
                                                    placeholder: (defaultStyles) => {
                                                        return {
                                                            ...defaultStyles,
                                                            color: '#ffffff',
                                                        }
                                                    },
                                                    option: () => ({
                                                        padding: "2px 2px 2px 10px",
                                                        margin: "0.5rem 0",
                                                        zIndex: 999,
                                                    }),
                                                    multiValueRemove: (styles, { data }) => ({
                                                        ...styles,
                                                        display: "none",
                                                    }),
                                                    multiValue: (styles, { data }) => {
                                                        return {
                                                            ...styles,
                                                            backgroundColor: "transparent",
                                                        };
                                                    },
                                                    valueContainer: (provided, state) => ({
                                                        ...provided,
                                                        flexWrap: "nowrap",
                                                        textOverflow: "ellipsis",
                                                        padding: "1px 1px"
                                                    }),
                                                    multiValueLabel: (styles, { data }) => ({
                                                        ...styles,
                                                        color: data.color,
                                                    })
                                                    ,
                                                }}
                                                options={allOptions}
                                                components={{
                                                    Option: InputOption
                                                }}
                                            />
                                        </div>

                                        {/* <div className="sort_by_Select d-flex align-items-center gap-2">
                                            <p className="mb-0">Network</p>
                                            <Select
                                                defaultValue={allOptions}
                                                classNamePrefix="custom_select"
                                                className="custom_select_input"
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                onChange={(options) => {
                                                    if (Array.isArray(options)) {
                                                        setSelectedValues(options.map((opt) => opt.value))
                                                        handleChange(undefined, undefined, undefined, options.map((opt) => opt.value))
                                                    }
                                                }}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                        color: "white",
                                                    
                                                        textOverflow: "ellipsis",
                                                        flexWrap: "nowrap",
                                                        // minWidth: "150px",
                                                        border: state.isFocused ? 0 : 0,
                                                        // This line disable the blue border
                                                        boxShadow: state.isFocused ? 0 : 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? 0 : 0
                                                        },
                                                        borderColor: state.isFocused ? 'transparent' : 'transparent',
                                                    }),
                                                    placeholder: (defaultStyles) => {
                                                        return {
                                                            ...defaultStyles,
                                                            color: '#ffffff',
                                                        }
                                                    },
                                                    option: () => ({
                                                        padding: "2px 2px 2px 10px",
                                                        margin: "0.5rem 0",
                                                        zIndex: 999,
                                                    }),
                                                    multiValueRemove: (styles, { data }) => ({
                                                        ...styles,
                                                        display: "none",
                                                    }),
                                                    multiValue: (styles, { data }) => {
                                                        return {
                                                            ...styles,
                                                            backgroundColor: "transparent",
                                                        };
                                                    },
                                                    valueContainer: (provided, state) => ({
                                                        ...provided,
                                                        flexWrap: "nowrap",
                                                        textOverflow: "ellipsis",
                                                        padding: "1px 1px"
                                                    }),
                                                    multiValueLabel: (styles, { data }) => ({
                                                        ...styles,
                                                        color: data.color,
                                                    })
                                                    ,
                                                }}
                                                options={allOptions}
                                                components={{
                                                    Option: InputOption
                                                }}
                                            />
                                        </div> */}
                                        <div class="input-group mb-2 coustom">
                                            <span class="input-group-text Inputlable" id="basic-addon1">Status</span>
                                            <Select
                                                isSearchable={false}
                                                defaultValue={allOptionsStatus}
                                                classNamePrefix="custom_select"
                                                className="custom_select_input"
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                onChange={(options) => {
                                                    if (Array.isArray(options)) {
                                                        setSelectedValuesStatus(options.map((opt) => opt.value))
                                                        handleChange(undefined, options.map((opt) => opt.value), undefined, undefined)
                                                    }
                                                }}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                        color: "white",
                                                    
                                                        textOverflow: "ellipsis",
                                                        flexWrap: "nowrap",
                                                        // minWidth: "150px",
                                                        border: state.isFocused ? 0 : 0,
                                                        // This line disable the blue border
                                                        boxShadow: state.isFocused ? 0 : 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? 0 : 0,
                                                            cursor: 'pointer'
                                                        },
                                                        borderColor: state.isFocused ? 'transparent' : 'transparent',
                                                    }),
                                                    placeholder: (defaultStyles) => {
                                                        return {
                                                            ...defaultStyles,
                                                            color: '#ffffff',
                                                        }
                                                    },
                                                    option: () => ({
                                                        padding: "2px 2px 2px 10px",
                                                        margin: "0.5rem 0",
                                                        zIndex: 999,
                                                    }),
                                                    multiValueRemove: (styles, { data }) => ({
                                                        ...styles,
                                                        display: "none",
                                                    }),
                                                    multiValue: (styles, { data }) => {
                                                        return {
                                                            ...styles,
                                                            backgroundColor: "transparent",
                                                        };
                                                    },
                                                    valueContainer: (provided, state) => ({
                                                        ...provided,
                                                        flexWrap: "nowrap",
                                                        textOverflow: "ellipsis",
                                                        padding: "1px 1px"
                                                    }),
                                                    multiValueLabel: (styles, { data }) => ({
                                                        ...styles,
                                                        color: data.color,
                                                    })
                                                    ,
                                                }}
                                                options={allOptionsStatus}
                                                components={{
                                                    Option: InputOption
                                                }}
                                            />
                                        </div>
                                        <div className="input-group mb-2 coustom">
                                            <span className="input-group-text" id="basic-addon1">Filter By</span>
                                            <select
                                                className="form-select arrow_bold"
                                                aria-label="Default select example"
                                                data-width="auto"
                                                onChange={handleFilterRewardChange}
                                                value={reward}
                                                style={{ cursor: 'pointer' }} 

                                            >
                                                <option value="no">All status</option>
                                                {REWARD_TYPE && Object.keys(REWARD_TYPE)?.map((rewardKey, rewardValue) => (
                                                    <option value={rewardValue}>
                                                        {rewardKey}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {/* <div className="sort_by_section d-flex align-items-center gap-2">
                                            <p className="mb-0">Filter By</p>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                data-width="auto"
                                                onChange={handleFilterRewardChange}
                                            // value={sortBy}
                                            >
                                                <option value="no">All status</option>
                                                {REWARD_TYPE && Object.keys(REWARD_TYPE)?.map((rewardKey, rewardValue) => (
                                                    <option value={rewardValue}>
                                                        {rewardKey}
                                                    </option>
                                                ))}
                                            </select>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        }

                        <div
                            className={`row row-cols-1 ${activeTab != "quick_view"
                                ? "row-cols-xxxl-4 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 gap-y-4"
                                : ""
                                }`}
                        >

                            {
                                loading ? (
                                    <div className="d-flex justify-content-center w-100 fs-7">
                                        <SyncLoader color="#3498DB" />
                                    </div>
                                ) : (

                                    stacks?.length > 0
                                        ?
                                        (
                                            stacks?.map((data) => {
                                                const startTime = Number(data?.startDateTime);
                                                const endTime = Number(data?.endDateTime);

                                                let classNameD = "sale_upcoming";
                                                let statusText = "Upcoming";
                                                let startEndIn = "TBA";

                                                if (data?.isCancel == 1) {
                                                    statusText = "Sale cancelled"
                                                }
                                                else if (currentUnixTime >= startTime && endTime > currentUnixTime) {
                                                    classNameD = "sale_live";
                                                    statusText = "Sale Live";
                                                    // startEndIn = "Sale Ends In";
                                                } else if (currentUnixTime < startTime) {
                                                    classNameD = "sale_upcoming";
                                                    statusText = "Upcoming";
                                                    // startEndIn = "Sale Start In";
                                                } else if (currentUnixTime > endTime) {
                                                    statusText = "Sale Ended";
                                                    classNameD = "sale_end";
                                                    // startEndIn = "Sale Ended"
                                                }
                                                return (
                                                    <div className="col" >
                                                        <div className={`card custom_card_design`}>
                                                            <Link to={`${BASE_URL}stake?stakeContract=${data?.stakeContract}&chainId=${data?.chain}`} >
                                                                <div
                                                                    className={`custom_card h-100`}>
                                                                    {/* <Link to={`${BASE_URL}presale?presale_id=${data?.preSale}&chainId=${data?.chain}`}> */}
                                                                    <div
                                                                        className="card-top-img stablz-card-top-img"
                                                                        // style={{
                                                                        //     backgroundImage: `url(${data?.bannerUrl != "" ? data?.bannerUrl : "https://testnet.gempad.app/static/bg4.png"})`,
                                                                        // }}
                                                                    >
                                                                            {
                                                                                // data?.bannerUrl &&
                                                                                <Banner url={data?.bannerUrl}/>
                                                                            }
                                                                        <div className="p-3">
                                                                            <div className="audi_kyc_btn">
                                                                            </div>
                                                                        </div>

                                                                        <div className="p-3">
                                                                            <div className={classNameD}>
                                                                                <p className="mb-0">{statusText}</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    {/* </Link> */}
                                                                    <div className={`p-3`}>
                                                                        <div className="compare_coin d-flex align-items-center">

                                                                            <img
                                                                                // className="img-fluid"
                                                                                src={
                                                                                    data?.stakingLogoUrl
                                                                                }
                                                                                onError={(e) => {
                                                                                    e.target.src = networkImages['bsc-testnet'];
                                                                                }}
                                                                            />

                                                    {data?.rewardToken != NO_REWARD && 
                                                    
                                                                <>
                                                                            <div className="px-3">
                                                                                <i className="fa fa-angle-right" style={{ fontSize: 36 }} />
                                                                            </div>

                                                                            <img
                                                                                src={
                                                                                    data?.rewardLogoUrl
                                                                                }
                                                                                onError={(e) => {
                                                                                    e.target.src = networkImages['bsc-testnet']; 
                                                                                }}
                                                                            />
                                                                            </>
                                                    }

                                                                        </div>
                                                                        <div className="card_second_section d-flex justify-content-between align-items-end">

                                                                            <div className="card_second_section_text">
                                                                                <h5> Stake {data?.name}</h5>
                                                                                <p>
                                                                                    Stake {data?.symbol} {data?.rewardToken != NO_REWARD && `to Earn ${JSON.parse(data.rewardTokenInfo)?.symbol}`}
                                                                                </p>
                                                                            </div>

                                                                            <div>

                                                                            </div>

                                                                            <div className="position">
                                                                                <img
                                                                                    width={40}
                                                                                    src={networkImagesByChain[data?.chain]}
                                                                                    // className="img-fluid"
                                                                                    alt="logo"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="row row-cols-1 row-cols-sm-1 gx-1 gy-1">

                                                                            <div className="col">
                                                                                <ul className="presale_address_ui ul_li_bordernone">
                                                                                    <li className="d-flex justify-content-between">
                                                                                        <span className="font-weight-bold fs-5"><strong>Reward Type:</strong></span>
                                                                                        <span className="text-blue font-weight-bold"><strong>
                                                                                            {Object.keys(REWARD_TYPE).find(key => REWARD_TYPE[key] == data?.rewardType)}
                                                                                        </strong></span>
                                                                                    </li>
                                                                                    {data?.rewardType != REWARD_TYPE["Only if balance"] && data?.rewardType !=REWARD_TYPE['No Reward']
                                                                                            &&
                                                                                    <li className="d-flex justify-content-between">
                                                                                        <span className="font-weight-bold fs-5"><strong>Reward Ratio (year):</strong></span>
                                                                                        <span className="text-blue font-weight-bold"><strong>
                                                                                            {
                                                                                                ethers.utils
                                                                                                    .formatUnits(String(data?.rewardRatio || 0), JSON.parse(data.rewardTokenInfo)?.decimals || 0)
                                                                                                    .toString()?.replace(/\.?0*$/, '')
                                                                                            }
                                                                                        </strong></span>
                                                                                    </li>
                                                                            }
                                                                                    <li className="d-flex justify-content-between">
                                                                                        <span className="font-weight-bold fs-5"><strong>Hard Cap:</strong></span>
                                                                                        <span className="text-blue font-weight-bold"><strong>
                                                                                            {/* {data?.hardCap} */}
                                                                                            {
                                                                                                Intl.NumberFormat("en", {notation: "compact"}).format(ethers.utils.formatUnits(String(data?.hardCap || 0), JSON.parse(data.stackTokenInfo)?.decimals || 0))
                                                                                                    // .toString()?.replace(/\.?0*$/, '')
                                                                                            }
                                                                                        </strong></span>
                                                                                    </li>
                                                                                    <li className="d-flex justify-content-between">
                                                                                        <span className="font-weight-bold fs-5"><strong>Total Staked</strong></span>
                                                                                        <span className="text-blue font-weight-bold"><strong>{Number(data?.total_staked).toFixed(5)?.replace(/\.?0*$/, '')}</strong></span>
                                                                                    </li>
                                                                                    <li className="d-flex justify-content-between">
                                                                                        <span className="font-weight-bold fs-5"><strong>Start Time</strong></span>
                                                                                        <span className="text-blue font-weight-bold"><strong>{moment(new Date(data?.startDateTime * 1000)).format("YYYY-MM-DD HH:mm")}</strong></span>
                                                                                    </li>
                                                                                    <li className="d-flex justify-content-between">
                                                                                        <span className="font-weight-bold fs-5"><strong>End Time</strong></span>
                                                                                        <span className="text-blue font-weight-bold"><strong>{moment(new Date(data?.endDateTime * 1000)).format("YYYY-MM-DD HH:mm")}</strong></span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        {/* <hr /> */}
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="sale_status d-flex justify-content-between align-items-start">
                                                                                    <div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* {showAdditionalData[key] && */}

                                                                    {/* } */}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )
                                            })

                                        )
                                        :
                                        ((!account?.isConnected && activeTab == "my_created_stack") || (!account?.isConnected && activeTab == "my_staked")) ?
                                            (
                                                (
                                                    <div className="d-flex justify-content-center w-100 fs-7">
                                                        Please Login with your wallet!
                                                    </div>
                                                )
                                            )
                                            :
                                            (
                                                (
                                                    <div className="d-flex justify-content-center w-100 fs-7">
                                                        No Data
                                                    </div>
                                                )
                                            )

                                )
                            }

                        </div>

                        {
                            !loading && stacks.length > 0 && totalData > itemsPerPage && (
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={totalData}
                                    pageSize={itemsPerPage}
                                    onPageChange={page =>
                                        handlePageChange(page)
                                    }
                                />
                            )
                        }


                        {
                            !loading && (stacks.length == 0 && currentPage != 1) &&
                            <>
                                <PaginationOver
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={totalData}
                                    pageSize={itemsPerPage}
                                    onPageChange={page =>
                                        handlePageChange(page)
                                    }
                                />
                            </>
                        }

                    </div>




                </div>
                </div>
            </div>
        </div >
    )
}