import * as Yup from "yup"

const validateAddress = (address) => {
    const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    return addressRegex.test(address);
  };
  
export const DispSameTokToAddValidator =Yup.object().shape({
    addresses: Yup.string().required("Address is required!").test(
      "Validate Address",
      "Invalid Address list!",
      (value) => {
        value = value.replace(/\s+/g, '').trim()
        const re =
          /^\w+(,\w+)*$/
        return re.test(value)
      },
    ).test("validate-valid-length-address", "Maximum address should be 500", (value) => {
      const addressArr = value.split(",")
      return addressArr.length <= 500;
    }).test("validate-valid-address", "Invalid address", (value) => {
      const addressArr = value.replace(/\s+/g, '').trim().split(",")
      return addressArr.every((address) => validateAddress(address));
    }),
    amount:Yup.number().positive("Amount should be positive").required("Amount is required!")
  })

  export const disperseDifferentTokenToAddressesValidator = Yup.object().shape({
    // amount:Yup.number().positive("Amount should be positive").required("Amount is required!"),
    addresses: Yup.string().required("Address is required!").test(
      "Validate Address",
      "Invalid Address list!",
      (value) => {
        const re =
          /^\w+(,\w+)*$/
        return re.test(value)
      },
    ).test("validate-valid-length-address", "Maximum address should be 500", (value) => {
      const addressArr = value.split(",")
      return addressArr.length <= 500;
    }).test("validate-valid-address", "Invalid address", (value) => {
      const addressArr = value.split(",")
      return addressArr.every((address) => validateAddress(address));
    }),
    diff_amount: Yup.string().required('Amount is required').test(
      "Validate Address",
      "Invalid amount list!",
      (value) => {
        const re =
        /^(0|[1-9][0-9]*)(,[0-9]+)?(\.[0-9]+)?(,(0|[1-9][0-9]*)(,[0-9]+)?(\.[0-9]+)?)*$/
        return re.test(value)
      },
    ).test({
      name: 'diff_amount',
      test: function (value) {
        if (!value) return false; // diff_amount is required
        const addressesArray = this.parent?.addresses?.split(',');
        const diffAmountArray = value.split(',');
        const cleanedDiffAmountArray = diffAmountArray.filter(amount => amount.trim() !== '');
        return addressesArray?.length === cleanedDiffAmountArray?.length;
      },
      message: 'The number of addresses and amount values must be the same.',
    }).test({
      name:'diff_amount',
      test:function(value){
        const diffAmountArray = value.split(',');
        const hasNegativeValue = diffAmountArray.some(amount => parseFloat(amount) < 0);
        return !hasNegativeValue;
      },
      message: 'Amount should not contain negative values.',
    }).test({
      name: 'diff_amount',
      test: function (value) {
        const diffAmountArray = value.split(',');
        const hasNegativeValue = diffAmountArray.some(amount => parseFloat(amount) < 0);
        return !hasNegativeValue;
      },
      message: 'Amount should not contain negative values.',
    }).test("validate-amount",'Invalid amount list(amount exceed token decimal)',(value)=>{
      const amount = value.split(",")
      return amount.every((amt)=> amt.toString().length < 20)
    }),
  })