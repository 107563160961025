/// Mainnet Chains
// import { arbitrum, mainnet, polygon, bsc } from "wagmi/chains";
// const chains = [arbitrum, mainnet, polygon, bsc];

/// Testnet Chains
import { bscTestnet,arbitrum,polygonMumbai,dogechain } from "wagmi/chains";
import { INFURAID } from "../../_constant";
export const chains = [bscTestnet, polygonMumbai];

export const PROJECT_ID = INFURAID;
