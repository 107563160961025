import { BASE_URL_BACKEND } from "../../_constant"
import { fetch } from "../axios.service"

export const PostPresale = data => {
  return fetch("post", `${BASE_URL_BACKEND}/post_presale`, data);
};

export const PostSpecialSale = data => {
  return fetch("post", `${BASE_URL_BACKEND}/post-special-sale`, data);
};

export const getPresaleService = (data) => {
  return fetch("get", `${BASE_URL_BACKEND}/get_presale?active_tab=${data.activeTab}&wallet=${data.wallet}&chain_id=${data.chain_id}&search=${data.search}&sortBy=${data.sortBy}&filterBy=${data.filterBy}&pageSize=${data.pageSize}&currentPage=${data.currentPage}`, {}, {});
}

export const getSpecialSaleService = (data) => {
  return fetch("get", `${BASE_URL_BACKEND}/special-sales?active_tab=${data.activeTab}&wallet=${data.wallet}&chain_id=${data.chain_id}&search=${data.search}&sortBy=${data.sortBy}&filterBy=${data.filterBy}&pageSize=${data.pageSize}&currentPage=${data.currentPage}`, {}, {});
}

export const favouriteService = (data, params) => {
  return fetch("post", `${BASE_URL_BACKEND}/favourite`, data);
}

export const remnderService = (data, params) => {
  return fetch("post", `${BASE_URL_BACKEND}/webpush`, data);
}
export const createTokenService = (data) => {
  return fetch("post", `${BASE_URL_BACKEND}/create_token`, data);
}


export const getcurrentBnbPriceService = (data) => {
  return fetch("get", `${BASE_URL_BACKEND}/coin_price?symbol=${data?.symbol}`, {}, {});
}


export const getPresaleDetailService = (data) => {
  return fetch("get", `${BASE_URL_BACKEND}/presale_details/${data.id}`, {}, {});
}

export const getSpecialDetailService = (data) => {
  return fetch("get", `${BASE_URL_BACKEND}/special_details/${data.id}`, {}, {});
}

export const postInvestService = (data) => {
  return fetch("post", `${BASE_URL_BACKEND}/invest`, data);
}

export const getInvestDetails = (data) => {
  return fetch("get", `${BASE_URL_BACKEND}/invest_details?address=${data.address}&preSale=${data.preSale}&chain=${data.chain}`, {}, {});
}

export const deleteContributionService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/delete-contribution`, data, {});
}

export const updatePresaleDetails = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/update-presale-info`, data, {});
}

export const cancelPresaleService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/cancel-preasale`, data, {});
}

export const extendLiquidityLockService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/extend-liquidity-lock`, data, {});
}


export const presaleStartService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/presale-start`, data, {});
}


export const addWhiteListedService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/add-whitelist`, data, {});

}

export const addWhiteListedServiceSpecial = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/add-whitelist-special`, data, {});

}

export const removeWhitelistService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/remove-whitelist`, data, {});
}

export const removeWhitelistSpecialService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/remove-whitelist-special`, data, {});
}


export const finalizePoolService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/finalize-pool`, data, {});
}

export const claimSpecialSaleService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/claim-special-sale`, data, {});
}

export const allowClaimService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/allow-claim`, data, {});
}

export const ClaimedService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/claimed`, data, {});
}

export const getPresaleReminder = (data, saleType) => {
  return fetch("get", `${BASE_URL_BACKEND}/webpush/${data}?saleType=${saleType}`);
}
export const deletePresaleReminder = (data) => {
  return fetch("delete", `${BASE_URL_BACKEND}/webpushes/${data}`);
}
export const deleteSinglePresaleReminder = (data) => {
  return fetch("delete", `${BASE_URL_BACKEND}/webpush/${data}`);
}

export const updateLiqAddsOnTimestamp = (data)=>{
  return fetch("put", `${BASE_URL_BACKEND}/update-liqadds-on-timestamp`,data,{});

}

export const updatePresaleRDexListingR = (data)=>{
  return fetch("put", `${BASE_URL_BACKEND}/update-prerate-dexlirate`,data,{});
}

export const getHeighlightedPresalesService = (data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/heighlighted-presales`,{},{});
}

export const lockService = (data)=>{
  return fetch("post", `${BASE_URL_BACKEND}/lock`, data);

}

export const getLocksService = (data) => {
  return fetch("get", `${BASE_URL_BACKEND}/locks?chain=${data.chain}&address=${data.address || ""}&type=${data.type || ""}&search=${data.search}&pageSize=${data.pageSize || 12}&currentPage=${data.currentPage || 1}`);
}

export const getMyLocksService = (data) => {
  return fetch("get", `${BASE_URL_BACKEND}/my-locks?chain=${data.chain}&address=${data.address || ""}&type=${data.type || ""}&search=${data.search}&pageSize=${data.pageSize || 12}&currentPage=${data.currentPage || 1}`);
}


export const getALockService = (data) => {
  return fetch("get", `${BASE_URL_BACKEND}/lock?token=${data.token}`);
}

export const updateRatesAfterFinalizeService = (data)=>{
  return fetch("put", `${BASE_URL_BACKEND}/update-rates-affinalize`,data,{});
}

export const unlockService =(data)=>{
  return fetch("post", `${BASE_URL_BACKEND}/unlocked`,data)
  
}


export const postStakingService =(data)=>{
  return fetch("post", `${BASE_URL_BACKEND}/post-staking`,data)
  
}



export const homeInfoService = () => {
  return fetch("get", `${BASE_URL_BACKEND}/home-info`);
}


export const getStakingService = (data) => {
  return fetch("get", `${BASE_URL_BACKEND}/get-staking?active_tab=${data.activeTab}&wallet=${data.wallet}&reward=${data.reward}&chain_id=${data.chain_id}&status=${data.status}&filterBy=${data.filterBy}&pageSize=${data.pageSize}&currentPage=${data.currentPage}`, {}, {});
}

export const getStakeDetails = (data) => {
  return fetch("get", `${BASE_URL_BACKEND}/stack-details/${data?.stakeContract}`, {}, {});
}

export const extendLockerTime =(data)=>{
  return fetch("post", `${BASE_URL_BACKEND}/extend-locker`,data)
  
}

export const updateStakingDetails = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/update-staking-details`, data, {});
}

export const cancelStakeService = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/cancel-stake`, data, {});
}


export const depositeWithdrawalStak = (data) => {
  return fetch("put", `${BASE_URL_BACKEND}/deposite-reward`, data, {});
}


export const stakeService =(data)=>{
  return fetch("post", `${BASE_URL_BACKEND}/stake`,data)
}

export const myStack = (data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/my-stake?address=${data.address}&stakeContract=${data.stakeContract}&chain=${data.chain}`, {}, {});
}

export const updateLastDReward =(data)=>{
  return fetch("put", `${BASE_URL_BACKEND}/update-last-dis-reward-af-de`,data,{})
}

export const updateLastDisTime =(data)=>{
  return fetch("put", `${BASE_URL_BACKEND}/update-last-dis-time`,data,{})
}

export const getStakedDetailsService= (data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/stake-details?stakeContract=${data.stakeContract}`, {}, {});
}


export const tokenIsStakeService = (data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/token-is-stake-address?address=${data.address}`, {}, {});
}

export const updateAuditService =(data)=>{
  return fetch("put", `${BASE_URL_BACKEND}/update-audit`,data,{})
}

export const updateSaleStaus =(data)=>{
  return fetch("put", `${BASE_URL_BACKEND}/change-whitelist-status`,data,{})
}

export const sendOTPSubsc =(data)=>{
  return fetch("post", `${BASE_URL_BACKEND}/subscription/otp`,data,{})
}

export const subscipRegisterService =(data)=>{
  return fetch("post", `${BASE_URL_BACKEND}/subscription/register`,data,{})
}

export const getPackageListService = (data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/get-package-list`, {}, {});
}

export const checkRegisterService = (data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/subscription/check_register?address=${data?.address}`, {}, {});
}

export const subscribe =(data)=>{
  return fetch("post", `${BASE_URL_BACKEND}/subscribe`,data,{})
}

export const checkSubscription =(data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/check-subscription?address=${data.address}`,{},{})
}

export const packageDetails =(data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/package-detail?package_id=${data.package_id}`,{},{})
}

export const checkAvailability =(data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/check-availability?package_id=${data?.package_id}&address=${data?.address}`,{},{})
}

export const checkPayent =(data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/check-payment?address=${data?.address}`,{},{})
}

export const subsDetailsService =(data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/subs-detatils?address=${data?.address}`,{},{})
}

export const storeHash =(data)=>{
  return fetch("post", `${BASE_URL_BACKEND}/subscription/store-hash`,data,{})
}

export const transactionError =(data)=>{
  return fetch("post", `${BASE_URL_BACKEND}/subscription/transaction-logsh`,data,{})
}

export const getCoinPriceService =(data)=>{
  return fetch("post", `${BASE_URL_BACKEND}/calculate_helper`,data,{})
}

export const checkReferrerService =(data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/check-referrer?username=${data?.username}`,{},{})
}

export const getSubsDetailsService =(data)=>{
  return fetch("get", `${BASE_URL_BACKEND}/subsc-details?address=${data?.address}&id=${data.id}`,{},{})
}