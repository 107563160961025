import moment from "moment";
import React from "react";
import { ethers } from "ethers";
import { getAccount,getNetwork } from "@wagmi/core"
import { useNetwork } from "wagmi";
import { PRESALE_MANAGER_ADDRESSESS } from "../../_constant";
import { TRANSACTIONMSGS } from "../../utils/messages";
import { toast } from "react-toastify";
import { useContractWrite, useWaitForTransaction } from "wagmi";
import PreSaleManager from "../../_constant/PreSaleManager.json";
import { claimSpecialSaleAction } from "../../redux/apiActions/api.action";
import { SyncLoader } from "react-spinners";

const CollectFund = ({presaleDetails, isDataFundRaised, fundRaisingTokenDecimal,getPresaleFuncWithoutLoading})=>{
    const account = getAccount()
    const { chain, chains } = useNetwork();

    const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network]


  const currentUnixTime = moment().unix();

   const isDisable =  presaleDetails?.isCanceled === 0 &&
    presaleDetails?.isCollected === 0 &&
    presaleDetails?.setSaleTime === 1 &&
    presaleDetails?.estimatedClaimTime < currentUnixTime &&
    Number(ethers.utils.formatUnits(isDataFundRaised || 0, Number(fundRaisingTokenDecimal))) >=
        ethers.utils.formatUnits(String(presaleDetails?.softCap || 0), Number(fundRaisingTokenDecimal))
    &&
    (presaleDetails?.preSaleOwner == account?.address );


    // collectFundsFromSpecialSale
    const onErrorClaimLPTokens = (error)=>{
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }else if(error.toString().includes("WaitTillClaimTime")){
            toast.error("Wait till claim time",{toastId:'wtCtTid'})
        }
        else{
            toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG)
        }
    }


    const onSuccessClaimLPTokens = ()=>{

    }

    const {
        data: collectFundData,
        write: writeCollectFund,
        isLoading: isLoadingCF,
        isSuccess: isSuccessCF,
      } = useContractWrite({
        address: PRESALE_MANAGER_ADDRESS,
        abi: PreSaleManager.abi,
        functionName: "collectFundsFromSpecialSale",
        args:[presaleDetails?.preSale],
        onError: onErrorClaimLPTokens,
        onSuccess: onSuccessClaimLPTokens
      });

      const onApproveReceipt = ()=>{

      }
      const onSuccessWaitForTransaction = async(data) => {
        let bodyData = {preSale:presaleDetails?.preSale}
        const response = await claimSpecialSaleAction(bodyData)
        if(response){
          toast.success("Fund collected!")
          getPresaleFuncWithoutLoading()
        }
      }

      const { isLoading: isLoadingWFTCF } = useWaitForTransaction({
        hash: collectFundData?.hash,
        onSettled: onApproveReceipt,
        onSuccess: onSuccessWaitForTransaction
      });



return(
    <>
        <button
                className="btn btn-info btn-block modal-open btn_managepool"
                disabled={!isDisable || isLoadingCF || isLoadingWFTCF }
                onClick={writeCollectFund}
              >{isLoadingCF || isLoadingWFTCF? <SyncLoader color={'#3498DB'} size={10} />:'Collect Fund'}</button>
    </>
)
}

export default CollectFund;