import React, { useState } from "react"
import { PRESALE_MANAGER_ADDRESSESS } from "../../_constant";
import { useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import { getAccount,getNetwork } from "@wagmi/core"
import { toast } from "react-toastify";
import { TRANSACTIONMSGS } from "../../utils/messages";
import PreSaleManager from "../../_constant/PreSaleManager.json";
import { updateSaleStatusAction } from "../../redux/apiActions/api.action";
import { SyncLoader } from "react-spinners";

const WhiteList = ({isWhiteList, preSale, getPresaleFuncWithoutLoading})=>{

    const account = getAccount()
    const { chain, chains } = useNetwork();

    const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network]



    const [saleStatus,setSalestatus] = useState(isWhiteList=="true"?'Whitelist':'Public')

    const [saleStatusTemp,setSalestatusTemp] = useState(isWhiteList=="true"?'Whitelist':'Public')


    // collectFundsFromSpecialSale
    const onErrorClaimLPTokens = (error)=>{
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }else if(error.toString().includes("WaitTillClaimTime")){
            toast.error("Wait till claim time",{toastId:'wtCtTid'})
        }
        else{
            toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG)
        }
    }


    const onSuccessClaimLPTokens = ()=>{

    }

    const {
        data: dataCST,
        write: writeChangeSaleType,
        isLoading: isLoadingCST,
        isSuccess: isSuccessCST,
      } = useContractWrite({
        address: PRESALE_MANAGER_ADDRESS,
        abi: PreSaleManager.abi,
        functionName: "changeSaleTypeForSpecialSale",
        // args:[preSale,],
        onError: onErrorClaimLPTokens,
        onSuccess: onSuccessClaimLPTokens
      });

      const onApproveReceipt = ()=>{

      }
      const onSuccessWaitForTransaction = async(data) => {
        setSalestatus(saleStatusTemp)
        
        let bodyData = {preSale:preSale, owner:account?.address, status:saleStatusTemp=='Whitelist'?"true":'false'}
        await updateSaleStatusAction(bodyData)

        toast.success("Whitelist updated!")

        getPresaleFuncWithoutLoading()
      }

      const { isLoading: isLoadingWFTCST } = useWaitForTransaction({
        hash: dataCST?.hash,
        onSettled: onApproveReceipt,
        onSuccess: onSuccessWaitForTransaction
      });




  const handleChangeSaleStatus = ({target})=>{
    setSalestatusTemp(target?.value)
    // setSalestatus(target?.value)
    writeChangeSaleType({args:[preSale,target?.value=='Whitelist'?true:false ]})
  }



    return(
        <>
            {
                isLoadingCST || isLoadingWFTCST ?

                <>
                <div className="mt-3 mb-3">
                    <SyncLoader color="#3498DB" />
                </div>
                </>

                :
                <select
                className="form-select whitelist-form-select mt-3"
                name="router"
                style={{ border: " 1px solid #90a3b74d" }}
                onChange={handleChangeSaleStatus}
                value={saleStatus}
              >
                <option>Public</option>
                <option>Whitelist</option>
              </select>
            }
   
        </>
    )
}

export default WhiteList;