import { useState } from "react"
import { useContractRead, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi"
import PreSaleManager from "../../_constant/PreSaleManager.json";
import Presale from "../../_constant/PreSale.json";
import FairLaunch from "../../_constant/FairLaunch.json";
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { toast } from "react-toastify";
import * as Yup from "yup"
import { useFormik } from "formik";
import { getAccount, getNetwork } from "@wagmi/core"
import { extendLiquidityLockAction } from "../../redux/apiActions/api.action";
import { FinalizePool } from "./FinalizePoolSP";
import { PresaleStart } from "./PresaleStart";
import { AddWhiteList } from "./AddWhitelist";
import moment from "moment";
import { ClaimLP } from "./ClaimLP";
import { ethers } from "ethers";
import { PRESALE_MANAGER_ADDRESSESS } from "../../_constant";
import { ClaimTeamVesting } from "./ClaimTeamVesting";
import Audit from "./Audit";
import CollectFund from "./CollectFund";
import WhiteList from "./Whitelist";
import { Allowclaim } from "./AllowClaim";
import { SyncLoader } from "react-spinners";

export function ManagePool({ preSale, isCanceled, isLoadingUpdatePresale, isLoadingCancelPresale, isLoadingCancelStealthPresale, liquidityLockupDays, launch_stealth_sale, presaleDetails, getPresaleFuncWithoutLoading, isDataFundRaised,fundRaisingTokenDecimal }) {

  const { chain, chains } = useNetwork();

  const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network]

  const account = getAccount()


  const onSuccessReadExtend = async (data) => {

    // if (liquidityLockupDays != Number(data) / 86400) {
    //   let bodyData = { address: account.address, preSale: preSale, days: Number(data-dataLiqidityAddsOn) / 86400 }
    //   await extendLiquidityLockAction(bodyData)
    // }

  }
  const { data: dataExtenddd, isError: ReceiveTokensisError, isLoading: ReceiveTokensisLoading, refetch: refetchReadExtend } = useContractRead({
    address: preSale,
    abi:  presaleDetails?.saleType =='Presale'?Presale.abi:FairLaunch.abi,
    functionName: "liquidityLockedUntilInSec",
    onSuccess: onSuccessReadExtend
  })


  const currentUnixTime = moment().unix();


  // liquidityLockedUntilInSec

  const onSuccessLiqLockInSec = () => {

  }

  const onErrorLiqLockInSec = () => {

  }

  const { data: dataLiqLockInSec, isError: isErrorLiqLockInSec, isLoading: isLoadingLiqLockInSec, refetch: refetchLiqLockInSec } = useContractRead({
    address: presaleDetails?.preSale,
    abi:  presaleDetails?.saleType =='Presale'?Presale.abi:FairLaunch.abi,
    functionName: "liquidityLockedUntilInSec",
    onSuccess: onSuccessLiqLockInSec,
    onError: onErrorLiqLockInSec
  })

  const onSuccessLiqidityAddsOn = (data) => {
    // console.log("onSuccessLiqidityAddsOn",data)
  }
  const onErrorLiqidityAddsOn = (error) => {
    // console.log("onErrorLiqidityAddsOn",error)
  }

  const { data: dataLiqidityAddsOn, isError: isErrorAllowance, isLoading: isLoadingAllowance, refetch: refetchLiqidityAddsOn } = useContractRead({
    address: presaleDetails?.preSale,
    abi:  presaleDetails?.saleType =='Presale'?Presale.abi:FairLaunch.abi,
    functionName: "liquidityAddedOn",
    onSuccess: onSuccessLiqidityAddsOn,
    onError: onErrorLiqidityAddsOn
  })

  // console.log('dataLiqLockInSec',dataLiqLockInSec)

  let showManagePool = presaleDetails?.isAllowclaim == 0 && presaleDetails?.isCollected==0;

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 gap-2">
          {/* <button className="btn btn-primary btn-block mt-3">Set AntiSniper</button> */}

          {
            (presaleDetails?.isAllowclaim == 0 && presaleDetails?.isCollected==1) &&
            <Allowclaim preSale={presaleDetails?.preSale} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading}/>
          }

          {presaleDetails?.preSaleOwner == account?.address && showManagePool &&
            <Audit preSale={presaleDetails?.preSale}/>
          }



          {
              presaleDetails?.preSaleOwner == account?.address && showManagePool &&
              <button
                className="btn btn-warning btn-block mt-3 modal-open btn_managepool"
                href="#modal"
                data-bs-toggle="modal"
                data-bs-target="#updatePoolModal"
                disabled={isLoadingUpdatePresale}
              >{isLoadingUpdatePresale ? <SyncLoader color={'#3498DB'} size={10} /> : 'Update'}</button>
          }


          {
            (isCanceled == 0  && showManagePool) 
            &&
            <button
              className="btn btn-danger btn-block mt-3 modal-open btn_managepool"
              href="#modal"
              data-bs-toggle="modal"
              data-bs-target="#cancelPresaleModal"
              disabled={isLoadingCancelPresale || isLoadingCancelStealthPresale}
            >{isLoadingCancelPresale || isLoadingCancelStealthPresale ? <SyncLoader color={'#3498DB'} size={10} /> : 'Cancel'}
            </button>
          }

          {
            Number(presaleDetails?.endTime) > currentUnixTime ?
           <WhiteList isWhiteList={presaleDetails?.isWhiteList} preSale ={presaleDetails?.preSale} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading}/>
           :
           <div className="mt-3"></div>
          }

          {
               (presaleDetails?.isWhiteList == "true" && presaleDetails?.isCanceled != 1 && currentUnixTime < presaleDetails?.endTime) && presaleDetails?.preSaleOwner == account?.address &&
            <AddWhiteList presaleDetails={presaleDetails} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading} />
          }

          {
            showManagePool && 
            <CollectFund presaleDetails={presaleDetails} isDataFundRaised={isDataFundRaised} fundRaisingTokenDecimal={fundRaisingTokenDecimal} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading}/>
          }

        </div>
      </div>
    </div>
  )
}