import * as Yup from "yup"

const validateAddress = (address) => {
    const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    return addressRegex.test(address);
  };


  export const createStakingSchema = Yup.object().shape({
    rewardToken:Yup.string(),
    name:Yup.string().required("Name is required"),
    symbol:Yup.string().required("Symbol is required"),
    rewardType:Yup.string().required("Reward type is required"),
    minAmountToStake:Yup.number().required("Min amount to stake is required"),
    hardCap:Yup.number().required("Hard cap is required").lessThan(1e+59, "Hard cap should be less then or equal to 1e59").moreThan(Yup.ref('minAmountToStake'), "Hard cap should be greater than min amount to stake"),
    rewardRatio: Yup.number().test("rewardRatio","Reward ratio is required!",function(value){
      const isRewardType = this.resolve(Yup.ref('rewardType'));
      const rewardToken= this.resolve(Yup.ref('rewardToken'));
      if((isRewardType == 0 || isRewardType == 1) && !value && rewardToken){
        return this.createError({
          message: "Reward ratio is required!",
          path: 'rewardRatio',
        });
      }
      return true;
    })
  });


  export const projectInfoSchemaStacking = Yup.object().shape({
    stakingLogoUrl: Yup.string()
      .url('Not a url')
      .required('Staking logo is required'),
      
      rewardLogoUrl: Yup.string()
      .url('Not a url')
      .required('Reward logo is required'),
  
    website_url: Yup.string()
      .url('Not a url').optional(),
  
    twitter: Yup.string()
      .url('Not a url').optional(),
  
    github: Yup.string()
      .url('Not a url').optional(),
  
    telegram: Yup.string()
      .url('Not a url').optional(),
  
    discord: Yup.string()
      .url('Not a url').optional(),
  
    youtube: Yup.string()
      .url('Not a url').optional(),
  
    whitelist_link: Yup.string()
      .url('Not a url').optional(),
  
    description: Yup.string()
      .required("Description is required"),
  
    bannerUrl: Yup.string()
      .url('Not a url').optional(),
  
  });