import React from 'react'
import { BASE_URL } from '../../_constant'

export default function StackingHeader({ onButtonClick,actived }) {

  const tabChangeHear = (tab) => {
    onButtonClick(tab)
  }


  return (
    <>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${actived=='all_products'&&'active'}`}
            data-bs-toggle="tab"
            href="#allproducts"
            onClick={() => { tabChangeHear("all_products") }}
          >
            <img
              src={BASE_URL+"images/icon/allproducts.png"}
              className="img-fluid me-2 active"
              alt="icon"
            />{" "}
            All Stake Pools
          </button>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${actived=='my_staked'&&'active'}`}
            data-bs-toggle="tab"
            href="#allproducts"
            onClick={() => { tabChangeHear("my_staked") }}
          >
            <img
              src={BASE_URL+"images/icon/myfavorites.png"}
              className="img-fluid me-2"
              alt="icon"
            />{" "}
            My Staked Pools
          </a>
        </li>
        {/* Upcoming */}
        <li className="nav-item">
          <a
            className={`nav-link ${actived=='my_created_stack'&&'active'}`}
            data-bs-toggle="tab"
            href="#allproducts"
            onClick={() => { tabChangeHear('my_created_stack') }}
          >
            <img
              src={BASE_URL+"images/icon/mycreatedpresales.png"}
              className="img-fluid me-2"
              alt="icon"
            />{" "}
            My Created Stake Pools
          </a>
        </li>
      </ul>
    </>
  )
}
