import * as Yup from "yup"

const LIMIT_SOFTCAP=0.0005;

const validateAddress = (address) => {
  const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
  return addressRegex.test(address);
};

export const presaleSpecialSaleInfoSchema = Yup.object().shape({
  specialSaleRate: Yup.number().moreThan(0, 'Special sale rate should be greater than 0!').lessThan(1e+59, "Special sale rate should be less than or equal to 1e59").required("Special sale rate is required"),
  preSaleRate: Yup.number().moreThan(0, 'Presale rate should be greater than 0!').lessThan(1e+59, "Presale rate should be less than or equal to 1e59").required("Presale rate is required"),
  dex_listing_rate:Yup.number().moreThan(0, 'Dex rate should be greater than 0!').lessThan(1e+59, "Dex rate should be less than or equal to 1e59").required("Dex rate is required"),
  soft_cap: Yup.number().positive("Soft cap must be a positive number").required("Soft cap is required").min(LIMIT_SOFTCAP,`Soft cap should be minimum ${LIMIT_SOFTCAP}`).lessThan(1e+59, "Soft cap should be less than or equal to 1e59"),
  hard_cap: Yup.number().lessThan(1e+59, "Hard cap should be less than or equal to 1e59").moreThan(Yup.ref('soft_cap'), "Hard cap is more than soft cap").required("Hard cap is required").test('is-hard-cap', 'Soft cap is equal or more than hard cap / 2', function (value) {
    const softCap = this.resolve(Yup.ref('soft_cap'));
    return value <= softCap * 2;
  }),
  minimum_buy: Yup.number()
    .required("Min buy is required").lessThan(1e+59, "Mimimum buy should be less than or equal to 1e59")
    .lessThan(Yup.ref('hard_cap'), "Min buy is less than hard cap"),
  maximum_buy: Yup.number()
    .required("Max buy is required").lessThan(1e+59, "Mimimum buy should be less than or equal to 1e59")
    .min(
      Yup.ref('minimum_buy'),
      "Max buy should be greater than min buy"
    )
    .lessThan(Yup.ref('hard_cap'), "Max buy is less than hard cap")
    .test('max-buy-greater-than-min-buy', 'Max buy should be greater than min buy', function (value) {
      const minimumBuy = this.resolve(Yup.ref('minimum_buy'));
      if (minimumBuy && value && value <= minimumBuy) {
        return false;
      }
      return true;
    }),

  // Add Presale Vesting
  add_presale_vesting: Yup.boolean(),
  first_token_release_presale: Yup.number()
    .test('first_token_release_presale', 'First token release presale days is invalid', function (value) {
      const addPresaleVesting = this.resolve(Yup.ref('add_presale_vesting'));
      if (addPresaleVesting === true) {
        const validationSchema = Yup.number()
          .required("First released amount is required").integer("First token release presale should be integer!")
          .min(1, "First released amount is required")
        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'first_token_release_presale');
        }
      }
      return true;
    }),

  cliff: Yup.number()
    .test('cliff', 'Cliff days is invalid', function (value) {
      const addPresaleVesting = this.resolve(Yup.ref('add_presale_vesting'));
      if (addPresaleVesting === true) {
        const validationSchema = Yup.number().integer("Cliff should be only integer")
          .required('Cliff is required')
          .moreThan(0, 'Cliff should be more than 0 day')
          .max(365, 'Cliff should be less than 365 days');

        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'cliff');
        }
      }
      return true;
    }),

  vesting_period_each_cycle_presale: Yup.number()
    .test('vesting_period_each_cycle_presale', 'Vesting period each cycle presale days is invalid', function (value) {
      const addPresaleVesting = this.resolve(Yup.ref('add_presale_vesting'));
      if (addPresaleVesting === true) {
        const validationSchema = Yup.number()
          .required('Vesting period each cycle presale is required').integer("Vesting period each cycle presale should be integer!")
          .min(1, 'Each period should not be less than 1 day')

        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'vesting_period_each_cycle_presale');
        }
      }
      return true;
    }),

  toke_released_each_cycle_presale: Yup.number().test("toke_released_each_cycle_presale", "Token released each cycle presale is invalid", function (value) {
    const addPresaleVesting = this.resolve(Yup.ref('add_presale_vesting'));
    const firstTokenReleasePresale = this.resolve(Yup.ref('first_token_release_presale'));
    if (addPresaleVesting === true) {
      const validationSchema = Yup.number()
        .required("Token released each cycle presale is required").integer("Token released each cycle presale should be integer!")
        .min(1, 'Token released each cycle presale should be more than 0').test(
          "total_presale_tokens",
          "First + each released amount should not more than 100%",
          function (value) {
            const total = firstTokenReleasePresale + value;
            return total <= 100;
          }
        );

      try {
        validationSchema.validateSync(value);
      } catch (error) {
        throw new Yup.ValidationError(error.message, value, 'toke_released_each_cycle_presale');
      }
    }
    return true;
  })

});


export const projectInfoSchema = Yup.object().shape({
  name_of_your_presale:Yup.string().required("Name is required"),
  logo_url: Yup.string()
    .url('Not a url')
    .required('Logo is required'),

  website_url: Yup.string()
    .url('Not a url').optional(),

  twitter: Yup.string()
    .url('Not a url').optional(),

  github: Yup.string()
    .url('Not a url').optional(),

  telegram: Yup.string()
    .url('Not a url').optional(),

  discord: Yup.string()
    .url('Not a url').optional(),

  youtube: Yup.string()
    .url('Not a url').optional(),

  whitelist_link: Yup.string()
    .url('Not a url').optional(),

  description: Yup.string()
    .required("Description is required"),

  bannerUrl: Yup.string()
    .url('Not a url').optional(),

  redditUrl: Yup.string()
    .url('Not a url').optional(),

  tier: Yup.string().required('Please select a tier'),

});


export const updateProjectDetailsSchema = Yup.object().shape({
  sale_title:Yup.string().required("sale title is required!"),
  logoUrl: Yup.string()
    .url('Not a url')
    .required('Logo is required'),

  websiteUrl: Yup.string()
    .url('Not a url').optional(),

  twitterUrl: Yup.string()
    .url('Not a url').optional(),

  githubUrl: Yup.string()
    .url('Not a url').optional(),

  telegramUrl: Yup.string()
    .url('Not a url').optional(),

  discordUrl: Yup.string()
    .url('Not a url').optional(),

  youtubePresentationVideoUrl: Yup.string()
    .url('Not a url').optional(),

  whitelistContestUrl: Yup.string()
    .url('Not a url').optional(),

  projectDescription: Yup.string()
    .required("Description is required"),

  bannerUrl: Yup.string()
    .url('Not a url').optional(),


    redditUrl: Yup.string()
    .url('Not a url').optional(),


    preSaleRate: Yup.number().moreThan(0, 'Presale rate should be greater than 0!').lessThan(1e+59, "Presale rate should be less than or equal to 1e59").required("Presale rate is required"),
    dex_listing_rate:Yup.number().moreThan(0, 'Dex rate should be greater than 0!').lessThan(1e+59, "Dex rate should be less than or equal to 1e59").required("Dex rate is required"),
});