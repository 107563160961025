import React, { useEffect, useState } from "react";
import Payment from "./Payment";
import Success from "./Success";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BASE_URL, PACKAGES } from "../../_constant";
import { checkAvailabilityAction, getPackageListAction } from "../../redux/apiActions/api.action";
import { SyncLoader } from "react-spinners";
import { getAccount, getNetwork } from "@wagmi/core"

const Packages = () => {
  const [selectedPackage, setSelectedPackage] = useState('2');
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPackageListAPI = async () => {
    try {
      const { data } = await getPackageListAction();
      setPackages(data?.data);
    } catch (err) {
      console.error("Error fetching package list:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPackageListAPI();
  }, []); // Run only on mount

  const handlePackageSelect = (packageType) => {
    setSelectedPackage(packageType);
  };

  const navigate = useNavigate();
  const account = getAccount()

  const [checking,_checking] = useState(false)
  const handlePackageSubscribe = async() => {
        try{
          if (selectedPackage == null) {
            toast.error("Please select a package!");
          } else {
            _checking(true)
            const bodyData = {address:account?.address, package_id:selectedPackage};
            const isValid = await checkAvailabilityAction(bodyData)
            if(isValid){
              navigate(BASE_URL + `make-payment?package_id=${selectedPackage}`);
            }
          }
        }finally{
          _checking(false)
        }

  };

  return (
    <>
      {
        loading  ?
          <Loader />
          :
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-7 col-lg-10 col-md-12 mx-auto">
                <div className="row mt-5">
                  <div className="row">
                    <div className="col-md-12 mx-auto">
                      <div className="row">
                        <div className="col-md-12 mx-auto">
                          <div className="approve_token_section">
                            <h3 className="text-center">Subscription</h3>
                            <div className="package_token_card mt-2 text-center">

                              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 gap-y-3">
                                <div className="col-sm-12 col-md-12 select_package_card">
                                  <label className="fieldlabels">Select Package</label>


                                  <div className="grid mt-3">
                                    {
                                      packages.map((dta, key) => (
                                        <>
                                          <label className={`package_card ${PACKAGES[dta.id]['detail_card_class']}`} key={key}>
                                            <input name="plan" className="radio" type="radio"
                                              id={dta.id}
                                              checked={selectedPackage == dta.id}
                                              onChange={() => handlePackageSelect(dta.id)}
                                            />

                                            <span className="plan-details">
                                              <span className={`plan-type ${PACKAGES[dta.id]['class_name']}`}>{PACKAGES[dta.id]['title']}</span>

                                              <span className="p-3">{PACKAGES[dta.id]['description']}</span>
                                              <span className="mx-3 pb-3 validity_bullet">Validity : {PACKAGES[dta.id]['validity']}</span>
                                            </span>
                                          </label>
                                        </>
                                      ))
                                    }
                                  </div>
                                </div>

                                <div className="col-sm-12 col-md-12 mt-3">
                                  <div className="packages_btns d-flex flex-wrap align-items-center justify-content-center gap-2">
                                    {/* <button class="btn cancel_btn" type="submit">Cancel</button> */}
                                    <button class="btn subscribe_btn" type="submit"
                                    disabled={checking}
                                    onClick={() => {
                                      handlePackageSubscribe()
                                    }}>{checking ?  'Loading...' :'Subscribe'} </button>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}


const Loader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <SyncLoader color="#3498DB" />
    </div>
  )
}

export default Packages;