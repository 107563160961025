export const SUCCESSMSGS = {
    INVESTED_CONTRIBUTION_SUCCESS:'Contributed!',
    EMERGENCY_WITHDRAWAL_SUCCESS:'Emergency withdrawal successfully',
    // Create Token
    TOKEN_DEPLOYED:"Token deployed!",
    // Create Prescale
    PRESALE_LISTED:"Good job! successfully listed!",

    PRESALE_UPDATED:'Project details updated',
    PRESALE_CANCELED:'This pool is cancelled',
    LIQUIDITY_LOCAK_EXTENDED:'Liquidity lock extended',
    APPROVED_SUCCESS:'Approved!',
    ADDED_WHITELIST:'Added To whitelisted successfully!',
    REMOVE_WHITELIST:'Removed from whitelisted successfully!',
    WITHDTAWAL_SUCCESS:'Withdrawal successfully!',
    POOLFINALIZED_SUCCESS:'Pool finalized successfully!',
    CLAIM_SUCCESS:'Success!',
    CLAIM_LP_CUCCESS:'Claim lp success!',
    CLAIM_TEAM_VESTING:'Claim team vesting success!',
    STACK_INFO_UPDATED:'Stake information updated!',
    STACK_CANCELED:'This stake is cancelled',
    STACK_DEPOSITE_REWARD:'Deposited!'
}

export const ERRORMSGS = {
    CONNECTTOWALLET:"Please connect to wallet to create a token!",
    LOGINWITHYOURWALLET:'Please login with your wallet!',
    NOTENOUGHBALALNCE:'Not enough balance',
    WAITTILLDEXLISTINGTIME:'Wait till dex list time',
    FUNDRAISEDLESSTHENSOFTCAP:'Fundraised is less than softcap',
    PRESALENOTSTARTED:'Presale not started!',
    IDENTICAL_ADDRESS:'Presale token address is same as investment token',
    WRONG_NETWORK:'Wrong network!',
    TRANSFER_AMOUNT_EXCEED_BALANCE:'Transfer amount exceeds balance!'
}



export const TRANSACTIONMSGS = {
    METAMASKREQUESTREJECT:'User rejected transaction',
    REJECTEDAPPROVING:'Rejected approving!',
    WRONGADDRESS:'This address is not correct!',
    SOMETHINGWENTWRONG:'Something went wrong! refresh & reconnect!',
    INSUFFICIENT_FUND:'Insufficient funds for gas * price + value!',
    CANCELPRESALE:'Presale cancelled!',
    MAXBUYLIMITEXCEED:'Max buy Limit Exceeded',
    HARDCAPLIMITEXCEED:'Hard cap limit exceeded',
    ADDRESS_ALREADY_WHITELISTED:'This address already whitelisted'
}


export const METAMASKERRORS ={
    InvalidTimeForPreSaleToStart:`ContractFunctionExecutionError: The contract function "createPreSale" reverted with the following reason: InvalidTimeForPreSaleToStart`
}