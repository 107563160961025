import { ethers } from "ethers"
import React from "react"

export function FairProgInfo ({fundRaisedData, fund_releasing_token, fundRaisingTokenDecimal, fundRaisingTokenSymbol, hardCap,isHardCap,softCap}){
    return(
        <>
                <div className="d-flex justify-content-between align-items-center">
                        <p>

                            {
                                fundRaisedData != undefined && ethers.utils.formatUnits(String(fundRaisedData), Number(fundRaisingTokenDecimal)) || 0
                            }
                            {" "}
                            {
                                fundRaisingTokenSymbol
                            }

                        </p>
                        <p>
                            {
                                isHardCap == 1
                                    ?
                                    // Number(formatEther(hardCap))
                                    ethers.utils.formatUnits(String(hardCap || 0),Number(fundRaisingTokenDecimal))
                                    :
                                    fundRaisedData > softCap
                                        ?
                                        ethers.utils.formatUnits(
                                            String(fundRaisedData || 0),
                                            Number(fundRaisingTokenDecimal)
                                        )
                                        :
                                        ethers.utils.formatUnits(
                                            String(softCap || 0),
                                            Number(fundRaisingTokenDecimal)
                                        )
                            }
                            {" "}
                            {
                                fundRaisingTokenSymbol
                            }
                        </p>
                    </div>
        </>
    )
}