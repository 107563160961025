import { toast } from "react-toastify";
import { useAccount, useContractRead, useContractWrite, useWaitForTransaction } from "wagmi"
import { getAccount, getNetwork } from "@wagmi/core"
import { STAKE_MANAGER } from "../../_constant";
import StackingManagerABI from "../../_constant/StakingManager.json";
import { TRANSACTIONMSGS } from "../../utils/messages";
import StackPoolABI from "../../_constant/StakePool.json";
import { updateLastDisTimeAction } from "../../redux/apiActions/api.action";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { SyncLoader } from "react-spinners";

export default function ClaimRewards({ stackDetails, getPresaleFuncWithoutLoading }) {
    const { address, isConnected } = useAccount();
    const { chain } = getNetwork()
    const STACK_MANAGER_ADDRESS = STAKE_MANAGER[chain?.network]

    const onClaimRewardError = async (error) => {
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        } else if (error.toString().includes("claimDateTime is not reached")) {
            toast.error("claim Date Time is not reached!")
        } else if(error.toString().includes("NoRewardStakePool")){
          toast.error("No reward stake pool!",{toastId:'nspTid'})
        }else if(error.toString().includes("NoRewardAvailable")){
          toast.error("No reward available",{toastId:'neATid'})
        }else {
            toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG,{toastId:'smwTid'});
        }

    };

    const {
        data: dataClaim,
        write: claimRewardsMethod,
        isLoading: isLoadingClaimReward,
        isSuccess: isSuccessStake,
    } = useContractWrite({
        address: STACK_MANAGER_ADDRESS,
        abi: StackingManagerABI.abi,
        functionName: 'claim',
        args: [stackDetails?.stakeContract],
        onError: onClaimRewardError,
        // onSuccess: onSuccessApprove
    });

    const { data: lastDistributedTime,refetch:refetchLastDisTime } = useContractRead({
        /// PreSale token address
        // @ts-ignore
        address: stackDetails?.stakeContract,
        abi: StackPoolABI.abi,
        functionName: "lastDistributeTime",
        // args: [stakeDetails?.stakeContract],
        watch: true,
        // onError: onTokenFetchError,
      });

    const onSuccessClaimRewards =async () => {

              let refetched_last_distribute_time = await refetchLastDisTime();
              refetched_last_distribute_time=refetched_last_distribute_time.data;
              let bodyData = {last_distribute_time:Number(refetched_last_distribute_time), stakeContract:stackDetails?.stakeContract,address:address}
              let response = await updateLastDisTimeAction(bodyData)
              if(response){
                  toast.success("Claimed")
                  getPresaleFuncWithoutLoading()
                  refetchWithdrawanAndCalculateClaimedAmt()
              }
    }


    const { isLoading: isLoadingClaimRewardWFT } = useWaitForTransaction({
        hash: dataClaim?.hash,
        onSuccess: onSuccessClaimRewards
    });

    const [claimedAmount,setClaimedAmount] = useState(0)
    const [intervalId, setIntervalId] = useState();
    // const countDownForFixedRatio = (
    //     rewardRatio,
    //     withdrawn,
    //     stakeDate
    //   ) => {
    //     if(rewardRatio && withdrawn && stakeDate){
    //       const totalReward = BigInt(rewardRatio);  // eslint-disable-line no-undef
    //       const start = () => {
    //         const currTime = Math.floor(Date.now() / 1000);
    //         const stakeTill = Math.floor((currTime - Number(stakeDate)) / 60);
    //         const rewardGained =
    //           (totalReward * BigInt(stakeTill)) / BigInt(60 * 24 * 365);  // eslint-disable-line no-undef
    //         const _withdrawable = rewardGained - BigInt(Number(withdrawn));  // eslint-disable-line no-undef
    //         const withdrawable =
    //           _withdrawable > BigInt(0) ? _withdrawable : BigInt(0);  // eslint-disable-line no-undef
    //         setClaimedAmount(withdrawable);
    //       };
    //       const id = setInterval(start, 1000);
    //       start();
    //       return id;
    //     }
    //   };
    


      // const countDownForPercentageRatio = (
      //   rewardRatio,
      //   withdrawn,
      //   stakeDate,
      //   totalSupply,
      //   rewardTokenDecimals
      // ) => {
      //   if(rewardRatio && withdrawn && rewardTokenDecimals){      
      //     const totalReward = BigInt(rewardRatio);   // eslint-disable-line no-undef
      //     const _totalSupply = BigInt(totalSupply);   // eslint-disable-line no-undef
      //     const start = () => {
      //       const currTime = Math.floor(Date.now() / 1000);
      //       const stakeTill = Math.floor((currTime - Number(stakeDate)) / 60);
      //       let rewardGained =
      //         (totalReward * _totalSupply) / BigInt(10) ** BigInt(rewardTokenDecimals);  // eslint-disable-line no-undef
      //       rewardGained = (rewardGained * BigInt(stakeTill)) / BigInt(60 * 24 * 365);   // eslint-disable-line no-undef
      //       const _withdrawable = rewardGained - BigInt(withdrawn);    // eslint-disable-line no-undef
      //       const withdrawable = _withdrawable > 0 ? _withdrawable : 0;
      //       setClaimedAmount(withdrawable);
      //     };
      //     const intervalId = setInterval(start, 1000);
      //     start();
      //     return intervalId;
      //   }
      // };


    //   countDown("500000", "500000", 4, "100000", "0", 1693025282 - 864000);

    const { data: myTotalRewardsData,refetch:refetchMyTotalRewards } = useContractRead({
        /// PreSale token address
        // @ts-ignore
        address: stackDetails?.stakeContract,
        abi: StackPoolABI.abi,
        functionName: "withdrawnRewards",
        args: [address],
        watch: true,
        // onError: onTokenFetchError,
      });


      const { data: dataStakeDate,refetch:refetchStakeDate } = useContractRead({
        /// PreSale token address
        // @ts-ignore
        address: stackDetails?.stakeContract,
        abi: StackPoolABI.abi,
        functionName: "stakeDateTime",
        args: [address],
        watch: true,
        // onError: onTokenFetchError,
      });

      const { data: dataTotalSupply,refetch:refetchTotalSupply } = useContractRead({
        /// PreSale token address
        // @ts-ignore
        address: stackDetails?.stakeContract,
        abi: StackPoolABI.abi,
        functionName: "totalSupply",
        // args: [address],
        watch: true,
        // onError: onTokenFetchError,
      });



    //   stakeDateTime

    // const getRewardDistribute = async()=>{
    //     let refetched_MyTotalRewards = await refetchMyTotalRewards()
    //     refetched_MyTotalRewards = refetched_MyTotalRewards.data;

    //     let refetched_stakeDate = await refetchStakeDate()
    //     refetched_stakeDate = refetched_stakeDate.data;

    //     let refetched_token_supply = await refetchTotalSupply()
    //     refetched_token_supply= refetched_token_supply.data;


    //     if(stackDetails?.rewardType==0){
    //         // const id = countDownForPercentageRatio(stackDetails?.rewardRatio, Number(refetched_MyTotalRewards), Number(refetched_stakeDate), Number(refetched_token_supply) || 0, JSON.parse(stackDetails?.rewardTokenInfo)?.decimals || 0 )
    //         // setIntervalId(id)
    //         // return () => clearInterval(id);
    //     }else if(stackDetails?.rewardType==1){
    //         const id = countDownForFixedRatio(stackDetails?.rewardRatio, Number(refetched_MyTotalRewards), Number(refetched_stakeDate))
    //         setIntervalId(id);
    //         return () => clearInterval(id);
    //     }
    // }
    

      // useEffect(()=>{
      //   // getRewardDistribute()
      //   if(stackDetails?.rewardType==1){   
      //     const id = countDownForFixedRatio(stackDetails?.rewardRatio, Number(myTotalRewardsData), Number(dataStakeDate))
      //     setIntervalId(id);
      //     return () => clearInterval(id);
      //   }
      // },[])


      // useEffect(()=>{
      //   if(stackDetails?.rewardType==0){  
      //     const id = countDownForPercentageRatio(stackDetails?.rewardRatio, Number(myTotalRewardsData), Number(dataStakeDate), Number(dataTotalSupply) || 0, JSON.parse(stackDetails?.rewardTokenInfo)?.decimals || 0 )
      //     setIntervalId(id);
      //     return () => clearInterval(id);
      //   }
      // },[])


      const refetchWithdrawanAndCalculateClaimedAmt = async()=>{
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(undefined);
          }

        let refetched_MyTotalRewards = await refetchMyTotalRewards()
        refetched_MyTotalRewards = refetched_MyTotalRewards.data;

        let refetched_stakeDate = await refetchStakeDate()
        refetched_stakeDate = refetched_stakeDate.data;

        let refetched_token_supply = await refetchTotalSupply()
        refetched_token_supply= refetched_token_supply.data;

        // if(stackDetails?.rewardType==0){
        //     const id = countDownForPercentageRatio(stackDetails?.rewardRatio, Number(refetched_MyTotalRewards), refetched_stakeDate, Number(refetched_token_supply) || 0, JSON.parse(stackDetails?.rewardTokenInfo)?.decimals || 0 )
        //     setIntervalId(id);
        // }else if(stackDetails?.rewardType==1){
        //     const id = countDownForFixedRatio(stackDetails?.rewardRatio, refetched_MyTotalRewards, dataStakeDate)
        //     setIntervalId(id);
        // }
      }

      // const refetchStakeDateAndCalculateClaimedAmt = async()=>{
      //   if (intervalId) {
      //       clearInterval(intervalId);
      //       setIntervalId(undefined);
      //     }

      //   let refetched_stakeDate = await refetchStakeDate()
      //   refetched_stakeDate = refetched_stakeDate.data;

      //   let refetched_token_supply = await refetchTotalSupply()
      //   refetched_token_supply= refetched_token_supply.data;

      //   let refetched_MyTotalRewards = await refetchMyTotalRewards()
      //   refetched_MyTotalRewards = refetched_MyTotalRewards.data;

      //   if(stackDetails?.rewardType==0){
      //       // const id = countDownForPercentageRatio(stackDetails?.rewardRatio, Number(refetched_MyTotalRewards), refetched_stakeDate, Number(refetched_token_supply) || 0, JSON.parse(stackDetails?.rewardTokenInfo)?.decimals || 0 )
      //       // setIntervalId(id);
      //   }else if(stackDetails?.rewardType==1){
      //     const id = countDownForFixedRatio(stackDetails?.rewardRatio, myTotalRewardsData, refetched_stakeDate)
      //     setIntervalId(id);
      //   }
      // }

      const account = getAccount()
      const claimableAmount = useContractRead({
        address: stackDetails?.stakeContract,
        abi: StackPoolABI.abi,
        functionName: 'watchClaimableAmount',
        args: [account?.address],
        watch: true,
      })

    return (
      <>
       { claimableAmount.data != BigInt(0)&& // eslint-disable-line no-undef
       <>   
        <div className="custom_card mt-4">
            <div className="p-3">
                <div className="progress_bar mt-2 text-center">
                <p>Claimable Amount</p>
                <p>{ethers.utils.formatUnits(String(claimableAmount?.data|| 0),stackDetails?.rewardTokenInfo && JSON.parse(stackDetails?.rewardTokenInfo)?.decimals || 0).toString()}</p>
                </div>
                <ul className="presale_address_ui estimated_listing_time mt-1">
                    <li className="d-flex">
                        <button type="submit" className="btn btn-success btn-block mt-4 btn_managepool"
                            onClick={() => { claimRewardsMethod() }}
                            disabled={isLoadingClaimReward || isLoadingClaimRewardWFT}
                        >
                            {
                                isLoadingClaimReward || isLoadingClaimRewardWFT ?  <SyncLoader color={'#3498DB'} size={10} /> : 'Claim Rewards'
                            }</button>
                    </li>
                </ul>
            </div>
        </div>
        </>
      }
      
        </>
    )
}