import React, { useState } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { useAccount, useContractRead, useContractWrite, useNetwork, useToken, useWaitForTransaction } from "wagmi";
import moment from "moment";
import { LOCKERS_ADDRESSESS, blockInvalidChar } from "../../_constant";
import LockerAddress from "../../_constant/Locker.json";
import SimpleToken from "../../_constant/SimpleToken.json";
import Factory from "../../_constant/Factory.json";
import { ERRORMSGS, METAMASKERRORS, SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { decodeEventLog, parseAbi } from "viem";
import { lockAction } from "../../redux/apiActions/api.action";
import { bscTestnet } from "viem/chains";
import { useEffect } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "@mui/material";
import { SyncLoader } from "react-spinners";
import copy from 'clipboard-copy';


const currentTime = dayjs();
const defaultTime = currentTime.add(1, 'month')

export default function CreateLock() {

    document.title =`Create Lock`

    const { chain } = useNetwork();

    const { address } = useAccount()

    const LOCKER_ADDRESS = LOCKERS_ADDRESSESS[chain?.network]


    const [lockUntil, setLockUntil] = useState(defaultTime)
    const [token, _token] = useState("")
    const [amount, setAmount] = useState(0)

    const { data: preSaleToken, isSuccess: isTokenFetched,isLoading:isLoadingPresale,isError:isErrorPresale,isFetching:isFetchingPresale } = useToken({
        address: token,
        // onError: onTokenFetchError,
        // onSuccess: onSuccessPresaleToken,
    });

    function handleDateChangeStart(dateString) {
        setLockUntil(moment(new Date(dateString)).unix())
    }


    const { data: isAllowanceData, isError: isErrorAllowance, isLoading: isLoadingAllowance, refetch: refetchAllowance } = useContractRead({
        address: token != "" ? token : undefined,
        abi: SimpleToken.abi,
        functionName: "allowance",
        args: [address, LOCKER_ADDRESS],
    })

    const { data: isFactory, isError: isErrorFactory, isLoading: isLoadingFactory, refetch: refetchFactory } = useContractRead({
        address: token != "" ? token : undefined,
        abi: Factory.abi,
        functionName: "factory",
        watch: true
    })

    // console.log(isFactory, '----errr', isErrorFactory)
    const Lock = () => {
        if(Number(amount) > ethers.utils.formatUnits(String(balanceOf || 0), preSaleToken?.decimals)){
        }else{
                const curr_unix = moment().unix();
                const atleastTime  = moment.unix(curr_unix).add(1,'day').unix();
                if( lockUntil < atleastTime ){
                    return toast.error("Opps, Lock duration should atleast one day!",{toastId:'lockDuSal1d'})
                }


            if (isAllowanceData == undefined || Number(ethers.utils
                .formatUnits(String(isAllowanceData || 0), Number(preSaleToken?.decimals || 0))
                .toString()) < Number(amount)) {
                approveToken({ args: [LOCKER_ADDRESS, ethers.utils.parseUnits(String(amount), Number(preSaleToken?.decimals)).toString()] })
            } else {
                writeLock({ args: [token, lockUntil, ethers.utils.parseUnits(String(amount), Number(preSaleToken?.decimals)).toString()] })
            }
        }
    }

    // args: [token, lockUntil, amount],


    const onApproveError = (error) => {
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.REJECTEDAPPROVING)
        } else {
            toast.error('Failed in approving!');
        }
    }

    const onSuccessApprove = (data) => {
    }


    const {
        data: approvedData,
        write: approveToken,
        isLoading: isLoadingApprove,
        isSuccess: isApprovedSuccess,
    } = useContractWrite({
        address: token,
        abi: SimpleToken.abi,
        functionName: "approve",
        // args: [LOCKER_ADDRESS, ethers.utils.parseUnits(String(amount), Number(preSaleToken?.decimals)).toString()],
        onError: onApproveError,
        onSuccess: onSuccessApprove
    });

    const onSuccessWaitForTransactionApprove = (data) => {
        // refetchAllowance()
        // DispSameTokToAdd()
        toast.success(SUCCESSMSGS.APPROVED_SUCCESS)
        writeLock({ args: [token, lockUntil, ethers.utils.parseUnits(String(amount), Number(preSaleToken?.decimals)).toString()] })
    }
    const { isLoading: isLoadingWFTApprove } = useWaitForTransaction({
        hash: approvedData?.hash,
        // onSettled: onApproveReceipt,
        onSuccess: onSuccessWaitForTransactionApprove
    });



    const onErrorLock = (error) => {
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        } else if (error.toString().includes('AmountShouldBeMoreThanZero')) {
            toast.error("Amount Should Be More Than Zero!")
        } else if (error.toString().includes("AtleastOneDayRequired")) {
            toast.error("Atleast One Day Required!")
        }
        else {
            toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG)
        }
    }

    const onSuccessLock = () => {

    }

    const {
        data: dataLock,
        write: writeLock,
        isLoading: isLoadingLock,
        isSuccess: isSuccessLock,
    } = useContractWrite({
        address: LOCKER_ADDRESS,
        abi: LockerAddress.abi,
        functionName: "lock",
        onError: onErrorLock,
        // args: [token, lockUntil, amount],
        onSuccess: onSuccessLock
    });
    const onSuccessWFTLock = async ({ logs }) => {

        const lastIndex = chain?.id ==bscTestnet.id?logs.length - 1:logs.length - 2;  //DynamicChain
        const { args } = decodeEventLog({
            abi: parseAbi([
                "event Locked(address token,uint256 id,uint256 startedAt,uint256 endAt,uint256 amount)",]),
            // `data` should be 64 bytes, but is only 32 bytes.
            data: logs[lastIndex]?.data,
            topics: [
                logs[lastIndex]?.topics[0],
            ],
        });



        const bodyData = {
            address: address,
            type: !isFactory ? 0 : 1,
            token: args.token,
            chain: chain.id,
            id: Number(args.id),
            startedAt: Number(args.startedAt),
            endAt: Number(args.endAt),
            amount: String(args.amount),
            token_info: { name: preSaleToken.name, symbol: preSaleToken.symbol, decimal: Number(preSaleToken.decimals) },
          
        };

        await lockAction(bodyData).then(() => {
            toast.success("Locked!")
            _token('')
            setAmount(0)
        }).catch((e)=>{
            console.log(e,'error')
        })

    }
    const { isLoading: isLoadingWFTLock } = useWaitForTransaction({
        hash: dataLock?.hash,
        // onSettled: onApproveReceipt,
        onSuccess: onSuccessWFTLock
    });


    const { data: balanceOf } = useContractRead({
        /// PreSale token address
        // @ts-ignore
        address: token,
        abi: SimpleToken.abi,
        functionName: "balanceOf",
        args: [address],
        // onError: onTokenFetchError,
    });

// console.log(preSaleToken,'preSaleToken')
    // console.log("balanceOf",ethers.utils.formatUnits(String(balanceOf),preSaleToken.decimals))

    const { isConnected } = useAccount()

    function isNumberKey(value) {
        value = value ? value : "";
        // alert(value)
        var validNumber = new RegExp(/^[0-9]*(\.[0-9]*)?$/);
        if (validNumber.test(value)) {
            return value;
        } else {
            return false;
        }
    }


    const [error,_error] = useState(false)
    useEffect(()=>{
        if(Number(amount) > ethers.utils.formatUnits(String(balanceOf || 0), preSaleToken?.decimals || 0))
        {
            _error(true)
        }else{
            _error(false)
        }
    },[amount])

    const validateAddress = (address) => {
        const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        return addressRegex.test(address);
      };

    return (
        <>
            <div className="container-fluid mt-5">
                <div className="approve_token_card card">
                    <h4 className="card-header">Create Your Locks for Free</h4>
                    <div className="card-body">

                        <div className="row">
                            <div className="approve_token_form">
                                <div className="col-md-12 mb-0">
                                    <div className="form-group mt-4">
                                        <label htmlFor="liquidity-lock form-label">Token Address</label>
                                        <input type="text" id="liquidity-lock"
                                        className={`create_token_input ${(isErrorAllowance && token != "") && 'invalid'}`}
                                            value={token}
                                            onChange={({ target }) => {
                                                if (!isConnected) {
                                                    toast.error(ERRORMSGS.LOGINWITHYOURWALLET, { toastId: "loginFirst" })
                                                } else {
                                                    _token(target.value)
                                                }

                                            }}
                                            name="preSaleToken"
                                        />
                                        { (isLoadingPresale || isFetchingPresale) && !isErrorAllowance && validateAddress(token) && 
                                                <div>
                                                    <SyncLoader color="#3498DB" size={10}/>
                                            </div>
                                                }
                                        <div  style={{ width: "100%", height: "20px",marginTop:'-14px' }} className="mb-2">
                                            <div className="text-danger">{(isErrorAllowance && token != "" && !preSaleToken) && `Invalid token address`}</div>
                                        </div>
                                    </div>
                                        {
                                            preSaleToken &&
                                            <div className="token_details_section_bottom mb-4">
                                                {/* <hr /> */}
                                                {
                                                    preSaleToken &&
                                                    <section className="mt-3">
                                                        <ul className="text-white mt-1">
                                                            <li className="d-flex flex-wrap align-items-center justify-content-between">
                                                                Name<span>{preSaleToken?.name}</span>
                                                            </li>
                                                            <li className="d-flex flex-wrap align-items-center justify-content-between">
                                                                Symbol<span>{preSaleToken?.symbol}</span>
                                                            </li>
                                                            <li className="d-flex flex-wrap align-items-center justify-content-between">
                                                                Total Supply
                                                                <span>
                                                            { ethers.utils.formatUnits(String(preSaleToken?.totalSupply.value),preSaleToken?.decimals || 0)}
                                                                    {/* {preSaleToken?.totalSupply.formatted} */}
                                                                </span>
                                                            </li>
                                                            <li className="d-flex flex-wrap align-items-center justify-content-between">
                                                                Decimals
                                                                <span>
                                                                    {preSaleToken?.decimals}
                                                                </span>
                                                            </li>
                                                            <li className="d-flex flex-wrap align-items-center justify-content-between">
                                                                Balance
                                                                <span>
                                                                    {ethers.utils.formatUnits(String(balanceOf || 0), preSaleToken?.decimals)}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        {/* <hr /> */}
                                                    </section>
                                                }
                                            </div>
                                        }


                                </div>
                                <div className="col-md-12 mb-4">
                                    <div className="form-group">
                                        <label htmlFor="hardcap">Amount</label>
                                        <div className="position-relative d-flex align-item-center justify-content-between">
                                        <input
                                            type="number"
                                            className={`create_token_input ${error && 'invalid'}`}
                                            id="hardcap"
                                            value={amount}
                                            onWheel={(e) => e.target.blur()}
                                            onKeyDown={blockInvalidChar}
                                            style={{marginBottom:0}}
                                            // onChange={(e) => {
                                            //     const inputValue = e.target.value;
                                            //     const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
                                            //     setAmount(numericValue)
                                            // }}
                                            onChange={(e) => {
                                                const inputValue = isNumberKey(e.target.value);
                                                // const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
                                                const numericValue = inputValue; // Remove non-numeric characters except dot (.)
                                                if (numericValue !== false) {
                                                    if (e.target.value.length < 21) {
                                                        setAmount(numericValue)
                                                    }
                                                }
                
                
                                            }}
                                            

                                        />
                                            <button type="button" className="btn primary-btn max-btn-locking position-absolute"
                                        onClick={()=>{setAmount(ethers.utils.formatUnits(String(balanceOf || 0), preSaleToken?.decimals || 0))}}
                                        >MAX</button>
                                        </div>
                                        
                                        {
                                            error &&
                                            <p className="text-danger">Please enter an amount within your available balance.</p>
                                        } 
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <div id="approve_token_form">
                                        <div className="text-start">
                                        <label htmlFor="hardcap">Lock until:</label>
                                        </div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer
                                                    components={["MobileDateTimePicker", "MobileDateTimePicker"]}
                                                >
                                                    <MobileDateTimePicker
                                                        className="form-control"
                                                        // label={'Estimated Dex Listing Date (Local)'}
                                                        openTo="hours"
                                                        name="dexlistingTime"
                                                        onChange={handleDateChangeStart}
                                                        defaultValue={defaultTime}
                                                    // onChange={handleDateChangeEstDexList}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
    <div className="row align-items-center">
        <div className="col-auto">
            <i className="fa fa-warning"/> &nbsp;&nbsp;
            Exclude Scanner's lock address &nbsp;
            <b>{LOCKER_ADDRESS}</b> &nbsp;                      
            <Tooltip title="Copy" arrow>
            {/* <CopyToClipboard text={LOCKER_ADDRESS}> */}
                    <ContentCopyIcon style={{ width: '18', cursor: 'pointer' }} onClick={() => { copy(LOCKER_ADDRESS).then(()=>{
                        toast.success("Copied");
                    })  }} />
            {/* </CopyToClipboard>&nbsp; */}
                </Tooltip>
            {" "}From Fees, Max Transaction and Rewards.
        </div>
        </div>
    
    </div>
                        </div>




                    <div className="btn align-items-center d-flex mx-auto mb-1">
                        <button disabled={isLoadingApprove || isLoadingLock || isLoadingWFTApprove || isLoadingWFTLock || preSaleToken == undefined || Number(amount) > ethers.utils.formatUnits(String(balanceOf || 0), preSaleToken?.decimals || 0)} onClick={() => { Lock() }}>{isLoadingApprove || isLoadingWFTApprove ? 'Approving...' : isLoadingLock || isLoadingWFTLock ? 'Locking...' : 'Lock'}</button>
                    </div>
                </div>
            </div>
        </>
    )
}