import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PublicRoutes from "./routes/publicRoutes/public.routes";
import PrivateRoutes from "./routes/privateRoutes/private.routes";
import NoGuard from "./routes/Guards/NoGuard";
import AuthGuard from "./routes/Guards/AuthGuard";

export default function Application({ notificationToken }) {

  return (
    <React.Fragment>
      <Routes>
        <Route
          path={`/*`}
          element={
            <NoGuard>
              <PublicRoutes notificationToken={notificationToken} />
            </NoGuard>
          }
        />
        <Route path={`/auth/*`} element={<AuthGuard><PrivateRoutes /></AuthGuard>} />
      </Routes>
    </React.Fragment>
  )
}
