import React, { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import Countdown from "../../Components/CountDown/CountDown";
import { getAccount, getNetwork } from "@wagmi/core"
import { useContractRead, useContractWrite } from "wagmi";
import { blockInvalidChar, isNumberKey } from "../../_constant";
import Stake from "./Stake";
import { ethers } from "ethers";

export default function CnbtStackDetails({ stackDetails, isAllowanceData, refetchAllowance, approveToken,isLoadingApprove, isLoadingWFTApprove, mystackGet, my_staking, myTotalRewardsData,balanceOf, getStakeDetails }) {
  if (!stackDetails)
    return null


  const { startDateTime, endDateTime, isCancel, stackTokenInfo, stakeToken, stakeContract } = stackDetails;

  const currentUnixTime = moment().unix();
  const startTime = Number(startDateTime);
  const endTime = Number(endDateTime);

  let classNameD = "sale_upcoming";
  let statusText = "Upcoming";

  if (isCancel === 1) {
    statusText = "Sale cancelled";
  } else if (currentUnixTime >= startTime && endTime > currentUnixTime) {
    classNameD = "sale_live";
    statusText = "Sale Live";
  } else if (currentUnixTime < startTime) {
    classNameD = "sale_upcoming";
  } else if (currentUnixTime > endTime) {
    statusText = "Sale Ended";
    classNameD = "sale_end";
  }

  const renderCountdown = (text, time) => (
    <div className="text-center">
      <p className="mb-0" style={{ color: 'white' }}>{text}</p>
      <span>
        <Countdown unixTime={time} />
      </span>
    </div>
  );



  const account = getAccount()

  return (
    <div className="contribute_r_section">
      <div className="custom_card">
        <div className="p-3">
          <div className="progress_bar mt-2">
            {isCancel === 1 ? (
              <div className="text-center">
                <p>Stake Cancelled</p>
              </div>
            ) : statusText === "Upcoming" ? (
              renderCountdown("Stake Available In:", startDateTime)
            ) : statusText === "Sale Live" ? (
              renderCountdown("Stake Ends In:", endDateTime)
            ) : statusText === "Sale Ended" ? (
              <div className="text-center">
                <span>Stake Ended</span>
              </div>
            ) : null}

            {isCancel === 0 && (
              <div className="text-center">
                {
                  Number(isAllowanceData) ==0 ?
                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-4 btn_managepool"
                  disabled={isLoadingApprove|| isLoadingWFTApprove}
                  onClick={()=>{approveToken(
                        {
                          args:[stackDetails?.stakeContract,
                            balanceOf || 0
                          ],
                        }

                  )}}
                >
                  {isLoadingApprove|| isLoadingWFTApprove? `Approving ${JSON.parse(stackTokenInfo).symbol}...`:`Approve ${JSON.parse(stackTokenInfo).symbol}`} 
                </button>
                :
                <button
                type="submit"
                className="btn btn-primary btn-block mt-4 btn_managepool"
                onClick={()=>{approveToken(
                  {
                    args:[stackDetails?.stakeContract,
                      // String(ethers.utils.parseUnits(String(Number(balanceOf || 0)),Number(stakeDetails && JSON.parse(stakeDetails?.rewardTokenInfo)?.decimals || 0)))
                      String(ethers.utils.parseUnits(String(Number(0)),Number(stackDetails && JSON.parse(stackDetails?.rewardTokenInfo)?.decimals || 0)))
                    ],
                  }

                )}}
                disabled={isLoadingApprove|| isLoadingWFTApprove}
              >
                {isLoadingApprove|| isLoadingWFTApprove? 'Revoking...':`Revoke ${JSON.parse(stackTokenInfo).symbol}`} 

              </button>
                }
              </div>
            )}
          </div>
          
          {
            // currentUnixTime >= startTime && endTime > currentUnixTime &&
              <Stake stackDetails={stackDetails} mystackGet={mystackGet} my_staking={my_staking} getStakeDetails={getStakeDetails} startTime={startTime} endTime={endTime}/>
          }


          <ul className="presale_address_ui">
            <li className="d-flex justify-content-between">
              <p>My Staked Amount</p>
              <p>
                {parseFloat(my_staking?.amount) || 0} {stackTokenInfo && JSON.parse(stackTokenInfo).symbol}
              </p>
            </li>

            <li className="d-flex justify-content-between">
              <p>My Total Rewards</p>
              <p>
                {ethers.utils
                                  .formatUnits(String(myTotalRewardsData || 0), JSON.parse(stackDetails?.rewardTokenInfo)?.decimals || 0)
                                  .toString()}
                                  {" "}
                {stackTokenInfo && JSON.parse(stackTokenInfo).symbol}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
