import React from 'react'
import { BASE_URL } from '../../_constant'

export default function PrescaleHeader({ onButtonClick,actived }) {

  const tabChangeHear = (tab) => {
    onButtonClick(tab)
  }


  return (
    <>
      <ul className="nav nav-tabs">
        {/* <li className="nav-item">
          <button
            className={`nav-link ${actived=='quick_view'&&'active'}`}
            data-bs-toggle="tab"
            href="#allproducts"
            onClick={() => { tabChangeHear("quick_view") }}
          >
            <img
              src={BASE_URL+`images/icon/quickview.png`}
              className="img-fluid me-2"
              alt="icon"
            />{" "}
            Quick View
          </button>
        </li> */}
        <li className="nav-item">
          <button
            className={`nav-link ${actived=='all_products'&&'active'}`}
            data-bs-toggle="tab"
            href="#allproducts"
            onClick={() => { tabChangeHear("all_products") }}
          >
            <img
              src={BASE_URL+`images/icon/allproducts.png`}
              className="img-fluid me-2 active"
              alt="icon"
            />{" "}
            All Presales
          </button>
        </li>
        {/* upcoming */}
        <li className="nav-item">
          <button
            className={`nav-link ${actived=='my_contribution'&&'active'}`}
            data-bs-toggle="tab"
            href="#allproducts"
            onClick={() => { tabChangeHear("my_contribution") }}
          >
            <img
              src={BASE_URL+`images/icon/mycontribution.png`}
              className="img-fluid me-2 active"
              alt="icon"
            />{" "}
            My Contributions
          </button>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${actived=='my_favorites'&&'active'}`}
            data-bs-toggle="tab"
            href="#allproducts"
            onClick={() => { tabChangeHear("my_favorites") }}
          >
            <img
              src={BASE_URL+`images/icon/myfavorites.png`}
              className="img-fluid me-2"
              alt="icon"
            />{" "}
            My Favorites
          </a>
        </li>
        {/* Upcoming */}
        <li className="nav-item">
          <a 
            className={`nav-link ${actived=='myalarms'&&'active'}`}
            data-bs-toggle="tab"
            href="#allproducts"
            onClick={() => { tabChangeHear("myalarms") }}
        
          >
            <img
              src={BASE_URL+`images/icon/myalarms.png`}
              className="img-fluid me-2"
              alt="icon"
            />{" "}
            My Alarms
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${actived=='my_created_presale'&&'active'}`}
            data-bs-toggle="tab"
            href="#allproducts"
            onClick={() => { tabChangeHear('my_created_presale') }}
          >
            <img
              src={BASE_URL+`images/icon/mycreatedpresales.png`}
              className="img-fluid me-2"
              alt="icon"
            />{" "}
            My Created Presales
          </a>
        </li>
      </ul>
    </>
  )
}
