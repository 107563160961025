import React from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../_constant";


export default function ReferralsCashback(){
    const navigate = useNavigate()
    return (
      <>
          <div className="container-fluid">
              <div className="row">
                  <div className="col-xxl-9 col-lg-10 col-md-12 mx-auto">
                      <div className="row mt-5">
                          <div id="approve_token_form">
                              <div className="row">
                                  <div className="col-md-12 mx-auto">
                                      <div className="row">
                                          <div className="col-md-12 mx-auto">
                                              <div className="approve_token_section">
                                                  <h3 className="text-center">Referrals & Cashback</h3>

                                                  <div className="mt-2">

                                                      <div className="row row-cols-1 row-cols-md-3 gap-4 justify-content-center mx-0">
                                                          <div className="col approve_token_card d-flex flex-wrap justify-content-between">
                                                              <div className="text-start">
                                                                  <h3>$200.00</h3>
                                                                  <span className="mt-2">Total Referrals</span>
                                                              </div>
                                                              <a href="#">
                                                                  <img src="images/referrals-icon.png" alt="Total Referrals" />
                                                              </a>
                                                          </div>

                                                          <div className="col approve_token_card d-flex flex-wrap justify-content-between">
                                                              <div className="text-start">
                                                                  <h3>$200.00</h3>
                                                                  <span className="mt-2">Total Cashback</span>
                                                              </div>
                                                              <a href="#">
                                                                  <img src="images/total_cashback.png" alt="Total Cashback" />
                                                              </a>
                                                          </div>
                                                      </div>
                                                  </div>

                                                  <div className="approve_token_card mt-4 text-center p-md-4 p-3">

                                                      <div className="row row-cols-1">
                                                          <div className="col mx-auto">
                                                              <h5 className="text-white">Summary</h5>
                                                          </div>

                                                          <div className="col-sm-12 col-md-12 mt-3">
                                                              <div class="table-responsive">
                                                                  <table class="table subscriptions_Cashback_view_table">
                                                                      <thead>
                                                                          <tr>
                                                                              <th className="text-start">Referral Info</th>
                                                                              <th>Status</th>
                                                                              <th>Cashback used</th>
                                                                              <th className="text-end">Cashback used date</th>
                                                                          </tr>
                                                                      </thead>

                                                                      <tbody className="mt-3">
                                                                          <tr>
                                                                              <td className="text-start referral_info_td">
                                                                              1FfmbHfnpaZjKFvyi1okTjJJusN455paPH <br/>
                                                                              <p className="mb-0">doloresJHunter@dayrep.com<span className="ms-1">(doloresjhunter)</span></p>
                                                                              </td>
                                                                              <td className="text-green">Paid</td>
                                                                              <td>$50.00</td>
                                                                              <td className="text-end">28-11-2023</td>
                                                                          </tr>

                                                                          <tr>
                                                                              <td className="text-start referral_info_td">
                                                                              1FfmbHfnpaZjKFvyi1okTjJJusN455paPH <br/>
                                                                              <p className="mb-0">doloresJHunter@dayrep.com<span className="ms-1">(doloresjhunter)</span></p>
                                                                              </td>
                                                                              <td className="text-green">Paid</td>
                                                                              <td>$50.00</td>
                                                                              <td className="text-end">28-11-2023</td>
                                                                          </tr>

                                                                          <tr>
                                                                              <td className="text-start referral_info_td">
                                                                              1FfmbHfnpaZjKFvyi1okTjJJusN455paPH <br/>
                                                                              <p className="mb-0">doloresJHunter@dayrep.com<span className="ms-1">(doloresjhunter)</span></p>
                                                                              </td>
                                                                              <td className="text-green">Paid</td>
                                                                              <td>$50.00</td>
                                                                              <td className="text-end">28-11-2023</td>
                                                                          </tr>

                                                                          <tr>
                                                                              <td className="text-start referral_info_td">
                                                                              1FfmbHfnpaZjKFvyi1okTjJJusN455paPH <br/>
                                                                              <p className="mb-0">doloresJHunter@dayrep.com<span className="ms-1">(doloresjhunter)</span></p>
                                                                              </td>
                                                                              <td className="text-green">Paid</td>
                                                                              <td>$50.00</td>
                                                                              <td className="text-end">28-11-2023</td>
                                                                          </tr>
                                                                          
                                                                      </tbody>
                                                                  </table>
                                                              </div>
                                                          </div>
                                                      </div>

                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}
