import React, { useState } from "react";
import {PRESALE_MANAGER_ADDRESSESS } from "../../_constant";
import PreSaleManager from "../../_constant/PreSaleManager.json";
import { useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import * as Yup from "yup"
import { useFormik } from "formik";
import { getAccount, getNetwork } from "@wagmi/core"
import { addWhiteListedAction, addWhiteListedSpecialAction, removeWhitelistAction, removeWhitelistSpecialAction } from "../../redux/apiActions/api.action";
import { toast } from "react-toastify";
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { SyncLoader } from "react-spinners";


export function AddWhiteList({ presaleDetails, getPresaleFuncWithoutLoading }) {
  const account = getAccount()

  const { chain, chains } = useNetwork();

  const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network]

  const validateAddress = (address) => {
    const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    return addressRegex.test(address);
  };

  const [submitButtonState, setSubmitButtonState] = useState(null)

  const formikAddToWhiteList = useFormik({
    enableReinitialize: true,
    initialValues: {
      addresses: ""
    },
    validationSchema: Yup.object().shape({
      addresses: Yup.string().required("Address is required!").test(
        "Validate Email",
        "Invalid Address list!",
        (value) => {
          const re =
            /^\w+(,\w+)*$/
          return re.test(value)
        },
      ).test("validate-valid-length-address", "Maximum Address should be 500", (value) => {
        const addressArr = value.split(",")
        return addressArr.length < 500;
      }).test("validate-valid-address", "Invalid address", (value) => {
        const addressArr = value.split(",")
        return addressArr.every((address) => validateAddress(address));
      }),
    }),
    onSubmit: async (data) => {
      try {
        if (submitButtonState === "add") {
          addToWhiteList();
        } else if (submitButtonState === "remove") {
          removeFromWhiteList();
        }
        //   resetForm(); // Reset the form after submission
      } catch (error) {
        // Handle errors
      } finally {
        // Perform any necessary cleanup or actions
      }
    },
  });




  const onErrorAddWhiteList = (error) => {
    let errStr = error.toString().slice(0, 25)
    if (errStr === "TransactionExecutionError") {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
    }else if(error.toString().includes('AddressCannotBeZeroAddress')){
      toast.error(TRANSACTIONMSGS.ADDRESS_ALREADY_WHITELISTED)
    }
    else {
      // console.log(error)
      toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG)
    }

  }

  const onSuccessAddWhiteList = () => {

  }

  const {
    data: dataAddWhiteList,
    isLoading: isLoadingAddWhitList,
    write: addToWhiteList,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: "addAddressesIntoWhitelist",
    args: [presaleDetails?.preSale, formikAddToWhiteList.values.addresses.split(",")],
    onError: onErrorAddWhiteList,
    onSuccess: onSuccessAddWhiteList
  });

  const onSuccesAddWhi = async (receipt) => {
    // console.log("receipt", receipt)
    // referchFundraise()

    let body = formikAddToWhiteList.values;
    body.owner = account.address;
    body.preSale = presaleDetails?.preSale;

    let response = await addWhiteListedSpecialAction(body);
    if (response) {
      toast.success(SUCCESSMSGS.ADDED_WHITELIST)
      formikAddToWhiteList.setFieldValue("addresses", "");
      formikAddToWhiteList.setFieldTouched({})
      formikAddToWhiteList.setErrors({})
      getPresaleFuncWithoutLoading()
    }

  }

  const onSettledAddWhi = (receipt) => {

  }

  const { data: dataWFTAddWhi, isError: isErrorWFTAddWhi, isLoading: isLoadingWFTAddWhi } = useWaitForTransaction({
    hash: dataAddWhiteList?.hash,
    onSettled: onSettledAddWhi,
    onSuccess: onSuccesAddWhi
  })


  //   Remmove WhiteList 
  const onErrorRemoveWhiteList = (error) => {
    let errStr = error.toString().slice(0, 25)
    if (errStr === "TransactionExecutionError") {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
    } else {
      toast.error(TRANSACTIONMSGS.WRONGADDRESS)
    }

  }

  const onSuccessRemoveWhiteList = (data) => {

  }

  const {
    data: dataRemoveWhiteList,
    isLoading: isLoadingRemoveWhitList,
    write: removeFromWhiteList,
  } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: "removeAddressesFromWhitelist",
    args: [presaleDetails?.preSale, formikAddToWhiteList.values.addresses.split(",")],
    onError: onErrorRemoveWhiteList,
    onSuccess: onSuccessRemoveWhiteList
  });


  const onSuccesRemoveWhi = async (receipt) => {
    // console.log("receipt", receipt)
    // referchFundraise()

    let body = formikAddToWhiteList.values;
    body.owner = account.address;
    body.preSale = presaleDetails?.preSale;

    let response = await removeWhitelistSpecialAction(body);
    if (response) {
      toast.success(SUCCESSMSGS.REMOVE_WHITELIST)
      formikAddToWhiteList.setFieldValue("addresses", "");
      formikAddToWhiteList.setFieldTouched({})
      formikAddToWhiteList.setErrors({})
      getPresaleFuncWithoutLoading()
    }

  }

  const onSettledRemoveWhi = (receipt) => {

  }

  const { data: dataWFTRemoveWhi, isError: isErrorWFTRemoveWhi, isLoading: isLoadingWFTRemoveWhi } = useWaitForTransaction({
    hash: dataRemoveWhiteList?.hash,
    onSettled: onSettledRemoveWhi,
    onSuccess: onSuccesRemoveWhi
  })


  return (
    <>

      <div className="form-group mt-4"> 
        <label htmlFor="liquidity-lock form-label">List of Addresses</label>
        <Tooltip  arrow title="Add 500 addresses per 1 upload and each address should be separated by comma(,)"
                      placement="top"
                      enterTouchDelay={false}
                      >
                       <InfoIcon  style={{marginLeft:'2px',width:'19'}}/>
                      </Tooltip>

        <textarea
          style={{ backgroundColor: '#1C242E', color: 'white' }}
          className={`form-control ${formikAddToWhiteList.errors.addresses && formikAddToWhiteList.touched.addresses && 'input_invalid'}`}
          rows={2}
          id="comment"
          defaultValue={""}
          name="addresses"
          value={formikAddToWhiteList.values.addresses}
          onChange={formikAddToWhiteList.handleChange}
        />
        {formikAddToWhiteList.errors.addresses && formikAddToWhiteList.touched.addresses ? (
          <span className="text-danger">{formikAddToWhiteList.errors.addresses}</span>
        ) : null}
        <div className="col-12 d-grid gap-2">
          <button className="btn btn-success btn-block mt-4"
            // onClick={addToWhiteList}
            type="submit"
            onClick={(event) => {
              setSubmitButtonState("add")
              event.preventDefault();
              formikAddToWhiteList.handleSubmit();
            }}
            disabled={isLoadingAddWhitList || isLoadingWFTAddWhi}
          >{isLoadingAddWhitList || isLoadingWFTAddWhi ?  <SyncLoader color={'#3498DB'} size={10} /> : 'Add to Whitelist'}</button>
        </div>

        <div className="col-12 d-grid gap-2">
          <button className="btn btn-success btn-block mt-4 mb-4"
            type="submit"
            onClick={(event) => {
              setSubmitButtonState("remove")
              event.preventDefault();
              formikAddToWhiteList.handleSubmit();
            }}
            disabled={isLoadingWFTRemoveWhi || isLoadingRemoveWhitList}
          >{isLoadingWFTRemoveWhi || isLoadingRemoveWhitList ?  <SyncLoader color={'#3498DB'} size={10} /> : 'Remove From Whitelist'}</button>
        </div>
        {/* <div className="col-12 d-grid gap-2 mt-4">
                    <label htmlFor="liquidity-lock form-label">Whitelist timer (1 - 1440) minutes</label>
                    <input type="number" className="contribute_amount_input form-control" id="liquidity-lock"
                        name="liqidity_lock"
                    />
                </div>
                <div className="col-12 d-grid gap-2">
                    <button className="btn btn-primary btn-block mt-4"
                    >Change WhiteList Timer</button>
                </div> */}


      </div>
    </>
  )
}