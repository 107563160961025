import React, {useEffect} from 'react'
import Sidebar from '../sidebar/Sidebar';
import Header from '../header/header';
// import Footer from "../footer/footer";   

export default function Layout(props) {
useEffect(() => {
let url = window.location.href
},[])
  return (
    <>
      <Header />
      <Sidebar/>
      {/* <Sidebar/> */}
      <div className='body-content side-bar'>
      {props.children}
      </div>
      {/* <Footer /> */}
    </>
  );
}
