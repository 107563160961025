import { ethers } from "ethers";
import React, { useState } from "react";
import { useEffect } from "react";
import { formatUnits } from "viem";
import { PerkCategories } from "../../_constant";

export default function FairPorgressbar({ fundRaisedData, hardCap, softCap, token_decimals, custom_fund_token_decimal, isHardCap, fundRaisingTokenDecimal, fundRaisingTokenSymbol, fund_releasing_token, perk }) {

    const [progressBar, _progressBar] = useState(0)
    useEffect(() => {
        if (fundRaisedData) {
            if (isHardCap == 1 && hardCap != undefined) {
                const fundRaised = Number(formatUnits(fundRaisedData, fundRaisingTokenDecimal));
                const hardCap_format = Number(formatUnits(hardCap, custom_fund_token_decimal));
                const progress = (fundRaised * 100) / hardCap_format;
                _progressBar(progress)
            }
            else if (BigInt(fundRaisedData) > BigInt(softCap)) {  // eslint-disable-line no-undef
                const fundRaised = Number(formatUnits(fundRaisedData, fundRaisingTokenDecimal));
                const progress = (fundRaised * 100) / fundRaised;
                _progressBar(progress)
            } else {
                const fundRaised = Number(formatUnits(fundRaisedData, fundRaisingTokenDecimal));
                const softCap_format = Number(formatUnits(softCap, custom_fund_token_decimal));
                const progress = (fundRaised * 100) / softCap_format;
                _progressBar(progress)
            }
        }
    }, [fundRaisedData, hardCap, softCap])


    return (
        <div
            className="progress"
            style={{ height: 10 }}
        >
            {
                <>
                    <div
                        className={`progress-bar-${perk == PerkCategories.GOLD?'gold':perk==PerkCategories.DIAMOND?'diamond':perk == PerkCategories.PLATINUM?'platinum':'standard'}`}
                        style={{
                            width: `${progressBar}%`,
                            height: 10,
                        }}
                    />

                    {/* <div className="d-flex justify-content-between align-items-center">
                        <p>

                            {
                                fundRaisedData != undefined && ethers.utils.formatUnits(String(fundRaisedData), Number(fund_releasing_token == 'Custom' || fundRaisingTokenDecimal)) || 0
                            }
                            {" "}
                            {
                                fundRaisingTokenSymbol
                            }

                        </p>
                        <p>
                            {
                                isHardCap == 1
                                    ?
                                    Number(formatEther(hardCap))
                                    :
                                    fundRaisedData > softCap
                                        ?
                                        ethers.utils.formatUnits(
                                            String(fundRaisedData || 0),
                                            Number(fundRaisingTokenDecimal)
                                        )
                                        :
                                        ethers.utils.formatUnits(
                                            String(softCap || 0),
                                            Number(fundRaisingTokenDecimal)
                                        )
                            }
                            {" "}
                            {
                                fundRaisingTokenSymbol
                            }
                        </p>
                    </div> */}


                </>

            }

        </div>

    )
}