import { ethers } from "ethers";
import ReactApexChart from "react-apexcharts"
import { SALE_TYPE } from "../../_constant";
import { useEffect, useState } from "react";

export function TokenomicsPieChart({ presaleDetails }) {

  function abbreviateNumber(value) {
    

    const newValue = Math.abs(value) >= 1.0e+9
      ? (Math.abs(value) / 1.0e+9).toFixed(2) + "B"
      : Math.abs(value) >= 1.0e+6
        ? (Math.abs(value) / 1.0e+6).toFixed(2) + "M"
        : Math.abs(value) >= 1.0e+3
          ? (Math.abs(value) / 1.0e+3).toFixed(2) + "k"
          : Math.abs(value).toFixed(2);

    return newValue;
  }

  if (!presaleDetails) {
    return null;
  }

  const {
    hardCap,
    preSaleRate,
    custom_fund_token_decimal,
    token_decimal,
    saleType,
    preSaleAmount,
    preSaleRatePercentage,
    isTeamVesting,
    isPreSaleVesting,
    supply,
    vestingAmount,
    dexLiquidityPercentage
  } = presaleDetails;

  const hardCapBigInt = BigInt(hardCap);   // eslint-disable-line no-undef
  const rateBigInt = BigInt(preSaleRate);   // eslint-disable-line no-undef

  const amountActual =
    (hardCapBigInt * rateBigInt) / BigInt(10) ** BigInt(custom_fund_token_decimal); // eslint-disable-line no-undef

  const presaleAmountFormatted = ethers.utils.formatUnits(
    amountActual.toString(),
    token_decimal
  );

  // const totalFormatted = ethers.utils.formatUnits(Math.floor(supply || 0), token_decimal);

  const presaleAmountValue =
    saleType === SALE_TYPE.PRESALE
      ? presaleAmountFormatted
      : ethers.utils.formatUnits(String(preSaleAmount), Number(token_decimal || 0));



      let number = parseFloat(supply); // Convert string to float
      let roundedNumber = Math.floor(number); // Round down the number
      let roundedSupply = roundedNumber.toLocaleString('fullwide', {useGrouping:false});// Convert the number to a string without scientific notation
      

  const total = Number(ethers.utils.formatUnits(roundedSupply || 0, token_decimal));

  const Presale = saleType === SALE_TYPE.PRESALE
    ? presaleAmountFormatted
    : ethers.utils.formatUnits(String(preSaleAmount), Number(token_decimal || 0));

  const PresalePercent = saleType === SALE_TYPE.PRESALE
    ? Number((presaleAmountFormatted * 100) / total).toFixed(2)
    : Number((Number(ethers.utils.formatUnits(String(preSaleAmount), Number(token_decimal || 0))) * 100) / total).toFixed(2);


  let dynamicPresaleRatePer;

  if (saleType == SALE_TYPE.PRESALE) {
    const dexLiquidityPercentage1 = BigInt(presaleDetails?.dexLiquidityPercentage); // eslint-disable-line no-undef
    const dexListingRate = BigInt(presaleDetails?.dexListingRate); // eslint-disable-line no-undef
    const amountActualToLiq =
      (BigInt(hardCap) * BigInt(dexListingRate) * BigInt(dexLiquidityPercentage1)) /    // eslint-disable-line no-undef
      BigInt(10) ** BigInt(Number(custom_fund_token_decimal) + 2);  // eslint-disable-line no-undef

    const amountFormattedToLiq = ethers.utils.formatUnits(
      amountActualToLiq.toString(),
      Number(token_decimal)
    );
    dynamicPresaleRatePer = amountFormattedToLiq
  } else {
    const fundRaisingTokenDecimal = token_decimal;
    const fairLaunchTokenForLiq = ((BigInt(preSaleAmount) * BigInt(preSaleRatePercentage) * BigInt(dexLiquidityPercentage)) / BigInt(10000));     // eslint-disable-line no-undef
    const parsefFair = ethers.utils.formatUnits(String(BigInt(fairLaunchTokenForLiq)), Number(fundRaisingTokenDecimal || 0)).toString()  // eslint-disable-line no-undef
    dynamicPresaleRatePer = parsefFair
  }

  const Liquidity = dynamicPresaleRatePer;


  const LiquidityPercent = Number((Liquidity * 100) / total);
  

  const total_vesting_amount =
    Number(ethers.utils
      .formatUnits(String(vestingAmount), Number(token_decimal || 0)))

  const total_vesting_amount_percent = Number(total_vesting_amount * 100) / total;

  const Locked =
    (isTeamVesting == 1 && isPreSaleVesting == 1)
      ?
      Number(presaleAmountValue) + Number(ethers.utils.formatUnits(String(vestingAmount), Number(token_decimal || 0)))
      :
      isTeamVesting == 1 ?
        Number(ethers.utils.formatUnits(String(vestingAmount), Number(token_decimal || 0)))
        :
        isPreSaleVesting == 1 ? presaleAmountValue
          :
          0

  const LockedPercentage = (Locked * 100) / total;


  const useSupply = presaleDetails?.saleType == SALE_TYPE.PRESALE ? 
   presaleDetails?.launch_stealth_sale == 1 ? presaleDetails?.supply :
  (presaleDetails?.supply && presaleDetails?.token_decimal) &&
  ethers.utils.formatUnits(String(presaleDetails?.supply), Number(presaleDetails?.token_decimal))
    .toString()
  : presaleDetails?.supply


  const Unlocked = useSupply - (Number(presaleAmountValue) + Number(Liquidity) + Number(total_vesting_amount) + Number(Locked))

  
  const UnlockedPercentage = (Unlocked * 100) / total;

  


  return (
    // <div className="container d-flex justify-content-center">

    <ReactApexChart
    // height={'100%'}
    // width={'100%'}

      options={{
        colors: ['#377179', '#09adb3', '#219a15', '#a74af9',"#ff4843"],
        chart: {
          type: 'donut',
          foreColor: '#ffffff',
        },
        dataLabels: {
          enabled: false,
        },
        labels: ['Presale', 'Liquidity', 'Team Vesting', 'Locked', 'Unlocked'],
        dataLabels: {
          dropShadow: {
            blur: 3,
            opacity: 0.8
          }
        },
        responsive: [
          {
            breakpoint: 1189,
            options: {
              chart: {
                // width: 350,
                height:400
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 480,
            options: {
              chart: {
                // width: 350,
                height:400
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 320,
            options: {
              chart: {
                width: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        stroke: {
          width: 0,
        },
        states: {
          hover: {
            filter: 'none'
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  // formatter: function (w) {
                  //   // return w.globals.seriesTotals.reduce((a, b) => {
                  //   //   return a + b;
                  //   // }, 0);
                  //   return presaleDetails?.launch_stealth_sale == 1 ? presaleDetails?.supply :
                  //   (presaleDetails?.supply && presaleDetails?.token_decimal) &&
                  //   ethers.utils.formatUnits(String(presaleDetails?.supply), Number(presaleDetails?.token_decimal))
                  //     .toString()
                  // },
                  formatter: function (w) {
                    const totalValue = presaleDetails?.launch_stealth_sale === 1
                      ? presaleDetails?.supply
                      : (presaleDetails?.supply && presaleDetails?.token_decimal) &&
                      ethers.utils.formatUnits(String(presaleDetails?.supply), Number(presaleDetails?.token_decimal)).toString();
                      if(totalValue.length > 13){
                        return Number(totalValue).toExponential(0);
                      }else{
                        return abbreviateNumber(totalValue);
                      }
                  },

                  style: {
                    colors: '#fff', // Set label color to white
                  },
                },
                value:{
                  show:true,
                  fontFamily:'Rajdhani Medium', //want some bold comment this
                  fontWeight:'bold',
                  formatter:function(val){
                    const number = `${val < 0 ? '-' : ''}` + abbreviateNumber(val);
                    const formattedNumber = String(number).replace(/\.?0*$/, '');
                    if (formattedNumber.length > 13) {
                        return Number(val).toExponential(0);
                    }
                    return formattedNumber;
                  }
                }
              },
            },
          },
        },
        legend: {
          position: 'top',
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex }) {
              if (seriesIndex === 0) {
                // return abbreviateNumber(parseFloat(presaleAmountValue));
                return presaleAmountValue;
              } else if (seriesIndex === 1) {
                // return abbreviateNumber(parseFloat(Liquidity));
                return Liquidity;
              } else if (seriesIndex === 2) {
                // return abbreviateNumber(parseFloat(total_vesting_amount));
                return total_vesting_amount;
              } else if (seriesIndex === 3) {
                // return abbreviateNumber(parseFloat(Locked));
                return Locked
              } else {
                // return abbreviateNumber(parseFloat(Unlocked));
                return Unlocked;
              }
            },
          },
        },
      }}
      series={[Number(presaleAmountValue), Number(Liquidity), Number(total_vesting_amount), Number(Locked), Number(Unlocked.toFixed(2))]}
      type="donut"
    />
      // </div>
  )
}