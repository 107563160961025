import { SyncLoader } from "react-spinners";

export default function UpdateStack ({isLoadingUpdatePresale,isLoadingWFTUpdate}){
  return(
      <>
              <button type="submit" className="btn btn-warning btn-block mt-4 btn_managepool"
                          href="#modal"
          data-bs-toggle="modal"
          data-bs-target="#updatePoolModal"
          disabled={isLoadingUpdatePresale||isLoadingWFTUpdate}
        >{isLoadingUpdatePresale||isLoadingWFTUpdate ? <SyncLoader color={'#3498DB'} size={10} />  : 'Update'}</button>
      </>
  )
}