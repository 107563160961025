import React from "react"
import { useContractRead, useContractReads, useContractWrite, useNetwork, useToken, useWaitForTransaction } from "wagmi";
import PreSaleManager from "../../_constant/PreSaleManager.json";
import { PRESALE_MANAGER_ADDRESSESS, SALE_TYPE, SUPPORTED_NETWORKS, validateAddress } from "../../_constant";
import SimpleToken from "../../_constant/SimpleToken.json";
import { getAccount,getNetwork } from "@wagmi/core"
import { toast } from "react-toastify";
import * as Yup from "yup"
import { useFormik } from "formik";
import { ERRORMSGS, SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { constants, ethers } from "ethers";
import { extendLiquidityLockAction, finalizePoolAction, updateLiqAddsOnTimestampService, updateRatesAfterFinalizeAction } from "../../redux/apiActions/api.action";
import { calculateNeededPool , calculateNeedPoolFairLaunchPresale} from "../../utils/PoolNeed";
import Presale from "../../_constant/PreSale.json";
import FairLaunch from "../../_constant/FairLaunch.json";
import { SyncLoader } from "react-spinners";

export function FinalizePool({preSale,launch_stealth_sale,getPresaleFuncWithoutLoading,presaleDetails,refetchReadExtend,liquidityLockupDays,dataLiqidityAddsOn,refetchLiqidityAddsOn}){
  const { chain, chains } = useNetwork();
  const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network]

  const account = getAccount()
  
  let hardCap =ethers.utils.formatUnits(
    String(presaleDetails?.hardCap || 0),
    Number(
      presaleDetails?.fund_releasing_token ==
        "Custom" ||
        presaleDetails?.fund_releasing_token == "BUSD" || presaleDetails?.fund_releasing_token =="USDT"
        ? presaleDetails?.custom_fund_token_decimal
        : 18
    )
  )
  let presaleRate = (presaleDetails?.preSaleRate && presaleDetails?.token_decimal) &&
  ethers.utils.formatUnits(String(presaleDetails?.preSaleRate), Number(presaleDetails?.token_decimal)).toString()
  let dexListingRate = (presaleDetails?.dexListingRate && presaleDetails?.token_decimal) &&
  ethers.utils.formatUnits(String(presaleDetails?.dexListingRate), Number(presaleDetails?.token_decimal))
    .toString()
  let maxBuy = ethers.utils.formatUnits(String(presaleDetails?.maxBuy || 0), (presaleDetails?.fund_releasing_token === 'BNB' || presaleDetails?.fund_releasing_token==="MATIC") ? 18 : presaleDetails?.custom_fund_token_decimal || 0).toString()  
  const needPool = calculateNeededPool(hardCap,presaleDetails?.dexLiquidityPercentage,presaleRate,dexListingRate,maxBuy,presaleDetails?.isbonusSale,presaleDetails?.bonusReceivedPercentage,presaleDetails?.noOfBonusEligibleInvestors,presaleDetails?.vestingAmount)

  const needPoolFairLauch = calculateNeedPoolFairLaunchPresale(presaleDetails?.preSaleAmount,presaleDetails?.preSaleRatePercentage,presaleDetails?.dexLiquidityPercentage,presaleDetails?.vestingAmount,presaleDetails?.token_decimal,presaleDetails?.bonusReceivedPercentage,presaleDetails?.saleType)

  const formikApprove = useFormik({
    enableReinitialize: true,
    initialValues: {
        tokenAddress:""
    },
    validationSchema: Yup.object().shape({
        tokenAddress: Yup.string().required("Token Address is required!")
    }),
    onSubmit: async (data) => {
      try {
        approveToken()
      } catch (error) {
      } finally {
      }
    },
  });



  const { data: dataLiqidity, isError: isErrorLiqidityAddsOn, isLoading: isLoadingLiqAddsOn, refetch: refetchLiqAddsOn } = useContractRead({
    address: presaleDetails?.preSale,
    abi: presaleDetails?.saleType =='Presale'?Presale.abi:FairLaunch.abi,
    functionName: "liquidityAddedOn",
    // onSuccess: onSuccessLiqidityAddsOn,
    // onError: onErrorLiqidityAddsOn
  })




  const onFilalizeError = (error)=>{
    console.log("onFilalizeError",error)
    let errStr = error.toString().slice(0, 25)
    if (errStr === "TransactionExecutionError") {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
    }else if(error.toString().includes("WaitTillDexListTime")){
      toast.error(ERRORMSGS.WAITTILLDEXLISTINGTIME)
    }else if(error.toString().includes("CannotFinalizeAsTheFundRaisedIsLessThanSoftCap")){
      toast.error(ERRORMSGS.FUNDRAISEDLESSTHENSOFTCAP)
    }else if(error.toString().includes("PreSaleNotStarted")){
      toast.error(ERRORMSGS.PRESALENOTSTARTED)
    }else if(error.toString().includes("IDENTICAL_ADDRESSES")){
      toast.error(ERRORMSGS.IDENTICAL_ADDRESS)
    }else if(error.toString().includes("transfer amount exceeds balance")){
      toast.error(ERRORMSGS.TRANSFER_AMOUNT_EXCEED_BALANCE,{toastId:'tbebTid'})
    }else if(error.toString().includes("insufficient allowance")){
      toast.error("insufficient allowance",{toastId:'insuffAllTid'})
    }else if(error.toString().includes("ETH_TRANSFER_FAIL")){
      toast.error("Not able to finalize your presale. Please contract support team asap",{toastId:'canFP'})
    }
    else{
      toast.error("Not able to finalize your presale. Please contract support team asap!",{toastId:'smwwTid'})
    }
  }
  
  const onSuccessFilalize = async(data)=>{
  }

const {
  data: investDataNotEth,
  isLoading: isLoadingFilalizePool,
  write: filalizeThePool,
} = useContractWrite({
  address: PRESALE_MANAGER_ADDRESS,
  abi: PreSaleManager.abi,
  functionName:launch_stealth_sale==1 ? "finalizeStealthPreSale":'finalizePreSale',
  args: [preSale,formikApprove.values.tokenAddress],
  onError: onFilalizeError,
  onSuccess:onSuccessFilalize
});

const {
  data: investDataNotEthNotStealth,
  isLoading: isLoadingFinalizePoolNotStealth,
  write: filalizeThePoolNotStelath,
} = useContractWrite({
  address: PRESALE_MANAGER_ADDRESS,
  abi: PreSaleManager.abi,
  functionName:'finalizePreSale',
  args: [preSale],
  onError: onFilalizeError,
  onSuccess:onSuccessFilalize
});


const { data:data_Presale_Dex, isError:isErrorPresale_Dex, isLoading:isLoading_Presale_Dex,refetch:refetchPresale_Dex } = useContractReads({
  contracts: [
    {
      address: (SUPPORTED_NETWORKS.some(network => network.id === chain?.id))
       ? presaleDetails?.preSale
      : undefined,
      abi: FairLaunch.abi,
      functionName: 'currentPreSaleRate',
   },
    {
      address: (SUPPORTED_NETWORKS.some(network => network.id === chain?.id))
      ? presaleDetails?.preSale
      : undefined,
      abi: FairLaunch.abi,
      functionName: 'currentDEXRate',
    },
  ],
})

const onSuccessPresaleToken = (data)=>{
  // console.log("onSuccessPresaleToken",data)

}
const onTokenFetchError = async (err) => {
  // toast.error(err.message);
};


const { data: preSaleToken, isSuccess: isTokenFetched,isError:isErrorFetchToken,isLoading:isLoadingPresale,isFetching:isFetchingPresale } = useToken({
  address: formikApprove.values.tokenAddress,
  onError: onTokenFetchError,
  onSuccess:onSuccessPresaleToken,
});

const onSuccessWaitForTransactionInvested = async (receipt) => {
  let bodyData = {preSale:preSale}
  const response = await finalizePoolAction(bodyData)
  if(response){
    toast.success(SUCCESSMSGS.POOLFINALIZED_SUCCESS)
    const closeButton = document.getElementById('btn-close');
    closeButton.click();
    getPresaleFuncWithoutLoading()
    
    // TODO: call this api for update LiqAddsOnTimestamp
    const refetchedLiAddData =await refetchLiqAddsOn()
    console.log("refetchedLiAddData",refetchedLiAddData)
    const dataLiqidity = refetchedLiAddData?.data;
    let addsOnData = {preSale:preSale,presaleOwner:account.address,data:String(dataLiqidity)}
    await updateLiqAddsOnTimestampService(addsOnData)
    // dataLiqidity
  }

  let refetchedExtendData = await refetchReadExtend()
  refetchedExtendData= refetchedExtendData.data;
  
  let refetchedLiquidityAddson = await refetchLiqidityAddsOn()
  refetchedLiquidityAddson = refetchedLiquidityAddson.data;

  if (liquidityLockupDays != Number(refetchedExtendData) / 86400) {
    let bodyData = { address: account.address, preSale: preSale, days: Number(refetchedExtendData-refetchedLiquidityAddson) / 86400 }
    await extendLiquidityLockAction(bodyData)
  }


  if(presaleDetails?.saleType != SALE_TYPE.PRESALE){
    // /update-rates-affinalize
    let refetch_data_Presale_Dex = await refetchPresale_Dex()
    refetch_data_Presale_Dex= refetch_data_Presale_Dex?.data;
    let body = {}
    body.preSale = presaleDetails?.preSale;
    body.preSaleRate = String(refetch_data_Presale_Dex[0].result)
    body.dexListingRate = String(refetch_data_Presale_Dex[1].result)
    body.presaleAmount = String(ethers.utils.parseUnits(ethers.utils.formatEther(String(presaleDetails?.preSaleAmount)), preSaleToken?.decimals))
    body.name =preSaleToken?.name;
    body.symbol =preSaleToken?.symbol;
    body.supply =ethers.utils.formatUnits(String(preSaleToken?.totalSupply?.value||0), Number(preSaleToken?.decimals||0)).toString()
    body.token_decimal =preSaleToken?.decimals;

    if(presaleDetails?.isTeamVesting==1){
                body.vestingAmount = String(ethers.utils.parseUnits(ethers.utils.formatEther(String(presaleDetails?.vestingAmount)), preSaleToken?.decimals))
              }
              
    await updateRatesAfterFinalizeAction(body)
  }



}

const { data:investNotEth, isError: isErrorWaitForTransNotEth, isLoading: isLoadingWaitForTransNotEth } = useWaitForTransaction({
  hash: investDataNotEth?.hash,
  onSuccess: onSuccessWaitForTransactionInvested
})

const { data, isError: isErrorWaitForTrans, isLoading: isLoadingWaitForTrans } = useWaitForTransaction({
  hash: investDataNotEthNotStealth?.hash,
  onSuccess: onSuccessWaitForTransactionInvested
})




    // const filalizeThePool = ()=>{
        
    // }





        /// Setup for getting balance of connected wallet address.
        const { data: balanceOf } = useContractRead({
            /// PreSale token address
            // @ts-ignore
            address: formikApprove.values.tokenAddress,
            abi: SimpleToken.abi,
            functionName: "balanceOf",
            args: [account.address],
            onError: onTokenFetchError,
          });

          

            /// @dev Handle on metamask popup rejection.
  const onApproveError = async (error) => {
    let errStr = error.toString().slice(0,53)
    if(errStr === "TransactionExecutionError: User rejected the request."){
      toast.error(TRANSACTIONMSGS.REJECTEDAPPROVING)
    }else{
    toast.error('Failed in approving!');
    }
  
  };

  const onErrorAllowance = (error) => {
    // console.log("onErrorAllowance",error)
  
  }

  const onSuccessAllowance =(data)=>{
    // console.log("onSuccessAllowance data",data)
    //  setFormData({type:'approvedToken',value:formatEther(data)})
    //  setFormData({ type: "token_name", value: preSaleToken.name });
    //   setFormData({ type: "token_symbol", value: preSaleToken.symbol });
    //   setFormData({ type: "token_supply", value: String(preSaleToken.totalSupply.value) });
    //   setFormData({ type: "token_decimal", value: preSaleToken.decimals });
  }

  const { data:isAllowanceData, isError:isErrorAllowance, isLoading:isLoadingAllowance,refetch:refetchAllowance } = useContractRead({
    address: formikApprove.values.tokenAddress!=""?formikApprove.values.tokenAddress:undefined,
    abi: SimpleToken.abi,
    functionName: "allowance",
    args: [account.address, preSale],
    onSuccess:onSuccessAllowance,
    onError:onErrorAllowance
  })

  

  const onSuccessApprove =(data)=>{

    refetchAllowance()
    // setFormData({type:'approvedToken',value:formatEther(data)})
    }


    // Approve Token
    const {
        data: approvedData,
        write: approveToken,
        isLoading:isLoadingApprove,
        isSuccess: isApprovedSuccess,
      } = useContractWrite({
        /// PreSale token address
        // @ts-ignore
        address: formikApprove.values.tokenAddress,
        abi: SimpleToken.abi,
        functionName: "approve",
        args:[preSale,String(ethers.utils.parseUnits(String(presaleDetails?.saleType==SALE_TYPE.PRESALE? Number(needPool)?.toFixed(preSaleToken?.decimals||18): Number(needPoolFairLauch)?.toFixed(preSaleToken?.decimals||18)), preSaleToken?.decimals || 18))],
        onError: onApproveError,
        onSuccess:onSuccessApprove
      });

      
      const onSuccessWaitForTransaction = (data)=>{
        // console.log("onSuccessWaitForTransaction",data)
        }
          /// Any api call
        const onApproveReceipt = async (data, err) => {
            if (err) {
            // console.error("Err", err);
            } else {
            // Any API call or additional logic can be placed here
            toast.success(SUCCESSMSGS.APPROVED_SUCCESS)
              refetchAllowance()
            //   next()
            }
        };


      const {isLoading:isLoadingWaitForTranasaction} = useWaitForTransaction({
        hash: approvedData?.hash,
        onSettled: onApproveReceipt,
        onSuccess:onSuccessWaitForTransaction
      });


      const Total_Supply = presaleDetails?.launch_stealth_sale == 1 ? presaleDetails?.supply :
      (presaleDetails?.supply && presaleDetails?.token_decimal) &&
      ethers.utils.formatUnits(String(presaleDetails?.supply), Number(presaleDetails?.token_decimal))
        .toString()


        const filalizeThePoolBtn = ()=>{
          const closeButton = document.getElementById('btn-close');
          closeButton.click();
          filalizeThePool()
        }

    return(
        <>
        {
            launch_stealth_sale==1?
            <button className="btn btn btn-info btn-block mt-3 text-white btn_managepool" 
            href="#modal"
            data-bs-toggle="modal"
            data-bs-target="#ApproveModal"
    
            >{isLoadingFilalizePool || isLoadingWaitForTrans || isLoadingWaitForTransNotEth ?  <SyncLoader color={'#3498DB'} size={10} /> : 'Finalize The Pool'} </button>
            :
            <button className="btn btn btn-info btn-block mt-3 text-white btn_managepool"
            // href="#modal"
            // data-bs-toggle="modal"
            // data-bs-target="#ApproveModal"
            disabled={isLoadingFilalizePool||isLoadingFinalizePoolNotStealth || isLoadingWaitForTrans || isLoadingWaitForTransNotEth}
            onClick={filalizeThePoolNotStelath}
            >{isLoadingFilalizePool||isLoadingFinalizePoolNotStealth ||isLoadingWaitForTrans || isLoadingWaitForTransNotEth?<SyncLoader color={'#3498DB'} size={10} /> :'Finalize The Pool'}</button>

        }
   
        
        <div className="approve_module">
        <div className="modal fade " id="ApproveModal" tabindex="99999" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              {/* Modal Header */}
              <div className="modal-header">
                {/* <h5 className="text-center text-blue">Update Presale Information</h5> */}
                <button type="button" className="btn-close" data-bs-dismiss="modal" id="btn-close" hidden={false} />
              </div>
              {/* Modal body */}
              <div className="modal-body pr-4">
                <div id="myDropdown"
                  className="dropdown-content"
                >
                  <div className="row">
                    <div className="col-12">
                      <label className="fieldlabels form-label"
                      >Token</label>
                            <div className="approve_token_form">
                     
                      {/* <div class="input-group mb-3"> */}
                    <div className="contribute_amount_input">

                        <input
                          type="text"
                          // className="form-control"
                          className={((!validateAddress(formikApprove.values.tokenAddress) || isErrorAllowance) && formikApprove.values.tokenAddress !="" ) && 'input_invalid'}
                          name="tokenAddress"
                          placeholder="Token Address"
                          onChange={formikApprove.handleChange}
                          value={formikApprove.values.tokenAddress}
                          aria-describedby="basic-addon1"
                        />
                        {/* </div> */}
                      </div>
                      </div>

                      { (isLoadingPresale || isFetchingPresale) && !isErrorAllowance && validateAddress(formikApprove.values.tokenAddress) && 
                        <div className="mt-2">
                            <SyncLoader color="#3498DB" size={10}/>
                      </div>
                        }
                        
                      {formikApprove.errors.tokenAddress && formikApprove.touched.tokenAddress ? (
                        <span className="text-danger">{formikApprove.errors.tokenAddress}</span>
                      ) : null}
                      {
                        ((!validateAddress(formikApprove.values.tokenAddress) || isErrorAllowance) && formikApprove.values.tokenAddress !="" )&& <span className="text-danger">Invalid Token</span>
                      }
                    </div>


                    <div className="token_details_section_bottom">
                <hr />
                {/* hidden={Number(isAllowanceData)!=0} */}
                <section hidden={!preSaleToken}>
                  <label>Token Details</label>
                  <ul className="text-white mt-1">
                    <li className="d-flex flex-wrap align-items-center justify-content-between">
                      Name<span>{preSaleToken?.name}</span>
                    </li>
                    <li className="d-flex flex-wrap align-items-center justify-content-between">
                      Symbol<span>{preSaleToken?.symbol}</span>
                    </li>
                    <li className="d-flex flex-wrap align-items-center justify-content-between">
                      Total Supply
                      <span>
              {
               preSaleToken &&
               ethers.utils
                .formatUnits(String(preSaleToken?.totalSupply?.value||0), Number(preSaleToken?.decimals||0))
                .toString()
              }

                      </span>
                    </li>
                  </ul>
                  <hr />
                </section>
              </div>

                    <div style={{ overflow: "auto" }}>
                      <div className="d-flex gap-3 py-2 justify-content-center">
                        {
                          Number(Total_Supply)
                          > Number(ethers.utils
                            .formatUnits(String(preSaleToken?.totalSupply?.value || 0), Number(preSaleToken?.decimals || 0)))

                            ?
                             preSaleToken && <p className="text-danger fs-5 fw-bold">Token supply less than presale's total supply</p>
                            :

                          (Number(balanceOf) >= (presaleDetails.saleType==SALE_TYPE.PRESALE?needPool: needPoolFairLauch)) ?
                            Number(ethers.utils
                                .formatUnits(String(isAllowanceData||0), Number(preSaleToken?.decimals||0))
                                .toString()) < Number(presaleDetails?.saleType== SALE_TYPE.PRESALE?needPool:needPoolFairLauch)
                                ?

                        <button
                          type="submit"
                          // id="nextBtn"
                          style={{width:"100px"}}
                          className="btn btn-primary m-0"
                          onClick={(event) => {
                            event.preventDefault();
                            formikApprove.handleSubmit();
                          }}
                          disabled={isLoadingApprove||isLoadingWaitForTranasaction}
                        >
                            {isLoadingApprove||isLoadingWaitForTranasaction ?'Approving...':'Approve'}
                        </button>
                        :
                        <button
                        type="submit"
                        id="nextBtn"
                        className="btn btn-primary m-0"
                        style={{width:"100px"}}
                        // onClick={(event) => {
                        //   event.preventDefault();
                        //   formikApprove.handleSubmit();
                        // }}
                        disabled={isLoadingFilalizePool || isLoadingWaitForTrans || isLoadingWaitForTransNotEth }
                        onClick={filalizeThePoolBtn}
                      >
                        {isLoadingFilalizePool || isLoadingWaitForTrans || isLoadingWaitForTransNotEth?'Finalizing...':'Next'}
                      </button>

                          :
                          preSaleToken &&
                          <p className="text-danger fs-5 fw-bold">Not enough balance</p>
                        }
                        
                      </div>
                    </div>


                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}