import { useFormik } from "formik";
import * as Yup from 'yup';
import { getAccount,getNetwork } from "@wagmi/core"
import { updateAuditAction } from "../../redux/apiActions/api.action";
import { useState } from "react";
import { toast } from "react-toastify";
import { SyncLoader } from "react-spinners";

const Audit = ({preSale})=>{

  const account = getAccount()
    

    const validationSchema = Yup.object().shape({
        audit: Yup.string().url('Not a url'),
      });

      const [submitting,_submitting] = useState(false)

      const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            audit:"",
          // burnTokens:formData?.burnTokens
        },
        validationSchema: validationSchema,
    
        onSubmit: async (data) => {
            try{
              if(data.audit==""){
                toast.error('Audit link is required!',{toastId:'adlrdTid'})
              }else{
                _submitting(true)
                let bodyData = {preSale:preSale,owner:account?.address,audit:data.audit}
                let resp = await updateAuditAction(bodyData)
                if(resp){
                    toast.success('Updated!',{toastId:'audIpdTid'})
                }
              }
            }catch(err){
            }finally{
                _submitting(false)
                formik.resetForm({})
            }
   
        },
      });

    return(
        <>
          <div className="form-group mt-4">
            <label htmlFor="liquidity-lock form-label">Audit Link</label>
            <div className="contribute_amount_input">
            <input type="text"
              className={`${formik.errors.audit && formik.touched.audit && 'invalid'}`}
              id="liquidity-lock"
              name="audit"
              onChange={formik.handleChange}
              value={formik.values.audit}
            />
                            {formik.errors.audit && formik.touched.audit ? (
            <span className="text-danger">{formik.errors.audit}</span>
          ) : null}
            </div>
          </div>
          <button
                className="btn btn-info btn-block mt-3 modal-open btn_managepool"
                disabled={submitting}
                onClick={formik.handleSubmit}
              >{submitting? <SyncLoader color={'#3498DB'} size={10} /> :'Audit'}</button>

        </>
    )
}

export default Audit;