import React, { useEffect, useState } from "react";
import { getHeighlightedPresalesAction } from "../../redux/apiActions/api.action";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../_constant";

const Trending = () => {
  const [presales, setPresales] = useState([]);

  const getPresales = async () => {
    const data = await getHeighlightedPresalesAction();
    setPresales(data?.data?.data);
  };

  useEffect(() => {
    getPresales();
  }, []);

  const navigate = useNavigate();

  return (
    <div className="row">
      <div className="runing_bar d-flex align-items-center">
        <div className="runing_bar_left d-flex align-items-center">
          <p className="d-none d-md-block">Spotlight</p>
          {
            presales?.length > 0 && 
          <p className="d-flex align-items-center" style={{cursor:'pointer'}}  onClick={() => {
            navigate(`${BASE_URL}presale?presale_id=${presales[0]?.preSale}&chainId=${presales[0]?.chain}`);
          }}>
            <img src={presales[0]?.logoUrl} className="img-fluid me-md-2" alt="icon" style={{ borderRadius: "50%" }} />{" "}
            <span className="d-none d-md-block">{presales[0]?.token_symbol}</span>
          </p>
          }
        </div>
        <div className="runing_bar_right d-flex justify-content-between align-items-center breaking-news ">
          <div className="d-flex flex-row flex-grow-1 flex-fill news">
            <span className="d-flex align-items-center">
              <img src={BASE_URL+`images/icon/hot.png`} className="img-fluid me-2" alt="icon" /> Trending
            </span>
          </div>
          <marquee
            className="news-scroll"
            behavior="scroll"
            direction="left"
            onmouseover="this.stop();"
            onmouseout="this.start();"
            scrollamount={4}
            onMouseEnter={() => {
                const marquee = document.querySelector(".news-scroll");
                marquee.stop();
              }}
              onMouseLeave={() => {
                const marquee = document.querySelector(".news-scroll");
                marquee.start();
              }}
                
          >
            <ul className="d-flex align-items-center">
              {presales?.map((presale, index) => (
                <li
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`${BASE_URL}presale?presale_id=${presale?.preSale}&chainId=${presale?.chain}`);
                  }}
                >
                  <span>#{index + 1}</span> {presale?.token_symbol}
                  <img
                    src={presale?.logoUrl}
                    className="img-fluid me-2"
                    alt="icon"
                    style={{ borderRadius: "50%", marginLeft: "10px" }}
                  />{" "}
                </li>
              ))}
            </ul>
          </marquee>
        </div>
      </div>
    </div>
  );
};

export default Trending;
