import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../_constant";
import { subsDetailsAction } from "../../redux/apiActions/api.action";
import { getAccount, getNetwork } from "@wagmi/core"
import moment from "moment";
import { SyncLoader } from "react-spinners";
import { useAccount } from "wagmi";

export default function SubCashback() {
    const account = getAccount()
    const { address } = useAccount()

    const [cashback,setCashback] = useState(0)
    const [subscriptions,setSubscriptions] = useState([])
    const [totalSubs,_totaSubs] = useState(0)
    const [loading,setLoading] = useState(false)
    const getSubsDetails = async()=>{
        try{
            setLoading(true)
            const bodyData={address :account?.address}
            const {data} =await subsDetailsAction(bodyData)
            setCashback(data?.data?.total_cashback)
            setSubscriptions(data?.data?.subscriptions)
            _totaSubs(data?.data?.total_subscriptions)
        }catch(err){

        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        getSubsDetails()
    },[address])

    const navigate = useNavigate()
    return (
        <>
            <div className="container-fluid">

            {loading ? (
             <div className="d-flex justify-content-center align-items-center vh-100">
             <SyncLoader color="#3498DB" />
           </div>
        )
        :
                <div className="row">
                    <div className="col-xxl-9 col-lg-10 col-md-12 mx-auto">
                        <div className="row mt-5">
                            <div id="approve_token_form">
                                <div className="row">
                                    <div className="col-md-12 mx-auto">
                                        <div className="row">
                                            <div className="col-md-12 mx-auto">
                                                <div className="approve_token_section">
                                                    <h3 className="text-center">Subscriptions & Cashback</h3>

                                                    <div className="mt-2">

                                                        <div className="row row-cols-1 row-cols-md-3 gap-4 justify-content-center mx-0">
                                                            <div className="col approve_token_card d-flex flex-wrap justify-content-between">
                                                                <div className="text-start">
                                                                    <h3>${totalSubs || "0.00"}</h3>
                                                                    <span className="mt-2">Total Subscriptions</span>
                                                                </div>
                                                                <a href="#">
                                                                    <img src="images/copy_icon.png" alt="Total Subscription" />
                                                                </a>
                                                            </div>

                                                            <div className="col approve_token_card d-flex flex-wrap justify-content-between">
                                                                <div className="text-start">
                                                                    <h3>{cashback || "0.00"}</h3>
                                                                    <span className="mt-2">Total Cashback</span>
                                                                </div>
                                                                <a href="#">
                                                                    <img src="images/total_cashback.png" alt="Total Cashback" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="approve_token_card mt-4 text-center p-md-4 p-3">

                                                        <div className="row row-cols-1">
                                                            <div className="col mx-auto">
                                                                <h5 className="text-white">Summary</h5>
                                                            </div>

                                                            <div className="col-sm-12 col-md-12 mt-3">
                                                                <div class="table-responsive">
                                                                    <table class="table subscriptions_Cashback_view_table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="text-start">Date</th>
                                                                                <th>Subscription Amount</th>
                                                                                <th>Valid Till</th>
                                                                                <th className="text-end">Cashback</th>
                                                                                <th className="text-end">View</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody className="mt-3">
                                                                            {
                                                                                subscriptions?.length > 0 ?
                                                                                subscriptions?.map((dta)=>(
                                                                                        <tr>
                                                                                            <td className="text-start">{moment(dta?.created_date).format('DD-MM-YYYY')}</td>
                                                                                            <td>${dta?.price}</td>
                                                                                            <td>{moment(dta?.valid_date).format('DD-MM-YYYY')}</td>
                                                                                            <td className="text-end">{dta?.cashback}.00</td>
                                                                                            <td className="text-end">
                                                                                            <p onClick={()=>{navigate(BASE_URL+ `subs-details?id=${dta?.id}`)}} style={{color:'#3498DB',textDecoration:'underline',cursor:'pointer'}}>
                                                                                            View
                                                                                        </p>

                                                                                            </td>
                                                                                        </tr>
                                                                                ))
                                                                                :
                                                                                <tr>
                                                                                <td colSpan="5" className="text-center" style={{ color: 'white' }}>
                                                                                    No record found
                                                                                </td>
                                                                                 </tr>
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
            </div>
        </>
    )
}
