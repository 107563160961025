import { useContractWrite, useWaitForTransaction } from "wagmi";
import { TRANSACTIONMSGS } from "../../utils/messages";
import { toast } from "react-toastify";
import { getAccount, getNetwork } from "@wagmi/core"
import { STAKE_MANAGER } from "../../_constant";
import StackingManagerABI from "../../_constant/StakingManager.json";

export default function WithdrawStake ({stakeDetails}){

    // withdrawRewards
    const { chain, chains } = getNetwork()
   const STACK_MANAGER_ADDRESS = STAKE_MANAGER[chain?.network]


    const onErrorDispSameTokToAdd = (error)=>{
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }
        else {
           toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
        }
    }


    console.log("stakeDetails?.stakeContract",stakeDetails?.stakeContract)
    const {
        data: dataWithDrawReward,
        write: withdrawReward,
        isLoading: isLoadingDispSameTokToAdd,
        isSuccess: isSuccessDispSameTokToAdd,
      } = useContractWrite({
        address: STACK_MANAGER_ADDRESS,
        abi: StackingManagerABI.abi,
        args: [stakeDetails?.stakeContract],
        functionName:'withdrawRewards',
        onError: onErrorDispSameTokToAdd,
      });

      const onSuccessWFTDepositeReward = ()=>{
            toast.success("Withdrawal!")
            // Api call 
      }

      const {isLoading:isLoadingWFTWithdrawReward} = useWaitForTransaction({
        hash: dataWithDrawReward?.hash,
        // onSettled: onApproveReceipt,
        onSuccess:onSuccessWFTDepositeReward
      });

    return(
        <>
                <button type="submit" className="btn btn-primary btn-block mt-4 btn_managepool"
            onClick={()=>{withdrawReward()}}
            // disabled={isLoadingUpdatePresale||isLoadingWFTUpdate}
          >
            Withdraw Reward
            </button>
        </>
    )
}