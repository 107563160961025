// import React, { memo, useEffect, useState } from "react";
// import Countdown from "react-countdown";
// import moment from "moment-timezone";
// import _ from "lodash";

// const AuctionTime = ({ auction_End_Time, isArtWorkDetails = false }) => {
//   const [auctionEndTime, setAuctionEndTime] = useState({
//     D: "",
//     H: "",
//     M: "",
//     S: "",
//   });

//   let intervalTime;

//   useEffect(() => {
//     let auctionTimeOut;
//     intervalTime = setInterval(() => {
//       const data = updateAuctionTime();

//       if (data) {
//         auctionTimeOut = setTimeout(() => {
//         }, 2000);

//         clearInterval(intervalTime);
//       }
//     }, 1000);

//     return () => {
//       clearInterval(intervalTime);
//       setAuctionEndTime(null);
//     };
//   }, []);

// //   const updateAuctionTime = () => {
// //     const endTime = moment.unix(auction_End_Time);
// //     const duration = moment.duration(endTime.diff(moment()));
// //     if (duration._milliseconds < 0) {
// //       clearInterval(intervalTime);
// //     }
// //     const D = duration.days() > 0 ? duration.days() : 0;
// //     const H = duration.hours() > 0 ? duration.hours() : 0;
// //     const M = duration.minutes() > 0 ? duration.minutes() : 0;
// //     const S = duration.seconds() > 0 ? duration.seconds() : 0;

// //     if (
// //       duration.asHours() <= 0 &&
// //       duration?.minutes() <= 0 &&
// //       duration.seconds() <= 0
// //     ) {
// //       return true;
// //     } else {
// //       setAuctionEndTime({
// //         D: D,
// //         H: H,
// //         M: M,
// //         S: S,
// //       });
// //       return false;
// //     }
// //   };

// const updateAuctionTime = () => {
//     const endTime = moment.unix(auction_End_Time);
//     const duration = moment.duration(endTime.diff(moment()));

//     if (duration._milliseconds < 0) {
//       clearInterval(intervalTime);
//     }

//     const D = duration.days() > 0 ? duration.days() : 0;
//     const H = duration.hours() > 0 ? duration.hours() : 0;
//     const M = duration.minutes() > 0 ? duration.minutes() : 0;
//     const S = duration.seconds() > 0 ? duration.seconds() : 0;

//     if (duration.asMilliseconds() <= 0) {
//       clearInterval(intervalTime);
//       setAuctionEndTime({
//         D: 0,
//         H: 0,
//         M: 0,
//         S: 0,
//       });
//       return true;
//     } else {
//       setAuctionEndTime({
//         D: D,
//         H: H,
//         M: M,
//         S: S,
//       });
//       return false;
//     }
//   };


//   const renderer = ({ hours, minutes, seconds, completed }) => {
//     console.log("completed",completed)
//     if (completed) {
//       return <Completionist />;
//     } else {
//       return (
//         <span>
//           {auctionEndTime?.D}D : {auctionEndTime?.H}H : {auctionEndTime?.M}M :{" "}
//           {auctionEndTime?.S} S
//         </span>
//       );
//     }
//   };

//   const Completionist = () => <span>CountDown Finish!</span>;

//   return (
//     <>
//       <>
//         <Countdown date={Date.now() + 343334000} renderer={renderer} />
//       </>
//     </>
//   );
// };

// export const AuctionMemoized = memo(AuctionTime, (pp, np) => _.isEqual(pp, np));


import React, { useState, useEffect } from "react";

const Countdown = ({ unixTime }) => {

  const [countdown, setCountdown] = useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      const difference = unixTime - now;
      if (difference < 0) {
        clearInterval(intervalId);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      const days = Math.floor(difference / (24 * 60 * 60));
      const hours = Math.floor((difference % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((difference % (60 * 60)) / 60);
      const seconds = Math.floor(difference % 60);

      setCountdown({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [unixTime]);

  if (
    countdown.days === null &&
    countdown.hours === null &&
    countdown.minutes === null &&
    countdown.seconds === null
  ) {
   
    return <div>00:00:00:00</div>;
  } else if (
    countdown.days === 0 &&
    countdown.hours === 0 &&
    countdown.minutes === 0 &&
    countdown.seconds === 0

  ) {
    // console.log("expired")
    // if (setSaleStatus) {
    //   setSaleStatus(Math.floor(Math.random() * 17))
    // }
    return <div>
      Sale Ended
    </div>;
  }


  return (
    <ul className="d-flex align-items-center gap-1">
      <li>{countdown.days} D</li>
      <li>:</li>
      <li>{countdown.hours} H</li>
      <li>:</li>
      <li>{countdown.minutes} M</li>
      <li>:</li>
      <li>{countdown.seconds} S</li>
    </ul>
  );
};

export default Countdown;
