import * as Yup from "yup"

const LIMIT_SOFTCAP=0.0005;

const validateAddress = (address) => {
  const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
  return addressRegex.test(address);
};

export const presaleFairLaunchInfoSchema = Yup.object().shape({
  pre_sale_amount: Yup.number().min(0.0005, 'Presale amount should be equal or more than 0!').lessThan(1e+59, "Presale amount should be less than or equal to 1e59").required("Presale amount is required"),
  dex_listing_rate: Yup.number().integer('Dex listing/presale rate(%) should be an integer')
  .min(1, 'Dex listing/presale(%) should be equal or more than 1').max(100,'Dex listing/presale rate(%) equal or less than 100%').required("Dex listing/presale rate(%) is required"),
  dex_liquidity: Yup.number().integer("Liqidity should be integer!").moreThan(50, "Liquidity is more than 50%").lessThan(100, "Liquidity is equal or less than 100%").required('Liquidity amount is required'),
  liquidity_lookup: Yup.number().integer("Lock up days should be integer!").min(30, "Lock up days should be 30 days as minimum").required("Liquidity lock up is required").lessThan(1e+59, "Liquidity lock up should be less than or equal to 1e59"),
  soft_cap: Yup.number().positive("Soft cap must be a positive number").required("Soft cap is required").min(LIMIT_SOFTCAP,`Soft cap should be minimum ${LIMIT_SOFTCAP}`).lessThan(1e+59, "Soft cap should be less than or equal to 1e59"),
  // hard_cap: Yup.number().lessThan(1e+59, "hard Cap should be less then or equal to 1e59").moreThan(Yup.ref('soft_cap'), "Hard Cap is more than Soft Cap").required("Hard Cap is required").test('is-hard-cap', 'Soft Cap is equal or more than Hard Cap / 2', function (value) {
  //   const softCap = this.resolve(Yup.ref('soft_cap'));
  //   return value === softCap * 2;
  // }),
  isHardCap:Yup.boolean(),
  hard_cap: Yup.number().test("hard-cap-validation", "Hard cap is required", function (value) {
    const isHardCap = this.resolve(Yup.ref('isHardCap'));

    if (isHardCap) {
      const softCap = this.resolve(Yup.ref('soft_cap'));

      if (value === undefined) {
        return this.createError({
          message: "Hard cap is required",
          path: 'hard_cap',
        });
      }

      if (value <= 0 || value > 1e+59) {
        return this.createError({
          message: "Hard cap should be between 0 and 1e59",
          path: 'hard_cap',
        });
      }

      if (value <= softCap) {
        return this.createError({
          message: "Hard cap should be more than soft cap",
          path: 'hard_cap',
        });
      }

    }

    return true; // Field is valid if isHardCap is false
  }),
//   minimum_buy: Yup.number()
//     .required("Min Buy is required").lessThan(1e+59, "Mimimum Buy should be less then or equal to 1e59")
//     .lessThan(Yup.ref('hard_cap'), "Min Buy is less than Hard Cap"),
  // maximum_buy: Yup.number()
  //   .required("Max Buy is required").lessThan(1e+59, "Mimimum Buy should be less then or equal to 1e59")
  //   .min(
  //     Yup.ref('minimum_buy'),
  //     "Max Buy should be greater than Min Buy"
  //   )
  //   .lessThan(Yup.ref('hard_cap'), "Max Buy is less than Hard Cap")
  //   .test('max-buy-greater-than-min-buy', 'Max Buy should be greater than Min Buy', function (value) {
  //     const minimumBuy = this.resolve(Yup.ref('minimum_buy'));
  //     if (minimumBuy && value && value <= minimumBuy) {
  //       return false;
  //     }
  //     return true;
  //   }),
  maximum_buy: Yup.number().test("max-buy-validation", function (value) {
    const isMaxBuy = this.resolve(Yup.ref('isMaxBuy'));
    const isHardCap = this.resolve(Yup.ref('isHardCap'));


    if (isMaxBuy) {
      const minimumBuy = this.resolve(Yup.ref('minimum_buy'));
      const hardCap = this.resolve(Yup.ref('hard_cap'));

      if (value === undefined) {
        return this.createError({
          message: "Max buy is required",
          path: 'maximum_buy',
        });
      }

      if (value <= 0 || value > 1e+59) {
        return this.createError({
          message: "Max buy should be between 0 and 1e59",
          path: 'maximum_buy',
        });
      }

      if (value <= minimumBuy) {
        return this.createError({
          message: "Max buy should be greater than min buy",
          path: 'maximum_buy',
        });
      }

      if (value > hardCap && isHardCap) {
        return this.createError({
          message: "Max buy is less than or equal to hard Cap",
          path: 'maximum_buy',
        });
      }
    }

    return true; // Field is valid if isHardCap is false
  }),

  whitelist_timer: Yup.number().integer("whitelist timer should be integer!").positive("Whitelist timer should be integer!").max(1440, "Max timer is 1440 minutes").optional(),
  // whitelist_timer:Yup.number().required("whitelist timer is required").positive("whitelist timer should be integer!").max(1440,"Max Timer is 1440 minutes"),
  // Bonus
  bonus_sale: Yup.boolean(),
  min_bonus_buy_amount: Yup.number()
    .test('min_bonus_buy_amount', 'Min amount for bonus is required!', function (value) {
      const addTeamTokenVesting = this.resolve(Yup.ref('bonus_sale'));
      if (addTeamTokenVesting === true) {
        const validationSchema = Yup.number()
          .required('Min amount for bonus is required!')
          .moreThan(0, 'Amount should be greater than 0')
        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'min_bonus_buy_amount');
        }
      }
      return true;
    }).test('min-bonus-buy-amount', 'Amount should be less or equal of  maxBuy', function (value) {
    const addTeamTokenVesting = this.resolve(Yup.ref('bonus_sale'));
    const isMaxBuy = this.resolve(Yup.ref('isMaxBuy'));
    if (addTeamTokenVesting === true && isMaxBuy === true) {
      const maxBuy = this.parent.maximum_buy;
      return value <= maxBuy;
    }
    return true;
  }).test('min-bonus-buy-amount', 'Amount should be less or equal of  hardCap', function (value) {
      const addTeamTokenVesting = this.resolve(Yup.ref('bonus_sale'));
      const isMaxBuy = this.resolve(Yup.ref('isHardCap'));
      if (addTeamTokenVesting === true && isMaxBuy === true) {
        const maxBuy = this.parent.hard_cap;
        return value <= maxBuy;
      }
      return true;
    }),
  // min_bonus_buy_amount: Yup.number().test('min-bonus-buy-amount', 'Amount should be Less or equal of  hardCap', function (value) {
  //   const addTeamTokenVesting = this.resolve(Yup.ref('bonus_sale'));
  //   const isMaxBuy = this.resolve(Yup.ref('isHardCap'));
  //   if (addTeamTokenVesting === true && isMaxBuy === true) {
  //     const maxBuy = this.parent.maximum_buy;
  //     return value <= maxBuy;
  //   }
  //   return true;
  // }),
  bonus_received: Yup.number()
    .test('bonus_received', 'bonus % required!', function (value) {
      const addTeamTokenVesting = this.resolve(Yup.ref('bonus_sale'));
      if (addTeamTokenVesting === true) {
        const validationSchema = Yup.number()
          .required('bonus % required!').integer("Bonus should be integer")
          .moreThan(0, 'Bonus percent should be greater than 0').max(100,'Bonus percent shoudld be less than 100')
        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'bonus_received');
        }
      }
      return true;
    }),

  bonus_spots_available: Yup.number()
    .test('bonus_spots_available', 'Bonus number is required!', function (value) {
      const addTeamTokenVesting = this.resolve(Yup.ref('bonus_sale'));
      if (addTeamTokenVesting === true) {
        const validationSchema = Yup.number()
          .moreThan(0, 'Bonus percent should be greater than 0').max(100,'Bonus percent shoudld be less than or equal 100')
          .moreThan(0, 'Number should be greater than 0')
        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'bonus_spots_available');
        }
      }
      return true;
    }),

  stealth_wallet: Yup.string().test('stealth_wallet', 'stealth wallet is required!', function (value) {
    const launch_stealth_sale = this.resolve(Yup.ref('launch_stealth_sale'));
    if (launch_stealth_sale === true) {
      const validationSchema = Yup.string()
        .required('Stealth wallet address is required!').test('stealth_wallet-test', 'Invalid stealth wallet address', (address) => {
          return validateAddress(address);
        })
      try {
        validationSchema.validateSync(value);
      } catch (error) {
        throw new Yup.ValidationError(error.message, value, 'stealth_wallet');
      }
    }
    return true;
  }),
  // Yup.string().optional().test('stealth_wallet-test', 'Invalid stealthWallet', (address) => {
  //   return validateAddress(address);
  // }),

  // End Bonus

  // Start Add Team Token Vesting
  add_team_token_vesting: Yup.boolean(),
  token_team_vesting_tokens: Yup.number()
    .test('token_team_vesting_tokens', 'Team vesting amount is required!', function (value) {
      const addTeamTokenVesting = this.resolve(Yup.ref('add_team_token_vesting'));
      if (addTeamTokenVesting === true) {
        const validationSchema = Yup.number()
          .required('Team vesting amount is required!').integer("Team vesting amount should be integer!")
          .moreThan(0, 'Team vesting amount must be greater than 0').max(9007199254740991, 'Total amount needed should not be over than 9007199254740991!')
        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'token_team_vesting_tokens');
        }
      }
      return true;
    }),
  first_token_release: Yup.number()
    .test('first_token_release', 'First token release is required!', function (value) {
      const addTeamTokenVesting = this.resolve(Yup.ref('add_team_token_vesting'));
      if (addTeamTokenVesting === true) {
        const validationSchema = Yup.number()
          .required('First token release is required!').integer("First token release should be integer!")
          .min(1, 'First released amount should be > 0')
        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'first_token_release');
        }
      }
      return true;
    }),

  first_token_release_after_listing: Yup.number()
    .test('first_token_release_after_listing', 'First token release after listing is required!', function (value) {
      const addTeamTokenVesting = this.resolve(Yup.ref('add_team_token_vesting'));
      if (addTeamTokenVesting === true) {
        const validationSchema = Yup.number()
          .required('First released time is required!').integer("First token release after listing should be integer!")
          .min(3, 'First released time should not be less than 3 days')
        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'first_token_release_after_listing');
        }
      }
      return true;
    }),

  team_token_released_each_cycle: Yup.number()
    .test('team_token_released_each_cycle', 'Team token released each cycle is required!', function (value) {
      const addTeamTokenVesting = this.resolve(Yup.ref('add_team_token_vesting'));
      if (addTeamTokenVesting === true) {
        const validationSchema = Yup.number()
          .required('Each released amount is required').integer("Each released amount should be integer!")
          .moreThan(0, 'Each released amount should be  > 0')
          .lessThan(100, 'Each released amount should be < 100');
        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'team_token_released_each_cycle');
        }
      }
      return true;
    }),

  vesting_period_each_cycle: Yup.number()
    .test('vesting_period_each_cycle', 'Vesting period each cycle is required!', function (value) {
      const addTeamTokenVesting = this.resolve(Yup.ref('add_team_token_vesting'));
      if (addTeamTokenVesting === true) {
        const validationSchema = Yup.number()
          .required('Each released time is required').integer("Vesting period each cycle should be integer!")
          .moreThan(0, 'Each released time should be > 0')
        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'vesting_period_each_cycle');
        }
      }
      return true;
    }),


  // End Add Team Token Vesting


  // Add Presale Vesting
  add_presale_vesting: Yup.boolean(),
  first_token_release_presale: Yup.number()
    .test('first_token_release_presale', 'First token release presale days is invalid', function (value) {
      const addPresaleVesting = this.resolve(Yup.ref('add_presale_vesting'));
      if (addPresaleVesting === true) {
        const validationSchema = Yup.number()
          .required("First released amount is required").integer("First token release presale should be integer!")
          .min(1, "First released amount is required")
        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'first_token_release_presale');
        }
      }
      return true;
    }),

  cliff: Yup.number()
    .test('cliff', 'Cliff days is invalid', function (value) {
      const addPresaleVesting = this.resolve(Yup.ref('add_presale_vesting'));
      if (addPresaleVesting === true) {
        const validationSchema = Yup.number().integer("Cliff should be only integer")
          .required('Cliff is required')
          .moreThan(0, 'Cliff should be more than 0 day')
          .max(365, 'Cliff should be less than 365 days');

        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'cliff');
        }
      }
      return true;
    }),

  vesting_period_each_cycle_presale: Yup.number()
    .test('vesting_period_each_cycle_presale', 'Vesting period each cycle presale days is invalid', function (value) {
      const addPresaleVesting = this.resolve(Yup.ref('add_presale_vesting'));
      if (addPresaleVesting === true) {
        const validationSchema = Yup.number()
          .required('Vesting period each cycle presale is required').integer("Vesting period each cycle presale should be integer!")
          .min(1, 'Each period should not be less than 1 day')

        try {
          validationSchema.validateSync(value);
        } catch (error) {
          throw new Yup.ValidationError(error.message, value, 'vesting_period_each_cycle_presale');
        }
      }
      return true;
    }),

  toke_released_each_cycle_presale: Yup.number().test("toke_released_each_cycle_presale", "Token released each cycle presale is invalid", function (value) {
    const addPresaleVesting = this.resolve(Yup.ref('add_presale_vesting'));
    const firstTokenReleasePresale = this.resolve(Yup.ref('first_token_release_presale'));
    if (addPresaleVesting === true) {
      const validationSchema = Yup.number()
        .required("Token released each cycle presale is required").integer("Token released each cycle presale should be integer!")
        .min(1, 'Token released each cycle presale should be more than 0').test(
          "total_presale_tokens",
          "First + each released amount should not more than 100%",
          function (value) {
            const total = firstTokenReleasePresale + value;
            return total <= 100;
          }
        );

      try {
        validationSchema.validateSync(value);
      } catch (error) {
        throw new Yup.ValidationError(error.message, value, 'toke_released_each_cycle_presale');
      }
    }
    return true;
  })

});


export const projectInfoSchema = Yup.object().shape({
  logo_url: Yup.string()
    .url('Not a url')
    .required('Logo is required'),

  website_url: Yup.string()
    .url('Not a url').optional(),

  twitter: Yup.string()
    .url('Not a url').optional(),

  github: Yup.string()
    .url('Not a url').optional(),

  telegram: Yup.string()
    .url('Not a url').optional(),

  discord: Yup.string()
    .url('Not a url').optional(),

  youtube: Yup.string()
    .url('Not a url').optional(),

  whitelist_link: Yup.string()
    .url('Not a url').optional(),

  description: Yup.string()
    .required("Description is required"),

  bannerUrl: Yup.string()
    .url('Not a url').optional(),

  tier: Yup.string().required('Please select a tier'),

});


export const updateProjectDetailsSchema = Yup.object().shape({
  logoUrl: Yup.string()
    .url('Not a url')
    .required('Logo is required'),

  websiteUrl: Yup.string()
    .url('Not a url').optional(),

  twitterUrl: Yup.string()
    .url('Not a url').optional(),

  githubUrl: Yup.string()
    .url('Not a url').optional(),

  telegramUrl: Yup.string()
    .url('Not a url').optional(),

  discordUrl: Yup.string()
    .url('Not a url').optional(),

  youtubePresentationVideoUrl: Yup.string()
    .url('Not a url').optional(),

  whitelistContestUrl: Yup.string()
    .url('Not a url').optional(),

  projectDescription: Yup.string()
    .required("Description is required"),

  bannerUrl: Yup.string()
    .url('Not a url').optional(),


});