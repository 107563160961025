import { toast } from "react-toastify";
import { networkLinks } from "../../_constant";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'clipboard-copy';

export default function StackTokenInfo ({stakeToken, chain}){
    return(
        <>
        <div className="custom_card mt-4">
        <div className="p-3">
          <div className="progress_bar mt-2">
            <div className="text-center">

            </div>
       
            <div>
              <p className="mb-0">Token Address</p>
              <div className="d-flex">
          <span
            onClick={() => {
              window.open(
                `${networkLinks[chain]}/address/${stakeToken}`
              );
            }}
            style={{
              fontSize: "13px",
              cursor: "pointer",
              color: "#3498DB",
              marginRight: "10px", // Add margin to create space
            }}
            onMouseEnter={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "none";
            }}
          >
            {stakeToken}
          </span>

            {/* <CopyToClipboard
              text={stakeToken}
            >
              <i
                className="fa fa-clone"
                aria-hidden="true"
                style={{
                  width: '20',
                  height: '20',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  toast.success("Copied",{toastId:'copiedTId'});
                }}
              ></i>
            </CopyToClipboard> */}
              <Tooltip arrow title="Copy">
              <ContentCopyIcon style={{width:'18',cursor:'pointer'}} onClick={() => {
                copy(stakeToken).then(()=>{
                  toast.success("Copied");
                })
          }}/>
              </Tooltip>
        </div>
            </div>
            
          </div>
        </div>
      </div>
        </>
    )
}