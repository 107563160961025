import React from "react"
import PresaleCalc from "./PresaleCalc";
import FHLaunchCalc from "./FHLaunchCalc";

function Calculator() {
  return (
    <>
      <div className="container-fluid mt-5">
        <div className="approve_token_card card">
          <h4 className="card-header">Presale Calculator</h4>
          <div className="card-body">

            <ul class="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="ex2-tab-1"
                  data-bs-toggle="tab"
                  href="#ex2-tabs-1"
                  role="tab"
                  aria-controls="ex2-tabs-1"
                  aria-selected="true"
                >Presale</a>
              </li>
              <li className="nav-item">
      <a
        className="nav-link"
        id="ex2-tab-2"
        data-bs-toggle="tab"
        href="#ex2-tabs-2"
        role="tab"
        aria-controls="ex2-tabs-2"
        aria-selected="false"
      >Fair/Hyper Launch</a>
    </li>
            </ul>


            {/* <div className="tab-content mt-3" id="ex2-tabs-2">
            </div> */}

            <div className="tab-content mt-3">
              <div className="tab-pane fade show active" id="ex2-tabs-1" role="tabpanel" aria-labelledby="ex2-tab-1">
                <PresaleCalc/>
              </div>

              <div className="tab-pane fade" id="ex2-tabs-2" role="tabpanel" aria-labelledby="ex2-tab-2">
              <FHLaunchCalc/>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default Calculator;