export function ShowRecv({ReceiveTokens, token_symbol}){
    return(
        <>
           <ul>
        <button
        className="btn receivable_amt mt-2 mx-auto"
        // onClick={() => { claim() }}
        disabled={true}
      > {`Claimed (${ReceiveTokens} / ${ReceiveTokens} ${token_symbol})`}</button>
      </ul>
        </>
    )
}