import * as Yup from "yup";


const validateAddress = (address) => {
  const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
  return addressRegex.test(address);
};

export const createTokeSchema = Yup.object().shape({
  token_type: Yup.string().required("Token type is required"),
  token_name: Yup.string().required("Name is required").matches(/^[A-Za-z\s]+$/, "Name can only contain letters"),
  token_symbol: Yup.string().required("Symbol is required").matches(/^[A-Za-z]+$/, "Symbol can only contain letters"),
  token_decimal: Yup.number()
    .required("Decimals is required").integer("Token decimals must be an integer").min(1,'Decimals >= 1').max(18,'decimals <= 18'),
  token_total_supply: Yup.number().required("Total supply is required").min(100,'Total supply >= 100').max(1e+59,"Total supply should be less than or equal to 1e59"),
});

export const standardTokenSchema = createTokeSchema.shape({
  token_total_supply: Yup.number().required("Total supply is required").min(100,'Total supply >= 100').max(1e+59,"Total supply should be less than or equal to 1e59"),
  max_wallet: Yup.number().required("Max wallet is required").test("max_wallet", "Max wallet limit should be greater than 0.1% of total supply!", function (value) {
    const tokenTotalSupply = this.parent.token_total_supply;
    const maxWalletLimit = tokenTotalSupply * 0.001; // 0.1% of total supply
    return value >= maxWalletLimit;
  }).test("max_wallet","Max Wallet <= Total Supply",function(value){
    const tokenTotalSupply = this.parent.token_total_supply;
    return value <= tokenTotalSupply
  }),
  max_transaction_amount: Yup.number().required(
    "Max transaction amount is required"
  ).test("max_transaction_amount", "Max transaction amount should be greater than 0.1% of total supply!  ", function (value) {
    const tokenTotalSupply = this.parent.token_total_supply;
    const maxWalletLimit = tokenTotalSupply * 0.001; // 0.1% of total supply
    return value >= maxWalletLimit;
  }).test("max_transaction_amount","Max transaction amount <= totalSupply",function(value){
    const tokenTotalSupply = this.parent.token_total_supply;
    return value <= tokenTotalSupply
  }),
  tokenAddressForPair:Yup.string().required("Token address for pair is required").test('Token address For pair-test', 'Invalid token address for pair', (address) => {
    return validateAddress(address);
  }),
  marketing_wallet: Yup.string().required("Marketing wallet address is required").test('marketing_wallet', 'Invalid marketing wallet address', (address) => {
    return validateAddress(address);
  }),
  sell_liquidity: Yup.number()
    .required("Sell liquidity fee is required")
    .min(0.1, "Sell liquidity fee >= 0.1")
    .max(20, "Sell liquidity fee <= 20%"),
  sell_marketing_fee:Yup.lazy((value,{parent})=>{
    return Yup.number()
    .required("Sell marketing fee is required")
    .min(0.1, "Sell marketing fee >= 0.1")
    .max(20, "Sell marketing fee <= 20%")
    // .max(20-parent.sell_liquidity,"Sell fee exceeds 20%!")
  }),   
  buy_liquidity_fee: Yup.lazy((value, { parent }) => {
    return Yup.number()
      .required("Buy liquidity fee is required")
      .min(0.1, "Buy liquidity fee >= 0.1").max(20,'Buy liquidity fee <= 20%')
      // .max(20 - parent.sell_liquidity, "Sell Liquidity Fee and Buy Liquidity Fees cannot exceed 20%");
  }),
  buy_marketing_fee:Yup.lazy((value, { parent }) => {
    return Yup.number()
      .required("Buy marketing fee is required")
      .min(0.1, "Buy marketing fee >= 0.1").max(20,'Buy marketing fee <= 20%');
      // .max(20 - parent.buy_liquidity_fee, "Buy fee exceeds 20%!");
  }),
});


export const reflectionTokenSchema = createTokeSchema.shape({
  token_total_supply: Yup.number().required("Total supply is required").min(100,'Total supply >= 100').max(1e+59,"Total supply should be less than or equal to 1e59"),
  max_wallet: Yup.number().required("Max wallet is required").test("max_wallet", "Max wallet limit should be greater than 0.1% of total supply!", function (value) {
    const tokenTotalSupply = this.parent.token_total_supply;
    const maxWalletLimit = tokenTotalSupply * 0.001; // 0.1% of total supply
    return value >= maxWalletLimit;
  }),

  max_transaction_amount: Yup.number().required(
    "Max transaction amount is required"
  ).test("max_transaction_amount", "Max transaction amount should be greater than 0.1% of total supply!  ", function (value) {
    const tokenTotalSupply = this.parent.token_total_supply;
    const maxWalletLimit = tokenTotalSupply * 0.001; // 0.1% of total supply
    return value >= maxWalletLimit;
  }),
  tokenAddressForPair:Yup.string().required("Token address for pair is required").test('Token address For Pair-test', 'Invalid token address for pair', (address) => {
    return validateAddress(address);
  }),
  marketing_wallet: Yup.string().required("Marketing wallet address is required").test('marketing_wallet', 'Invalid marketing wallet address', (address) => {
    return validateAddress(address);
  }),
  sell_liquidity: Yup.number()
    .required("Sell liquidity fee is required")
    .min(0.1, "Sell liquidity fee >= 0.1")
    .max(20, "Sell liquidity fee <= 20%"),

    sell_marketing_fee:Yup.lazy((value,{parent})=>{
      return Yup.number()
      .required("Sell marketing fee is required")
      .min(0.1, "Sell marketing fee >= 0.1")
      .max(20, "Sell marketing fee <= 20%")
      // .max(20-parent.sell_liquidity,"Sell fee exceeds 20%!")
  
    }),  
    buy_liquidity_fee: Yup.lazy((value, { parent }) => {
      return Yup.number()
        .required("Buy liquidity fee is required")
        .min(0.1, "Buy liquidity fee >= .1").max(20,'Buy liquidity fee <= 20%')
        // .max(20 - parent.sell_liquidity, "Sell Liquidity Fee and Buy Liquidity Fees cannot exceed 20%");
    }),
  buy_marketing_fee:Yup.lazy((value, { parent }) => {
      return Yup.number()
        .required("Buy marketing fee is required")
        .min(0.1, "Buy marketing fee >= 0.1")
        .max(20,'Buy marketing fee <= 20%');
        // .max(20 - parent.sell_marketing_fee, "Sell Marketing Fee and Buy Marketing Fees cannot exceed 20%");
    }),
    sell_reward_fee: Yup.number()
    .required("Sell reward fee is required")
    .min(0.1, "sell marketing fee >= 0.1")
    .max(20, "sell reward fee <= 20%")
    // .test("sum-validation", "Sell fee exceeds 20%!", function (value) {
    //   const sellLiquidityFee = this.parent.sell_liquidity || 0;
    //   const sellMarketingFee = this.parent.sell_marketing_fee || 0;
    //   const totalFees = sellLiquidityFee + sellMarketingFee + value;
    //   return totalFees <= 20;
    // })
    ,
    buy_reward_fee:Yup.lazy((value, { parent }) => {
      return Yup.number()
        .required("Buy reward fee is required")
        .min(0.1, "Buy reward fee >= 0.1").max(20,'Buy reward fee <=20%')
        // .test("sum-validation", "Buy fee exceeds 20%!", function (value) {
        //   const BuyLiquidityFee = this.parent.buy_liquidity_fee || 0;
        //   const BuyMarketingFee = this.parent.buy_marketing_fee || 0;
        //   const totalFees = BuyLiquidityFee + BuyMarketingFee + value;
        //   return totalFees <= 20;
        // })
        // .max(20 - parent.buy_liquidity_fee, "Buy fee exceeds 20%!");
    }),
});

export const dividentTokenSchema = createTokeSchema.shape({
  token_total_supply: Yup.number().required("Total supply is required").min(100,'Total supply >= 100').max(1e+59,"Total supply should be less than or equal to 1e59"),
  max_wallet: Yup.number().required("Max wallet is required").test("max_wallet", "Max wallet limit should be greater than 0.1% of total supply!", function (value) {
    const tokenTotalSupply = this.parent.token_total_supply;
    const maxWalletLimit = tokenTotalSupply * 0.001; // 0.1% of total supply
    return value >= maxWalletLimit;

  }),
  max_transaction_amount: Yup.number().required(
    "Max transaction amount is required"
  ).test("max_transaction_amount", "Max transaction amount should be greater than 0.1% of total supply!  ", function (value) {
    const tokenTotalSupply = this.parent.token_total_supply;
    const maxWalletLimit = tokenTotalSupply * 0.001; // 0.1% of total supply
    return value >= maxWalletLimit;
  }),
  tokenAddressForPair:Yup.string().required("Token address for pair is required").test('Token address for Pair-test', 'Invalid token address for pair', (address) => {
    return validateAddress(address);
  }),
  marketing_wallet: Yup.string().required("Marketing wallet address is required").test('marketing_wallet', 'Invalid marketing wallet address', (address) => {
    return validateAddress(address);
  }),
  sell_liquidity: Yup.number()
    .required("Sell liquidity fee is required")
    .min(0.1, "Sell  liquidity fee >= 0.1")
    .max(20, "Sell  liquidity fee <= 20%"),

    sell_marketing_fee:Yup.lazy((value,{parent})=>{
      return Yup.number()
      .required("Sell marketing fee is required")
      .min(0.1, "Sell marketing fee >= 0.1")
      .max(20, "Sell marketing fee <= 20%")
      // .max(20-parent.sell_liquidity,"Sell fee exceeds 20%!")
  
    }),   

    buy_liquidity_fee: Yup.lazy((value, { parent }) => {
      return Yup.number()
        .required("Buy liquidity fee is required")
        .min(0.1, "Buy liquidity fee >= 0.1").max(20,'Buy liquidity fee <= 20%')
        // .max(20 - parent.sell_liquidity, "Sell Liquidity Fee and Buy Liquidity Fees cannot exceed 20%");
    }),
    buy_marketing_fee:Yup.lazy((value, { parent }) => {
      return Yup.number()
        .required("Buy marketing fee is required")
        .min(0.1, "Buy marketing fee >= 0.1").max(20,'Buy marketing fee <= 20%');
        // .max(20 - parent.buy_liquidity_fee, "Buy fee exceeds 20%!");
    }),
  
    sell_reward_fee: Yup.number()
    .required("Sell reward fee is required")
    .min(0.1, "Sell farketing fee >= 0.1")
    .max(20, "Sell reward fee <= 20%")
    // .test("sum-validation", "Sell fee exceeds 20%!", function (value) {
    //   const sellLiquidityFee = this.parent.sell_liquidity || 0;
    //   const sellMarketingFee = this.parent.sell_marketing_fee || 0;
    //   const totalFees = sellLiquidityFee + sellMarketingFee + value;
    //   return totalFees <= 20;
    // })
    ,
    buy_reward_fee:Yup.lazy((value, { parent }) => {
      return Yup.number()
        .required("Buy reward fee is required")
        .min(0.1, "Buy reward fee >= 0.1")
        .max(20,'Buy reward fee <= 20');
        // .test("sum-validation", "Buy fee exceeds 20%!", function (value) {
        //   const BuyLiquidityFee = this.parent.buy_liquidity_fee || 0;
        //   const BuyMarketingFee = this.parent.buy_marketing_fee || 0;
        //   const totalFees = BuyLiquidityFee + BuyMarketingFee + value;
        //   return totalFees <= 20;
        // })
        // .max(20 - parent.buy_liquidity_fee, "Buy fee exceeds 20%!");
    }),
    reflection_token:Yup.string().required("Fill up the reflection token address!").test('reflection_token-test', 'Invalid reflection token', (address) => {
      return validateAddress(address);
    }),
    minimumTokenBalanceForDividends:Yup.number().required("Minimum token balance for dividends is required").min(0.1, "Minimum token balance for dividends >= 0.1")
})