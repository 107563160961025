import React from 'react';
import './NotFound.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../_constant';

const NotFound = () => {

    document.title = `404 Page Not Found`
    const navigate = useNavigate()
    return (

        <div className="centered-container">
            <h2>Sorry, page not found!</h2>
            <p>Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.</p>
            <div className="d-flex align-items-center">
                <button className='btn btn-primary'
                    style={{ whiteSpace: 'nowrap' }}
                    onClick={() => {
                        navigate(BASE_URL + 'presales')
                    }}
                >
                    Go To Home
                </button>
            </div>


        </div>

    );
};

export default NotFound;
