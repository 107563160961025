import { ethers } from "ethers"
import React from "react"

export function PresaleProgInfo ({fundRaisedData, fund_releasing_token, fundRaisingTokenDecimal, fundRaisingTokenSymbol, hardCap}){
    return(
        <>
             <div className="d-flex justify-content-between align-items-center">
                        <p>

                            {
                                fundRaisedData != undefined && ethers.utils.formatUnits(String(fundRaisedData), Number(fundRaisingTokenDecimal)) || 0
                            }
                            {" "}
                            {
                                fundRaisingTokenSymbol
                            }

                        </p>
                        <p>
                            {
                                ethers.utils.formatUnits(String(hardCap), Number(fundRaisingTokenDecimal))
                            }
                            {" "}
                            {
                                fundRaisingTokenSymbol
                            }
                        </p>
                    </div>
        </>
    )
}