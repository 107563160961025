import { ethers } from "ethers"
import React from "react"
import { PerkCategories } from "../../_constant"
export default function Progressbar({ fundRaisedData, hardCap, fund_releasing_token, fundRaisingTokenDecimal, fundRaisingTokenSymbol, perk }) {
    return (
        <div
            className="progress"
            style={{ height: 10 }}
        >
            {
                <>
                    <div
                        className={`progress-bar-${perk == PerkCategories.GOLD?'gold':perk==PerkCategories.DIAMOND?'diamond':perk == PerkCategories.PLATINUM?'platinum':'standard'}`}
                        style={{
                            width: `${fundRaisedData != undefined && Number(ethers.utils.formatUnits(String(fundRaisedData), fundRaisingTokenDecimal) * 100 / Number(ethers.utils.formatUnits(String(hardCap), fundRaisingTokenDecimal)))}%`,
                            height: 10,
                        }}
                    />
                </>

            }

        </div>


    )
}