import CopyToClipboard from "react-copy-to-clipboard";
import { BASE_URL, listedOnDexLinks, networkLinks } from "../../_constant";
import { toast } from "react-toastify";
import Countdown from "../../Components/CountDown/CountDown";
import moment from "moment";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip } from "@mui/material";
import copy from 'clipboard-copy';


export function TokenInfo({estimatedDexListingTime,preSaleToken,launch_stealth_sale,setSaleTime,isFinalized,presale_chain, buyToken,isCanceled, isAllowclaim}){
  const currentUnixTime = moment().unix();
    return(
        <div className="custom_card">
        <div className="p-3">
          <div className="progress_bar mt-2">
            <div className="text-center">
            {isAllowclaim==1 && 
                              currentUnixTime >= estimatedDexListingTime ?
                              <span>Sale Ended</span>
                                :
                                Number(
                                    moment(new Date()).unix()
                                  ) >estimatedDexListingTime ?
                                <span>Sale Ended</span>


                                :
                                <>
              <p className="mb-0">Estimated Token Distribution:</p>
              <span>
                {
                  isCanceled ?
                    <div>Presale Cancelled</div>
                  :
                  setSaleTime == "0" ? "TBA":
                <Countdown
                  unixTime={
                    Number(
                      moment(new Date()).unix()
                    ) >=
                      Number(estimatedDexListingTime)
                      ? estimatedDexListingTime
                      : Number(
                        moment(
                          new Date()
                        ).unix()
                      ) < estimatedDexListingTime
                        ? estimatedDexListingTime
                        : ""
                  }
                />
                }
              </span>
              </>
                              }
            </div>
            {
              // launch_stealth_sale ==0 &&
            <div>
    <p className="mb-0">Token Address</p>
        <div className="d-flex">
          <span
            onClick={() => {
              window.open(
                `${networkLinks[presale_chain]}/address/${preSaleToken}`
              );
            }}
            style={{
              fontSize: "13px",
              cursor: "pointer",
              color: "#3498DB",
              marginRight: "10px", // Add margin to create space
            }}
            onMouseEnter={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "none";
            }}
          >
            {preSaleToken}
          </span>
          {/* <div class="copy-container">
            <div class="tooltip">Copy</div>
            <CopyToClipboard
              text={preSaleToken}
            >
              <i
                className="fa fa-clone"
                aria-hidden="true"
                style={{
                  width: '20',
                  height: '20',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  toast.success("Copied");
                }}
              ></i>
            </CopyToClipboard>
          </div> */}
                {/* <CopyToClipboard text={preSaleToken}> */}
      <Tooltip arrow title="Copy">
        {/* <i
          className="fa fa-clone"
          aria-hidden="true"
          style={{
            width: "20",
            height: "20",
            cursor: "pointer"
          }}
          onClick={() => {
            toast.success("Copied");
          }}
        ></i> */}
        <ContentCopyIcon style={{width:'18',cursor:'pointer'}} onClick={() => {
          copy(preSaleToken).then(()=>{
            toast.success("Copied");
          })
          }}/>
    </Tooltip>
      {/* </CopyToClipboard> */}
        </div>

            </div>
            }
          </div>

          <ul className="presale_address_ui estimated_listing_time mt-3">
            </ul>
        </div>
      </div>
    )
}