import React from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../_constant";


const Success = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-7 col-lg-10 col-md-12 mx-auto">
            <div className="row mt-5">
              <div id="approve_token_form">
                <div className="row">
                  <div className="col-md-12 mx-auto">
                    <div className="row">
                      <div className="col-md-12 mx-auto">
                        <div className="approve_token_section">
                          <h3 className="text-center">Subscription</h3>
                          <div className="approve_token_card mt-2 text-center p-md-5 p-3">

                            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 gap-y-3">
                              <div className="col-sm-12 col-md-12">
                                <img src="images/listed_successfully.svg" alt="listed successfully" />
                              </div>
                              <div className="col-sm-12 col-md-6 mx-auto">
                                <h5 className="text-white">Subscription Successfully Purchased</h5>
                                {/* <span className="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labor.</span> */}
                              </div>
                              <div className="col-sm-12 col-md-12 mt-3">
                                <div className="packages_btns d-flex flex-wrap align-items-center justify-content-center">
                                  <button class="btn subscribe_btn" onClick={()=>{
                                    navigate(BASE_URL +'subsc-cashback')
                                  }}>Home</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Success;