import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../../Components/Home";
import Layout from "../../Components/Layouts/layout";

const PrivateRoutes = () => {
    return (
        <>
            {/* <Layout>

                <Routes>
                    <Route path={`/home`} exact={true} element={<Home />} />
                </Routes>
            </Layout> */}

<div>
  <nav className="navbar navbar-default py-0">
    <div className="container-fluid flex-nowrap ps-0">
      {/* Brand and toggle get grouped for better mobile display */}
      <div className="navbar-header d-flex align-items-center gap-2">
        {/* <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                  <span class="sr-only">Toggle navigation</span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
              </button> */}
        <a href="#" className="nav_menu_btn fw-lighter side-bar"><i className="fa fa-bars ion-lg m-0" /></a>
        {/* <a href="#"><i class="fa fa-bars ion-sm m-0"></i></a> */}
        <a href="#" className="d-none d-sm-block"><img src="images/logo.png" alt="images" /></a>
      </div>
      {/* ======== Header Begin ======== */}
      <header className="w-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex flex-wrap justify-content-xxl-between justify-content-end px-3">
              <div className="header_left_btn header_top_btn d-xxl-flex d-none flex-wrap">
                <a href="#" className="align-items-center d-flex"><img src="images/icon/home.png" alt="home" className="img-fluid me-2" />Home</a>
                <a href="#" className="align-items-center d-flex"><img src="images/icon/docs.png" alt="Docs" className="img-fluid me-2" />Docs</a>
                <a href="#" className="align-items-center d-flex"><img src="images/icon/presale-alerts.png" alt="Presale Alerts" className="img-fluid me-2" />Presale Alerts</a>
              </div>
              <div className="header_right_btn header_top_btn d-flex flex-wrap">
                <a href="#" className="align-items-center d-md-flex d-none"><img src="images/icon/home.png" alt="Create" className="img-fluid me-2" />Create</a>
                <a href="#" className="align-items-center d-flex binance_smart_chain"><img src="images/icon/binance_logo.png" alt="binance_logo" className="img-fluid me-2" width={20} />Binance Smart Chain</a>
                <button className="btn btn-primery btn-connect">Connect</button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* ======== Header End ======== */}
    </div>{/* /.container-fluid */}
  </nav>
  <div className="side-bar-overlay">
  </div>
  <div className="nav-sidebar side-bar">
    {/* <div class="brand-logo d-block d-sm-none">
          <a href="index.html"><img src="images/logo.png" class="img-fluid" alt="logo"></a>
      </div> */}
    <div className="sidebar list-group">
      <h6 className="text-white list-group-item mb-0"><img src="images/dots.svg" alt="dots" className="explore_dot mx-auto" /> <span>EXPLORE</span></h6>
      <ul className="list-group pt-0">
        <li className="list-group-item"><a className="active" href="#"><span><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Presales" /><img src="images/nav-icon/1.svg" className="img-fluid" alt="presales" /></span><p> Presales</p></a></li>
        <li className="list-group-item"><a href="#"><span><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Special Sales" /><img src="images/nav-icon/2.svg" className="img-fluid" alt="presales" /></span><p> Special Sales</p></a></li>
        <li className="list-group-item"><a href="#"><span className="sidebar_icon_p"><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Staking Pools" /><img src="images/nav-icon/3.svg" className="img-fluid" alt="presales" /></span><p> Staking Pools</p></a></li>
        <li className="list-group-item"><a href="#"><span><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Token Locks" /><img src="images/nav-icon/4.svg" className="img-fluid" alt="presales" /></span><p> Token Locks</p></a></li>
        <li className="list-group-item"><a href="#"><span><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Liquidity Locks" /><img src="images/nav-icon/5.svg" className="img-fluid" alt="presales" /></span><p> Liquidity Locks</p></a></li>
      </ul>
      <hr />
      <h6 className="text-white list-group-item mb-0"><img src="images/dots.svg" alt="dots" className="explore_dot mx-auto" /> <span>DEVELOPERS</span></h6>
      <div className="accordion developers_dropdown_menu" id="accordionExample">
        <div className="list-group-item accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <a href="#"><span><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Create & Launch" /><img src="images/nav-icon/6.svg" className="img-fluid" alt="presales" /></span> <p>Create &amp; Launch</p></a>
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body pt-0 ps-5">
              <a href="#"><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Type 1" /> Type 1</a>
              <a href="#"><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Type 2" />Type 2</a>
              <a href="#"><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Type 3" />Type 3</a>
            </div>
          </div>
        </div>
        <div className="list-group-item accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <a href="#"><span><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Utility & Tools" /><img src="images/nav-icon/7.svg" className="img-fluid" alt="presales" /></span> <p>Utility &amp; Tools</p></a>
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body pt-0 ps-5">
              <a href="#"><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Type 1" /> Type 1</a>
              <a href="#"><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Type 2" />Type 2</a>
              <a href="#"><div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Type 3" />Type 3</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="body-content side-bar">
    <div className="container-fluid">
      <div className="row">
        <div className="runing_bar d-flex align-items-center">
          <div className="runing_bar_left d-flex align-items-center">
            <p className="d-none d-md-block">Spotlight</p>
            <p className="d-flex align-items-center"><img src="images/icon/shibelon.png" className="img-fluid me-md-2" alt="icon" /> <span className="d-none d-md-block">ShibElon</span></p>
          </div>
          <div className="runing_bar_right d-flex justify-content-between align-items-center breaking-news ">
            <div className="d-flex flex-row flex-grow-1 flex-fill news">
              <span className="d-flex align-items-center"><img src="images/icon/hot.png" className="img-fluid me-2" alt="icon" /> Trending</span>
            </div>
            <marquee className="news-scroll" behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" scrollamount={5}>
              <ul className="d-flex align-items-center">
                <li><span>#1</span> BABYCEO</li>
                <li><span>#2</span> TOR</li>
                <li><span>#3</span> SAKAI</li>
                <li><span>#4</span> KVZ</li>
                <li><span>#5</span> CKING</li>
                <li><span>#6</span> CORGICEO</li>
                <li><span>#7</span> $PawCEO</li>
                <li><span>#8</span> DeFiAI</li>
                <li><span>#9</span> HUMAI</li>
                <li><span>#10</span> Khinu</li>
                <li><span>#11</span> WCP</li>
                <li><span>#12</span> CERAI</li>
                <li><span>#13</span> BABYCEO</li>
                <li><span>#14</span> TOR</li>
              </ul>
            </marquee>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="presales_section d-flex justify-content-between align-items-center flex-wrap mt-3">
          <h2 className="text-white fw-lighter">Presales</h2>
          <div className="presales_tabs">
            {/* Nav tabs */}
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-bs-toggle="tab" href="#quickview"><img src="images/icon/quickview.png" className="img-fluid me-2" alt="icon" /> Quick View</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#allproducts"><img src="images/icon/allproducts.png" className="img-fluid me-2" alt="icon" /> All Products</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#mycontribution"><img src="images/icon/mycontribution.png" className="img-fluid me-2" alt="icon" /> My Contribution</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#myfavorites"><img src="images/icon/myfavorites.png" className="img-fluid me-2" alt="icon" /> My Favorites</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#myalarms"><img src="images/icon/myalarms.png" className="img-fluid me-2" alt="icon" /> My Alarms</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#mycreatedpresales"><img src="images/icon/mycreatedpresales.png" className="img-fluid me-2" alt="icon" /> My Created Presales</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Tab panes */}
        <div className="tab-content mt-3 px-0 px-lg-3">
          <div className="tab-pane container active" id="quickview"> </div>
          <div className="tab-pane container fade" id="allproducts">
            <div className="row row-cols-1 mb-5">
              <div className="allproducts_tab_fillter d-flex flex-wrap gap-3 align-items-center justify-content-between">
                <div className="search_bar">
                  <form className="search_bar_form" action="/action_page.php" style={{margin: 'auto', maxWidth: 300}}>
                    <input type="text" placeholder="Enter token name or token symbol" name="search2" />
                    <button type="submit"><i className="fa fa-search" /></button>
                  </form>
                </div>
                <div className="d-flex gap-2 flex-wrap">
                  <div className="sort_by_section d-flex align-items-center gap-2">
                    <p className="mb-0">Network</p>
                    <select className="form-select" aria-label="Default select example" data-width="auto">
                      <option selected>KYC</option>
                      <option value={1}>Live</option>
                      <option value={2}>Gold</option>
                      <option value={2}>Audit</option>
                    </select>
                  </div>
                  <div className="sort_by_section d-flex align-items-center gap-2">
                    <p className="mb-0">Filter By</p>
                    <select className="form-select" aria-label="Default select example" data-width="auto">
                      <option selected>KYC</option>
                      <option value={1}>Live</option>
                      <option value={2}>Gold</option>
                      <option value={2}>Audit</option>
                    </select>
                  </div>
                  <div className="sort_by_section d-flex align-items-center gap-2">
                    <p className="mb-0">Sort By</p>
                    <select className="form-select" aria-label="Default select example" data-width="auto">
                      <option selected>No Sort</option>
                      <option value={1}>Start Time</option>
                      <option value={2}>End Time</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-xxxl-4 row-cols-xxl-3 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 gap-y-4">
              <div className="col">
                <div className="card custom_card_design">
                  <div className="custom_card">
                    <div className="card-top-img stablz-card-top-img">
                      <div className="p-3">
                        <div className="sale_live">
                          <p className="mb-0">Sale Live</p>
                        </div>
                        <div className="audi_kyc_btn">
                          <button type="btn" className="btn audit-btn">Audit</button>
                          <button type="btn" className="btn kyc-btn">KYC+</button>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="card_second_section d-flex justify-content-between align-items-end">
                        <div className="card_second_section_text">
                          <h5>Stablz 100</h5>
                          <p>Fair Launch-Max: 3 BNB</p>
                        </div>
                        <div className="card_second_section_img position">
                          <img src="images/stablz_stablz_icon.png" className="img-fluid" alt="icon" />
                          <span><img src="images/icon/binance_logo.png" className="img-fluid" alt="icon" width={28} /></span>
                        </div>
                      </div>
                      <div className="progress_bar mt-2">
                        <p className="mb-0">Progress(60.096%)</p>
                        <div className="progress" style={{height: 10}}>
                          <div className="progress-bar" style={{width: '40%', height: 10}} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p>12.019 BNB</p>
                          <p>20 BNB</p>
                        </div>
                      </div>
                      <div className="row row-cols-2 row-cols-sm-2 gx-1 gy-2">
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex flex-wrap align-items-center">Softcap<span>20 BNB</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex flex-wrap align-items-center">Hardcap<span><img src="images/icon/infinity.png" className="img-fluid" alt="infinity" /> </span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex flex-wrap align-items-center">Liquidity<span>85%</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex flex-wrap align-items-center">Locked<span>1825 Days</span></p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col">
                          <div className="sale_status d-flex justify-content-between align-items-start">
                            <div className>
                              <p>Sale Ends In:</p>
                              <span><p id="saletimer" /></span>
                            </div>
                            <div className="card_notification d-flex align-items-center gap-2">
                              <i className="fa fa-bell" aria-hidden="true" />
                              <i className="fa fa-heart-o" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card custom_card_design">
                  <div className="custom_card">
                    <div className="card-top-img belifex-card-top-img">
                      <div className="p-3">
                        <div className="sale_upcoming">
                          <p className="mb-0">Upcoming</p>
                        </div>
                        <div className="audi_kyc_btn">
                          <button type="btn" className="btn audit-btn">Audit</button>
                          <button type="btn" className="btn kyc-btn">KYC+</button>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="card_second_section d-flex justify-content-between align-items-end">
                        <div className="card_second_section_text">
                          <h5>Belifex</h5>
                          <p>Fair Launch-Max: 3 BNB</p>
                        </div>
                        <div className="card_second_section_img position">
                          <img src="images/belifex-icon.png" className="img-fluid" alt="icon" />
                          <span><img src="images/icon/binance_logo.png" className="img-fluid" alt="icon" width={28} /></span>
                        </div>
                      </div>
                      <div className="progress_bar mt-2">
                        <p className="mb-0">Progress(0%)</p>
                        <div className="progress" style={{height: 10}}>
                          <div className="progress-bar" style={{width: '0%', height: 10}} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p>12.019 BNB</p>
                          <p>20 BNB</p>
                        </div>
                      </div>
                      <div className="row row-cols-2 row-cols-sm-2 gx-1 gy-2">
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Softcap<span>20 BNB</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Hardcap<span><img src="images/icon/infinity.png" className="img-fluid" alt="infinity" /> </span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Liquidity<span>85%</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Locked<span>1825 Days</span></p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col">
                          <div className="sale_status d-flex justify-content-between align-items-start">
                            <div className>
                              <p>Sale Ends In:</p>
                              <span><p id="saletimer2" /></span>
                            </div>
                            <div className="card_notification d-flex align-items-center gap-2">
                              <i className="fa fa-bell" aria-hidden="true" />
                              <i className="fa fa-heart-o" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card custom_card_design">
                  <div className="custom_card">
                    <div className="card-top-img bitfax-card-top-img">
                      <div className="p-3">
                        <div className="sale_live">
                          <p className="mb-0">Sale Live</p>
                        </div>
                        <div className="audi_kyc_btn">
                          <button type="btn" className="btn audit-btn">Audit</button>
                          <button type="btn" className="btn kyc-btn">KYC+</button>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="card_second_section d-flex justify-content-between align-items-end">
                        <div className="card_second_section_text">
                          <h5>Bitfax</h5>
                          <p>Fair Launch-Max: 3 BNB</p>
                        </div>
                        <div className="card_second_section_img position">
                          <img src="images/bitfax-icon.png" className="img-fluid" alt="icon" />
                          <span><img src="images/icon/binance_logo.png" className="img-fluid" alt="icon" width={28} /></span>
                        </div>
                      </div>
                      <div className="progress_bar mt-2">
                        <p className="mb-0">Progress(60.096%)</p>
                        <div className="progress" style={{height: 10}}>
                          <div className="progress-bar" style={{width: '40%', height: 10}} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p>12.019 BNB</p>
                          <p>20 BNB</p>
                        </div>
                      </div>
                      <div className="row row-cols-2 row-cols-sm-2 gx-1 gy-2">
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Softcap<span>20 BNB</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Hardcap<span><img src="images/icon/infinity.png" className="img-fluid" alt="infinity" /> </span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Liquidity<span>85%</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Locked<span>1825 Days</span></p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col">
                          <div className="sale_status d-flex justify-content-between align-items-start">
                            <div className>
                              <p>Sale Ends In:</p>
                              <span><p id="saletimer3" /></span>
                            </div>
                            <div className="card_notification d-flex align-items-center gap-2">
                              <i className="fa fa-bell" aria-hidden="true" />
                              <i className="fa fa-heart-o" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card custom_card_design">
                  <div className="custom_card">
                    <div className="card-top-img bumbaai-card-top-img">
                      <div className="p-3">
                        <div className="sale_live">
                          <p className="mb-0">Sale Live</p>
                        </div>
                        <div className="audi_kyc_btn">
                          <button type="btn" className="btn audit-btn">Audit</button>
                          <button type="btn" className="btn kyc-btn">KYC+</button>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="card_second_section d-flex justify-content-between align-items-end">
                        <div className="card_second_section_text">
                          <h5>BumbaAI</h5>
                          <p>Fair Launch-Max: 3 BNB</p>
                        </div>
                        <div className="card_second_section_img position">
                          <img src="images/bumbaai-icon.png" className="img-fluid" alt="icon" />
                          <span><img src="images/icon/binance_logo.png" className="img-fluid" alt="icon" width={28} /></span>
                        </div>
                      </div>
                      <div className="progress_bar mt-2">
                        <p className="mb-0">Progress(60.096%)</p>
                        <div className="progress" style={{height: 10}}>
                          <div className="progress-bar" style={{width: '40%', height: 10}} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p>12.019 BNB</p>
                          <p>20 BNB</p>
                        </div>
                      </div>
                      <div className="row row-cols-2 row-cols-sm-2 gx-1 gy-2">
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Softcap<span>20 BNB</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Hardcap<span><img src="images/icon/infinity.png" className="img-fluid" alt="infinity" /> </span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Liquidity<span>85%</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Locked<span>1825 Days</span></p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col">
                          <div className="sale_status d-flex justify-content-between align-items-start">
                            <div className>
                              <p>Sale Ends In:</p>
                              <span><p id="saletimer4" /></span>
                            </div>
                            <div className="card_notification d-flex align-items-center gap-2">
                              <i className="fa fa-bell" aria-hidden="true" />
                              <i className="fa fa-heart-o" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card custom_card_design">
                  <div className="custom_card">
                    <div className="card-top-img tradegpt-card-top-img">
                      <div className="p-3">
                        <div className="sale_live">
                          <p className="mb-0">Sale Live</p>
                        </div>
                        <div className="audi_kyc_btn">
                          <button type="btn" className="btn audit-btn">Audit</button>
                          <button type="btn" className="btn kyc-btn">KYC+</button>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="card_second_section d-flex justify-content-between align-items-end">
                        <div className="card_second_section_text">
                          <h5>TradeGPT</h5>
                          <p>Fair Launch-Max: 3 BNB</p>
                        </div>
                        <div className="card_second_section_img position">
                          <img src="images/tradegpt-icon.png" className="img-fluid" alt="icon" />
                          <span><img src="images/icon/binance_logo.png" className="img-fluid" alt="icon" width={28} /></span>
                        </div>
                      </div>
                      <div className="progress_bar mt-2">
                        <p className="mb-0">Progress(60.096%)</p>
                        <div className="progress" style={{height: 10}}>
                          <div className="progress-bar" style={{width: '40%', height: 10}} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p>12.019 BNB</p>
                          <p>20 BNB</p>
                        </div>
                      </div>
                      <div className="row row-cols-2 row-cols-sm-2 gx-1 gy-2">
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Softcap<span>20 BNB</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Hardcap<span><img src="images/icon/infinity.png" className="img-fluid" alt="infinity" /> </span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Liquidity<span>85%</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Locked<span>1825 Days</span></p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col">
                          <div className="sale_status d-flex justify-content-between align-items-start">
                            <div className>
                              <p>Sale Ends In:</p>
                              <span><p id="saletimer5" /></span>
                            </div>
                            <div className="card_notification d-flex align-items-center gap-2">
                              <i className="fa fa-bell" aria-hidden="true" />
                              <i className="fa fa-heart-o" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card custom_card_design">
                  <div className="custom_card">
                    <div className="card-top-img stackg-card-top-img">
                      <div className="p-3">
                        <div className="sale_live">
                          <p className="mb-0">Sale Live</p>
                        </div>
                        <div className="audi_kyc_btn">
                          <button type="btn" className="btn audit-btn">Audit</button>
                          <button type="btn" className="btn kyc-btn">KYC+</button>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="card_second_section d-flex justify-content-between align-items-end">
                        <div className="card_second_section_text">
                          <h5>Stack G</h5>
                          <p>Fair Launch-Max: 3 BNB</p>
                        </div>
                        <div className="card_second_section_img position">
                          <img src="images/stackg-icon.png" className="img-fluid" alt="icon" />
                          <span><img src="images/icon/binance_logo.png" className="img-fluid" alt="icon" width={28} /></span>
                        </div>
                      </div>
                      <div className="progress_bar mt-2">
                        <p className="mb-0">Progress(60.096%)</p>
                        <div className="progress" style={{height: 10}}>
                          <div className="progress-bar" style={{width: '40%', height: 10}} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p>12.019 BNB</p>
                          <p>20 BNB</p>
                        </div>
                      </div>
                      <div className="row row-cols-2 row-cols-sm-2 gx-1 gy-2">
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Softcap<span>20 BNB</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Hardcap<span><img src="images/icon/infinity.png" className="img-fluid" alt="infinity" /> </span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Liquidity<span>85%</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Locked<span>1825 Days</span></p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col">
                          <div className="sale_status d-flex justify-content-between align-items-start">
                            <div className>
                              <p>Sale Ends In:</p>
                              <span><p id="saletimer6" /></span>
                            </div>
                            <div className="card_notification d-flex align-items-center gap-2">
                              <i className="fa fa-bell" aria-hidden="true" />
                              <i className="fa fa-heart-o" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card custom_card_design">
                  <div className="custom_card">
                    <div className="card-top-img infinet-card-top-img">
                      <div className="p-3">
                        <div className="sale_live">
                          <p className="mb-0">Sale Live</p>
                        </div>
                        <div className="audi_kyc_btn">
                          <button type="btn" className="btn audit-btn">Audit</button>
                          <button type="btn" className="btn kyc-btn">KYC+</button>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="card_second_section d-flex justify-content-between align-items-end">
                        <div className="card_second_section_text">
                          <h5>Infinet</h5>
                          <p>Fair Launch-Max: 3 BNB</p>
                        </div>
                        <div className="card_second_section_img position">
                          <img src="images/infinet-icon.png" className="img-fluid" alt="icon" />
                          <span><img src="images/icon/binance_logo.png" className="img-fluid" alt="icon" width={28} /></span>
                        </div>
                      </div>
                      <div className="progress_bar mt-2">
                        <p className="mb-0">Progress(60.096%)</p>
                        <div className="progress" style={{height: 10}}>
                          <div className="progress-bar" style={{width: '40%', height: 10}} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p>12.019 BNB</p>
                          <p>20 BNB</p>
                        </div>
                      </div>
                      <div className="row row-cols-2 row-cols-sm-2 gx-1 gy-2">
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Softcap<span>20 BNB</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Hardcap<span><img src="images/icon/infinity.png" className="img-fluid" alt="infinity" /> </span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Liquidity<span>85%</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Locked<span>1825 Days</span></p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col">
                          <div className="sale_status d-flex justify-content-between align-items-start">
                            <div className>
                              <p>Sale Ends In:</p>
                              <span><p id="saletimer7" /></span>
                            </div>
                            <div className="card_notification d-flex align-items-center gap-2">
                              <i className="fa fa-bell" aria-hidden="true" />
                              <i className="fa fa-heart-o" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card custom_card_design">
                  <div className="custom_card">
                    <div className="card-top-img flamerlife-card-top-img">
                      <div className="p-3">
                        <div className="sale_live">
                          <p className="mb-0">Sale Live</p>
                        </div>
                        <div className="audi_kyc_btn">
                          <button type="btn" className="btn audit-btn">Audit</button>
                          <button type="btn" className="btn kyc-btn">KYC+</button>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="card_second_section d-flex justify-content-between align-items-end">
                        <div className="card_second_section_text">
                          <h5>FLAMER.LIFE AI</h5>
                          <p>Fair Launch-Max: 3 BNB</p>
                        </div>
                        <div className="card_second_section_img position">
                          <img src="images/flamerlife-icon.png" className="img-fluid" alt="icon" />
                          <span><img src="images/icon/binance_logo.png" className="img-fluid" alt="icon" width={28} /></span>
                        </div>
                      </div>
                      <div className="progress_bar mt-2">
                        <p className="mb-0">Progress(60.096%)</p>
                        <div className="progress" style={{height: 10}}>
                          <div className="progress-bar" style={{width: '40%', height: 10}} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p>12.019 BNB</p>
                          <p>20 BNB</p>
                        </div>
                      </div>
                      <div className="row row-cols-2 row-cols-sm-2 gx-1 gy-2">
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Softcap<span>20 BNB</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Hardcap<span><img src="images/icon/infinity.png" className="img-fluid" alt="infinity" /> </span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Liquidity<span>85%</span></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="Softcap">
                            <p className="justify-content-between d-flex">Locked<span>1825 Days</span></p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col">
                          <div className="sale_status d-flex justify-content-between align-items-start">
                            <div className>
                              <p>Sale Ends In:</p>
                              <span><p id="saletimer8" /></span>
                            </div>
                            <div className="card_notification d-flex align-items-center gap-2">
                              <i className="fa fa-bell" aria-hidden="true" />
                              <i className="fa fa-heart-o" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane container fade" id="mycontribution"> </div>
          <div className="tab-pane container active" id="myfavorites"> </div>
          <div className="tab-pane container fade" id="myalarms"> </div>
          <div className="tab-pane container fade" id="mycreatedpresales"> </div>
        </div>
      </div>
    </div>
  </div>
  <div className="clearfix" />
</div>



        </>
    )
}

export default PrivateRoutes;
